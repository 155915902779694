import React, { useState, ReactNode, useRef } from 'react';

import '../styles/components/ComboBox.css';

type MappedElements<T> = {
    [key: string]: T;
};

export default function ComboBox<T>({ onChange, className, elements, displayFromElement, value }: { onChange: (arg0: any) => void, className: string, elements: MappedElements<T>, value: string, displayFromElement: (e: T) => ReactNode }) {
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const timeoutId = useRef<NodeJS.Timeout>(undefined);

    function onComboBoxChange(value: any) {
        if (onChange !== undefined)
            onChange(value);
    }

    function onComboBoxClick() {
        setPopoverOpen(!popoverOpen);
    }

    // We close the popover on the next tick by using setTimeout.  
    // This is necessary because we need to first check if  
    // another child of the element has received focus as  
    // the blur event fires prior to the new focus event.  
    function onBlurHandler() {
        timeoutId.current = setTimeout(() => {
            setPopoverOpen(false);
        }, 100);
    }

    return (
        <div className={'ComboBox ' + className} onClick={() => onComboBoxClick()} onBlur={() => { onBlurHandler(); }}>
            <div className='ComboBox-SelectedElement' tabIndex={-1}>
                {displayFromElement(elements[value])}
            </div>
            {popoverOpen &&
                <div className='ComboBox-Popover'>
                    {Object.keys(elements)
                        .filter(x => x !== value)
                        .map(x =>
                            <div key={x} className='ComboBox-Popover-Element' onClick={(e) => {
                                onComboBoxChange(x);
                            }}>
                                {displayFromElement(elements[x])}
                            </div>)}
                </div>}
        </div>
    );
}
