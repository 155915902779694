import React from "react";
import { useLoaderData, useParams } from "react-router-dom";
import I18n from "../i18n/i18n";

// Images
import { ReactComponent as ExaminationImage } from "../images/examination.svg";

// Style
import "../styles/pages/Home.css";
import "../styles/pages/Examinations.css";

// Components
import Wiki from "../components/Wiki";

const examinations: Record<string, null> = {
  gastroscopy: null,
  anorectal_echoendoscopy: null,
  anorectal_manometry: null,
  esophageal_manometry: null,
  urea_breath_test: null,
  upper_digestive_tract_echo_endoscopy: null,
  ultrasound: null,
  ercp: null,
  colonoscopy: null,
  defeco_mri: null,
  twenty_four_hour_ph_impedance_testing: null,
  video_capsule_endoscopy: null
};

export default function Examinations() {
  useLoaderData();
  const { id } = useParams();

  return (
    <Wiki
      prefix="examinations"
      entries={examinations}
      selected_entry={id}
      homePage={
        <div className="fullSize centerFlex">
          <div>
            <ExaminationImage className="unselectable examinationHomeImage" />
            <h1>{I18n.getString("examinations.home.title")}</h1>
            <h2>{I18n.getString("examinations.home.subtitle")}</h2>
          </div>
        </div>
      }
    />
  );
}
