import React from 'react';

import en, { LocalizedStringKey } from './languageDicts/english';
import fr from './languageDicts/french';
import de from './languageDicts/german';

import { ReactComponent as FrIcon } from './icons/fr.svg';
import { ReactComponent as DeIcon } from './icons/de.svg';
import { ReactComponent as EnIcon } from './icons/en.svg';

import { ReactNode } from 'react';

export type Language = { displayName: string, icon: ReactNode, content: string }
export type LanguageKey = keyof typeof I18n.languages;

export default class I18n {
    static languages = {
        fr: {
            displayName: "français",
            icon: <FrIcon/>,
            content: fr
        },
        en: {
            displayName: "English",
            icon: <EnIcon/>,
            content: en
        },
        de: {
            displayName: "Deutsch",
            icon: <DeIcon/>,
            content: de
        },
    };



    static instance = new I18n();
    static defaultLocale: LanguageKey = "en";
    static locale: LanguageKey = I18n.defaultLocale;

    static isLang(language: string) {
        return language in I18n.languages;
    }

    static getString(stringKey: string) {
        if (stringKey in I18n.languages[I18n.locale].content) {
            return I18n.languages[I18n.locale].content[stringKey as unknown as LocalizedStringKey];
        } else if (stringKey in I18n.languages[I18n.locale].content) {
            return I18n.languages[I18n.defaultLocale].content[stringKey as unknown as LocalizedStringKey];
        } else {
            return stringKey;
        }
    }

    static setLocale(locale: LanguageKey) {
        I18n.locale = locale;
    }
};