import React from "react";
import I18n from "../i18n/i18n";
import { useParams, useLoaderData } from 'react-router-dom';

// Style
import "../styles/pages/Home.css";

// Components
import Wiki from "../components/Wiki";

const pathologies = {
  "pathologies.general.title": ["cancer"],
  "pathologies.alimentary_canal.title": [
    "barrett",
    "reflux",
    "colorectal_cancer",
    "crohn",
    "ulcerative_colitis",
    "irritable_bowel_syndrome"
  ],
  "pathologies.liver.title": [
    "viral_hepatitis",
    "hepatitis_misc",
    "hepatitis_alc",
    "nafld",
    "rare_diseases",
    "cirrhosis",
  ],
  "pathologies.bile_ducts_and_pancreas.title": [
    "biliopancreatic_pathology",
    "gall_and_bile_duct_stones",
  ],
  "pathologies.anus.title": [
    "hemorrhoids",
    "anal_fissure",
    "anal_fistula",
    "anal_warts",
  ],
};

export default function Pathologies() {
  useLoaderData();
  const { id } = useParams();

  return (
    <Wiki
      prefix="pathologies"
      entries={pathologies}
      selected_entry={id}
      homePage={
        <div
          className="fullSize"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "25% center",
            backgroundImage: `url("/images/backgrounds/pathologies.jpg")`,
          }}
        >
          <div className="centerFlex fullSizeOpacityBoxLight">
            <div>
              <h1>{I18n.getString("pathologies.home.title")}</h1>
              <h2>{I18n.getString("pathologies.home.subtitle")}</h2>
            </div>
          </div>
        </div>
      }
    />
  );
}
