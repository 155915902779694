import React from 'react';

import '../styles/components/Highlight.css';

export default class Highlight extends React.Component {
    render() {
        return(
            <div className={"highlight" + (this.props.right?' right':'')}>
                <img src={this.props.imgSrc} alt="acc-logo" className="highlightImg"/>
                <div className="highlightInfo">
                    <div className='highlightTitleWrapper'>
                        <img src={this.props.imgSrc} alt="acc-logo" className="highlightImgMini"/>
                        <div className="highlightTitle">
                            {this.props.title}
                        </div>
                    </div>
                    <div className="highlightDescription">
                        {this.props.description}
                    </div>
                </div>
            </div>
        )
    }
}

