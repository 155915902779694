import React from 'react';

import '../styles/components/TeamMemberTitleDisplay.css';

export default class TeamMemberTitleDisplay extends React.Component {
    render() {
        return(
            <div className={"teamMemberTitleDisplay"}>
                <div className='teamMemberTitleDisplayImgWrapper'>
                    <img src={this.props.imgSrc} alt="acc-logo" className="teamMemberTitleDisplayImg"/>
                </div>
                <div className="teamMemberTitleDisplayInfo">
                    <div className="teamMemberTitleDisplayName">
                        {this.props.name}
                    </div>
                    <div className="teamMemberTitleDisplayDescription">
                        {this.props.description}
                    </div>
                </div>
            </div>
        );
    }
}

