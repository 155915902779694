import React from "react";
import Carousel from "react-bootstrap/Carousel";

// Components
import Highlight from "../components/Highlight";
import HRule from "../components/HRule";

// Styles
import "../styles/Carousel.css";
import "../styles/pages/Home.css";

// Images
import trust from "../images/trust.svg";
import meditate from "../images/meditate.svg";
import team from "../images/team.svg";
import target from "../images/target.svg";
import handshake from "../images/handshake-3.svg";
import interview from "../images/interview.svg";
import time from "../images/time.svg";
import scientist from "../images/scientist.svg";
import dataProcessing from "../images/data_processing.svg";
import security from "../images/security.svg";
import checklist from "../images/checklist.svg";

import I18n from "../i18n/i18n";
import { useLoaderData } from "react-router-dom";

export default function Home() {
  useLoaderData();

  return (
    <div>
      <div className="scrollableContent">
        <div className="centerFlex fullWidth cover">
          <div className="cover fullSizeOpacityBox centerFlex overlay">
            <div>
              <h3>EastGastro</h3>
              <p className="subtitle">
                {I18n.getString("home.header.subtitle")}
              </p>
            </div>
          </div>
          <Carousel className="behind" interval={7500}>
            <Carousel.Item>
              <div className="slide slide1" />
            </Carousel.Item>
            <Carousel.Item>
              <div className="slide slide2" />
            </Carousel.Item>
            <Carousel.Item>
              <div className="slide slide3" />
            </Carousel.Item>
            <Carousel.Item>
              <div className="slide slide4" />
            </Carousel.Item>
          </Carousel>
        </div>

        <div className="subtitleBlock">
          {I18n.getString("home.cornerstones.title")}
          <HRule />
        </div>

        <Highlight
          imgSrc={meditate}
          title={I18n.getString("home.cornerstones.first.title")}
          description={I18n.getString("home.cornerstones.first.description")}
        />
        <Highlight
          right={true}
          imgSrc={team}
          title={I18n.getString("home.cornerstones.second.title")}
          description={I18n.getString("home.cornerstones.second.description")}
        />
        <Highlight
          imgSrc={target}
          title={I18n.getString("home.cornerstones.third.title")}
          description={I18n.getString("home.cornerstones.third.description")}
        />
        <Highlight
          right={true}
          imgSrc={trust}
          title={I18n.getString("home.cornerstones.fourth.title")}
          description={I18n.getString("home.cornerstones.fourth.description")}
        />

        <div className="quoteBlock">
          <img src={handshake} alt="" />
          <div className="quoteContent">
            {I18n.getString("home.treatment_1")}
            <div className="quoteHighlight">
              {I18n.getString("home.treatment_2")}
            </div>
            {I18n.getString("home.treatment_3")}
          </div>
        </div>

        <div className="subtitleBlock">
          {I18n.getString("home.strengths.title")}
          <HRule />
        </div>

        <Highlight
          imgSrc={interview}
          title={I18n.getString("home.strengths.first.title")}
          description={I18n.getString("home.strengths.first.description")}
        />
        <Highlight
          imgSrc={scientist}
          title={I18n.getString("home.strengths.third.title")}
          description={I18n.getString("home.strengths.third.description")}
          right={true}
        />
        <Highlight
          imgSrc={security}
          title={I18n.getString("home.strengths.fourth.title")}
          description={I18n.getString("home.strengths.fourth.description")}
        />
        <Highlight
          imgSrc={dataProcessing}
          title={I18n.getString("home.strengths.fifth.title")}
          description={I18n.getString("home.strengths.fifth.description")}
          right={true}
        />
        <Highlight
          imgSrc={checklist}
          title={I18n.getString("home.strengths.sixth.title")}
          description={I18n.getString("home.strengths.sixth.description")}
        />
        <Highlight
          imgSrc={time}
          title={I18n.getString("home.strengths.second.title")}
          description={I18n.getString("home.strengths.second.description")}
          right={true}
        />
      </div>
    </div>
  );
}
