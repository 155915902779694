import {
  createBrowserRouter,
  RouterProvider,
  redirect,
  ScrollRestoration 
} from "react-router-dom";

import Layout from "./Layout";
import Contact from "./pages/Contact";
import Examinations from "./pages/Examinations";
import Home from "./pages/Home";
import News from "./pages/News";
import Pathologies from "./pages/Pathologies";
import Team from "./pages/Team";
import LanguageSelect from "./pages/LanguageSelect";
import NotFound from "./pages/NotFound";
import ReactDOM from "react-dom/client";
import React from "react";
import I18n, { LanguageKey } from "./i18n/i18n";

const router = createBrowserRouter([
  {
    path: "*",
    loader: () => {
      return redirect(`/${I18n.defaultLocale}/language_select`);
    },
  },
  {
    path: ":lang",
    element: <div>
      <Layout />
      <ScrollRestoration />
    </div>,
    errorElement: <NotFound />,
    loader: ({ params }) => {
      const selectedLanguage = params.lang as LanguageKey;

      if (I18n.isLang(selectedLanguage)) {
        I18n.setLocale(selectedLanguage);
      } else {
        return redirect(`/${I18n.defaultLocale}/home`);
      }
      return null;
    },
    children: [
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "examinations",
        element: <Examinations />,
      },
      {
        path: "examinations/:id",
        element: <Examinations />,
      },
      {
        path: "pathologies",
        element: <Pathologies />,
      },
      {
        path: "pathologies/:id",
        element: <Pathologies />,
      },
      {
        path: "news",
        element: <News />,
      },
      {
        path: "news/:id",
        element: <News />,
      },
      {
        path: "team",
        element: <Team />,
      },
      {
        path: "team/:id",
        element: <Team />,
      },
      {
        path: "language_select",
        element: <LanguageSelect />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
