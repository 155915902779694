import React, { ReactNode, useState } from "react";

import {
  Outlet,
  Link,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { ReactComponent as Logo } from "./images/logo.svg";
import { ReactComponent as MenuIcon } from "./images/menu_icon.svg";

import "./styles/App.scss";

import I18n, { LanguageKey } from "./i18n/i18n";
import ComboBox from "./components/ComboBox";
import ErrorBoundary from "./components/ErrorBoundary";

export default function Layout() {
  useLoaderData(); // Ensures it is refreshed when language changes
  const location = useLocation();
  const navigate = useNavigate();

  const [isMenuOpen, setMenuOpen] = useState(false);

  function MenuLink({ to, content, index } : {to : string, content : ReactNode, index : number}) {
    return (
      <Link to={to}>
        <div
          className={"App-MenuItem delay-" + index}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          {content}
        </div>
      </Link>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <Link to={`home`}>
          <Logo className="App-logo"/>
          <div className="App-title">EastGastro</div>
        </Link>

        <div
          onClick={() => {
            setMenuOpen(!isMenuOpen);
          }}
          className={"App-MenuButton" + (isMenuOpen ? " open" : "")}
        >
          <MenuIcon />
        </div>

        <ComboBox
          elements={I18n.languages}
          value={I18n.locale}
          displayFromElement={(e) => e.icon}
          className="App-NavButton"
          onChange={(value : LanguageKey) => {
            var splitRoute = location.pathname.split("/");
            splitRoute[1] = value;
            navigate(splitRoute.join("/"));
          }}
        />

        <Link to={`contact`}>
          <div className="App-NavButton">
            {I18n.getString("navbar.contact.title")}
          </div>
        </Link>
        <Link to={`examinations`}>
          <div className="App-NavButton">
            {I18n.getString("navbar.examinations.title")}
          </div>
        </Link>
        <Link to={`pathologies`}>
          <div className="App-NavButton">
            {I18n.getString("navbar.pathologies.title")}
          </div>
        </Link>
        <Link to={`team`}>
          <div className="App-NavButton">
            {I18n.getString("navbar.team.title")}
          </div>
        </Link>
        <Link to={`news`}>
          <div className="App-NavButton">
            {I18n.getString("navbar.news.title")}
          </div>
        </Link>
        <Link to={`home`}>
          <div className="App-NavButton">
            {I18n.getString("navbar.home.title")}
          </div>
        </Link>
      </header>

      <div className="App-Content">
        {isMenuOpen && (
          <div
            className="centerFlex App-NavMenu"
            style={{
              height: "calc(100vh - var(--navBarHeight))",
              width: "100vw",
            }}
          >
            <div>
              <MenuLink
                to={`home`}
                content={I18n.getString("navbar.home.title")}
                index={0}
              />
              <MenuLink
                to={`news`}
                content={I18n.getString("navbar.news.title")}
                index={1}
              />
              <MenuLink
                to={`team`}
                content={I18n.getString("navbar.team.title")}
                index={2}
              />
              <MenuLink
                to={`pathologies`}
                content={I18n.getString("navbar.pathologies.title")}
                index={3}
              />
              <MenuLink
                to={`examinations`}
                content={I18n.getString("navbar.examinations.title")}
                index={4}
              />
              <MenuLink
                to={`contact`}
                content={I18n.getString("navbar.contact.title")}
                index={5}
              />
              <MenuLink
                to={`language_select`}
                content={I18n.languages[I18n.locale].icon}
                index={6}
              />
            </div>
          </div>
        )}
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </div>
    </div>
  );
}
