import React from 'react';
import ArticleImage from '../../components/ArticleImage';
import { Link } from 'react-router-dom';

const german = {
    /* 
        NAVIGATION BAR
    */
    "navbar.contact.title": "Kontakt",
    "navbar.examinations.title": "Prüfungen",
    "navbar.pathologies.title": "Pathologien",
    "navbar.team.title": "Mannschaft",
    "navbar.news.title": "Nachrichten",
    "navbar.home.title": "Startseite",

    /* 
        HOMEPAGE
    */
    "home.header.subtitle": "Die gastroenterologische Station Ostbelgiens",

    "home.cornerstones.title": "Entdecken Sie unsere vier Säulen",

    "home.cornerstones.first.title": "verdauung von A bis Z",
    "home.cornerstones.first.description": "Spezialisiert auf die Vorbeugung, Diagnose und Behandlung von Erkrankungen des Magens, der Speiseröhre, des Darms, des Afters, der Leber, der Bauchspeicheldrüse, der Gallenblase, der Gallengänge und der Fettleibigkeit.",
    "home.cornerstones.second.title": "multidisziplinäres und multi-horizontales Team",
    "home.cornerstones.second.description": "Die Gastroenterologen der CHC-Klinik St. Elisabeth in Verviers, der Klinik St. Nicolas in Eupen, der Klinik Saint Joseph in St. Vith und der Klinik Reine Astrid in Malmédy haben ihre Fähigkeiten und Erfahrungen gebündelt, um eine organisierte, vollständige und umfassende Versorgung des Verdauungssystems zu gewährleisten als Team.",
    "home.cornerstones.third.title": "spezialisierte Betreuung",
    "home.cornerstones.third.description": "Unser Service zeichnet sich durch eine sehr spezialisierte Betreuung aus: Jeder Mitarbeiter des Service hat mindestens einen Kompetenzbereich, den er mit dem Rest des Teams teilt.",
    "home.cornerstones.fourth.title": "menschliche Natur & Vertrauen",
    "home.cornerstones.fourth.description": "Ihr Vertrauensverhältnis zu Ihrem Arzt ist entscheidend für die Wirksamkeit Ihrer Behandlung. Wir befürworten daher die Kontinuität dieser von Ihnen gewählten Beziehung mit einem Arzt aus unserem Team. Es ist Ihr Gastroenterologe, der sich mit den Fähigkeiten Ihres Hausarztes, seiner Kollegen, Gastroenterologen, Chirurgen, Radiologen, Onkologen, Internisten oder anderer umgibt, um Ihnen eine optimale Betreuung zu bieten, die individuell und menschlich bleibt.",
    "home.treatment_1": "Unsere ",
    "home.treatment_2": "Versorgung",
    "home.treatment_3": " erfolgt am häufigsten ambulant (Konsultationen). Bei schwereren oder überwachungsbedürftigen Erkrankungen des Verdauungstraktes wird ein stationärer Aufenthalt in unserer Abteilung an einem der vier Klinikstandorte organisiert. Die Tagesklinik wird häufig genutzt, um beispielsweise Endoskopien in Narkose oder Infusionsbehandlungen durchzuführen.",
    "home.strengths.title": "Unsere Stärken",
    "home.strengths.first.title": "privilegierter Kontakt",
    "home.strengths.first.description": "Erkrankungen des Verdauungstraktes werden zunehmend von mehreren medizinischen und chirurgischen Fachrichtungen gemeinsam behandelt. Digestive Onkologie und Adipositas sind zwei Beispiele für multidisziplinäre Konsultationen, an denen mehrere Interessengruppen beteiligt sind. Das Besondere an unserem Service ist, dass der von Ihnen gewählte Gastroenterologe Ihr Ansprechpartner bleibt. Dies ermöglicht Ihnen, eine privilegierte medizinische Beziehung zum Arzt Ihrer Wahl zu pflegen, die trotz der manchmal notwendigen Vielzahl von Interessengruppen menschlich bleibt.",
    "home.strengths.second.title": "24/7 Betreuung nach Bedarf",
    "home.strengths.second.description": "Wir sind der Referenzdienst für endoskopische Notfälle, endoskopische Gallen- und Bauchspeicheldrüsenuntersuchungen für Ostbelgien für 4 der 5 Krankenhausstandorte in Verviers und Ostbelgien.",
    "home.strengths.third.title": "modernste Ausstattung",
    "home.strengths.third.description": "Für eine genaue Diagnose sind oft endoskopische oder funktionelle Untersuchungen notwendig. Dafür verfügt die Abteilung über die bestmögliche Ausstattung: hochauflösende Endoskope mit elektronischer Farbgebung, Echo-Endoskope der neuesten Generation mit axialer Sicht (ermöglicht Punktionen und endoskopische Behandlungen) oder 360°-Quersicht (ermöglicht eine optimale Sicht auf bestimmte Anomalien), Videokapsel ermöglicht die Untersuchung des Dünndarms, hochauflösende Manometrie, Impedanzmessgeräte, Laktose-Atemtests, ...",
    "home.strengths.fourth.title": "Patientensicherheit",
    "home.strengths.fourth.description": <div>
        <h3>Hardware-Desinfektion</h3>
        Die Abteilung gehört historisch gesehen zu den ersten Abteilungen in der Provinz Lüttich, die Einweg-Biopsiezangen für die endoskopische Probenahme und Endoskop-Reinigungsgeräte zur Desinfektion von Endoskopen einsetzten. Derzeit sind praktisch alle kleinen Endoskopiegeräte Einweggeräte.
        <br/><br/>
        Die Endoskope werden mit Hochleistungsmaschinen und mit den wirksamsten vorhandenen Produkten desinfiziert.
        <br/><br/>
        Jede Desinfektion wird von A bis Z nachverfolgt und Desinfektionsqualitätskontrollen werden regelmäßig durch bakteriologische Proben durchgeführt.
        <br/><br/>
        <h3>Mehrere Prüfungen</h3>
        Vor jeder Endoskopie in Narkose wird für jeden Patienten eine Checkliste verwendet, die unter anderem die Überprüfung der Patientenidentität, die durchzuführende Untersuchung, Allergien, das Einverständnis des Patienten usw. beinhaltet.
        <br/><br/>
        <h3>Kanada-Akkreditierung</h3>
        Ein sehr anspruchsvoller <a href="https://accreditation.ca/qmentum-accreditation/">Akkreditierungsprozess</a> wurde bei CHC erfolgreich abgeschlossen, der von einer unabhängigen internationalen Stelle geleitet wurde. Das CHC wird von dieser Organisation mit "Gold" akkreditiert, nachdem es alle möglichen Punkte für die Patientensicherheit angestrebt hat.
    </div>,
    "home.strengths.fifth.title": "Computergestützte Krankenakte",
    "home.strengths.fifth.description": <div>
        100 % der medizinischen Informationen zu Konsultationen, Krankenhausaufenthalten und technischen Eingriffen werden von den Ärzten der Abteilung in diese Datenbank eingegeben. Dies ermöglicht statistische Überprüfungen, Abgleiche von Informationen und verbessert so die Versorgungsqualität in unserem Service.
        <br/><br/>
        Darüber hinaus kann der internationale Konsens zum Management einer Krankheit oder zur Durchführung einer Untersuchung ohne Verzögerung in die Datenbank integriert werden, da diese ausschließlich von den Ärzten der Abteilung verwaltet wird.
    </div>,
    "home.strengths.sixth.title": "Konsistenz zwischen Hyperspezialisierungen",
    "home.strengths.sixth.description": <div>
        Gastroenterologen haben jeweils mindestens einen Bereich der Hyperspezialisierung, der alle Verdauungspathologien abdeckt (Krebs, Lebererkrankungen, Morbus Crohn, ulzerativ-hämorrhagische Rektokolitis, Bauchspeicheldrüse, Gallenblase, Proktologie, Reflux und Ösophagitis, Dickdarmpolypen, Fettleibigkeit, ...).
        <br/><br/>
        Diese Hyperspezialisierungen werden so weit wie möglich nach den Interessen des Patienten vom Arzt gruppiert.
        <br/><br/>
        Beispielsweise führen dieselben Ärzte Echoendoskopien und ERCPs durch und ermöglichen so die Sicht auf einen Stein im Gallengang (Echoendoskopie) und die endoskopische Entfernung dieses Steins (ERCP) während einer einzigen Anästhesie. Oder der in anorektaler Manometrie ausgebildete Arzt praktiziert auch die anorektale Echoendoskopie, so dass der Patient diese mühseligen Untersuchungen in einem einzigen Termin und mit einer einzigen Person durchführen kann.
    </div>,
    
    /* 
        NEWS
    */
    "news.published_on": "Veröffentlicht auf",
    "news.covid_19.contents": <div>
        Obwohl sich die Situation verbessert, zirkuliert das Virus immer noch und unsere Patienten, Ihre Lieben, sind ein gefährdetes Publikum.
        <br/><br/>
        Einige Vorsichtsmaßnahmen bleiben daher in Gebrauch:
        <ul style={{listStyleType: "circle"}}>
        <li>Das Tragen einer Maske ist im Krankenhaus obligatorisch;</li>
        <li>Vermeiden Sie es zu kommen, wenn Sie Anzeichen von COVID-19 zeigen oder wenn Sie in engem Kontakt mit einer positiven Person waren.</li>
        </ul>
    </div>,
    "news.covid_19.title": "COVID 19",

    /*
        TEAM
    */
    "team.title": "Unser Team",
    "team.navigation.back": "Zurück",

    "team.olivier_cajot.subtitle": "Gastroenterologe",
    "team.olivier_cajot.description": <div>
        <h2>
            Interessen
        </h2>
        <ul>
            <li>Bauchspeicheldrüse und Gallenwege</li>
            <li>Entzündliche Darmerkrankungen (UCL-Schulung und Referenzzentrum CHU Lille)</li>
            <li>Echo-Endoskopie (mehr als 5.000 Erfahrungsuntersuchungen) und CPRE, interventionelle Endoskopie (mehrere Experten-Ausbildungszentren: Lille, Paris, Brüssel, Lüttich und Charleroi)</li>
            <li>Ernährung</li>
        </ul>
        <h2>
            Qualifikationen
        </h2>
        <ul>
            <li>Doktor der Medizin 1992 (UCL)</li>
            <li>Spezialisierung in Gastroenterologie 1998 (UCL)</li>
            <li>Universitätsdiplom Strahlenschutz 2017 (ULG)</li>
            <li>Universitätsdiplom in entzündlichen Darmerkrankungen 2020 (Sorbonne, Paris)</li>
        </ul>
        <h2>
            Gelehrte Gesellschaften
        </h2>
        <ul>
            <li>Mitglied der belgischen Forschung und Entwicklung für entzündliche Darmerkrankungen</li>
            <li>Mitglied des European Pancreatic Club</li>
        </ul>
    </div>,
    "team.maxime_seivert.subtitle": "Gastroenterologe",
    "team.maxime_seivert.description": <div>
        2014 als Gastroenterologe an der ULg abgeschlossen, bin ich einer der Neuzugänge im Gastroenterologie-Team des St. Elisabeth in Heusy und der jüngste Arzt im Team. Junger Papa, dynamisch und spontan, ich schloss mich schnell dem St. Elisabeth-Team an.
        <br/><br/>
        Ich bin in interventioneller Endoskopie an der UCL bei Pr Deprez ausgebildet und praktiziere Abdominal-Ultraschall, Echo-Endoskopie sowie retrograde Cholangiographie und interessiere mich besonders für die bilio-pankreatische Pathologie.
        <br/><br/>
        Obwohl ich hauptsächlich Endoskopiker bin, bleibe ich natürlich ein allgemeiner Gastroenterologe mit einem besonderen Interesse an entzündlichen Darmpathologien nach einer Zeit der Ausbildung, insbesondere in den Abteilungen von Pr. Louis an der ULg und Pr. Dewit an der UCL.
    </div>,
    "team.virginie_jadot.subtitle": "Gastroenterologe",
    "team.virginie_jadot.description": <div>
        <h2>
            Interessen
        </h2>
        <ul>
            <li>Hepatologie (Universitätsklinikum Lüttich und Lille)</li>
            <li>Allgemeine Gastroenterologie (Lüttich)</li>
            <li>Abdomenultraschall (in Bearbeitung)</li>
        </ul>
        <h2>
            Qualifikationen
        </h2>
        <ul>
            <li>Doktor der Medizin (ULG 2013)</li>
            <li>Spezialisierung in Gastroenterologie (ULG 2019)</li>
            <li>Universitätsdiplom für entzündliche Darmerkrankungen (Lille 2020)</li>
            <li>Universitätsabschluss in dysmetabolischer Steatohepatitis/NASH (Angers 2021)</li>
        </ul>
    </div>,
    "team.alexandre_clotuche.subtitle": "Krankenhausarzt",
    "team.alexandre_clotuche.description": <div>
        Mein Name ist Alexandre Clotuche und ich bin ein ausgebildeter Allgemeinmediziner.<br/><br/>
        Auf der Suche nach einer neuen beruflichen Richtung wurde ich zum <b>Krankenhausarzt</b>.<br/>
        Was beinhaltet das?<br/><br/>
        Ich bin für die stationären Patienten in der Gastroenterologie- und Onkologieabteilung zuständig, von der Aufnahme bis zur Entlassung, und arbeite eng mit verschiedenen Fachärzten sowie dem Hausarzt des Patienten und seiner Familie zusammen.<br/><br/>
        Meine Aufgabe besteht darin, Diagnosen zu stellen, Behandlungspläne zu entwickeln, zusätzliche Untersuchungen zu organisieren und Nachsorge nach dem Krankenhausaufenthalt unter Aufsicht des Gastroenterologen und/oder Onkologen zu leisten.
    </div>,
    "team.donald_vermer.subtitle": "Gastroenterologe",
    "team.donald_vermer.description": <div>
        Sehr geehrter Patient, sehr geehrte Patientin,<br/><br/>
        Mit über 15 Jahren Erfahrung in meinem Beruf und einem Abschluss von der Universität Löwen (UCL) stehe ich Ihnen für Konsultationen zur Verfügung.<br/><br/>
        Medizin ist eine Kunst, die Kunst des Heilens, Lindernden und Heilung.<br/><br/>
        Der Patient muss angehört werden, um umfassend versorgt zu werden.<br/><br/>
        Ich habe einen ganzheitlichen Ansatz in der Patientenversorgung, obwohl es manchmal notwendig ist, sich auf ein bestimmtes Organ oder eine bestimmte Erkrankung zu konzentrieren, um die Beschwerden des Patienten effektiv zu behandeln.<br/><br/>
        Das Verdauungssystem ist vielfältig. Während ich verschiedene Verdauungsprobleme behandele, von gastroösophagealem Reflux bis hin zur hämorrhoidalen Pathologie, habe ich mich auf endoskopische Untersuchungen des Verdauungstrakts und die Behandlung entzündlicher Darmerkrankungen spezialisiert (Interuniversitäres Diplom von Lille zu Morbus Crohn und Colitis ulcerosa).
    </div>,
    "team.ann_christine_dupin.subtitle": "Gastroenterologin",
    "team.ann_christine_dupin.description": <div>
        <h2>
            Ausbildung
        </h2>
        <ul>
            <li><b>2003:</b> SGR Abitur (Rheinbach, Deutschland)</li>
            <li><b>2004-2012:</b> Medizinstudium an der Universität Lüttich</li>
            <li><b>6/2012:</b> Doktortitel in Medizin mit Auszeichnung</li>
        </ul>

        <h2>
            Berufserfahrung
        </h2>
        <ul>
            <li><b>2003-2004:</b> Ausbildung und Praktika in der Krankenpflege im Marienhospital Euskirchen (Deutschland)</li>
            <li><b>2010-2012:</b> Medizinisches Praktikum im 3. und 4. Jahr am Universitätsklinikum Lüttich</li>
            <li><b>Oktober-November 2011:</b> Humanitäre Mission für die NGO "Femme SIDA" in Lubumbashi (Demokratische Republik Kongo)</li>
            <li><b>2012-2015:</b> Assistenzärztin in der Inneren Medizin (gemeinsamer Schwerpunkt des ergänzenden Masterstudiengangs Gastroenterologie) im St. Nikolaus Krankenhaus in Eupen</li>
            <li><b>2015:</b> Assistenzärztin in der Inneren Medizin am HUG (Genf, Schweiz)</li>
            <li><b>2016-2019:</b> Assistenzärztin in den gastroenterologischen Abteilungen am CHR Verviers (1. Jahr der Fachausbildung), Universitätsklinikum Lüttich und CHC Lüttich</li>
            <li><b>Seit Oktober 2019:</b> Gastroenterologin am CHC (Standorte Heusy, Eupen und St. Vith)</li>
        </ul>

        <h2>
            Veröffentlichungen und Präsentationen
        </h2>
        <ul>
            <li><b>2/2018:</b> Belgian Weeks of Gastro-Enterology, "The Duplicated Pancreas"</li>
            <li><b>2021:</b> Veröffentlichung des Fallberichts: "Eine seltene Ursache für epigastrische Schmerzen: Dissektion der Milzarterie und Aneurysma des Truncus coeliacus beim Ehlers-Danlos-Syndrom Typ IV"</li>
        </ul>

        <h2>
            Sprachen
        </h2>
        <ul>
            <li><b>Französisch-Deutsch:</b> Zweisprachig</li>
            <li><b>Englisch:</b> Fließend</li>
        </ul>

        <h2>
            Interessen
        </h2>
        Reisen, Lesen, Leichtathletik
    </div>,
    "team.matthias_dietze.subtitle": "Gastroenterologe",
    "team.matthias_dietze.description": <div>
        <h2>Ausbildung</h2>
        <ul>
            <li>1990-1992 Medizinstudium an der Universität Leipzig (Deutschland)</li>
            <li>1992-1993 Medizinstudium an der Universität Berlin (Deutschland)</li>
            <li>1993-1997 Medizinstudium an der Universität Rennes I (Frankreich)</li>
        </ul>

        <h2>Beruflicher Werdegang</h2>
        <ul>
            <li>1997-1999 Allgemeinarzt im Krankenhaus Gérardmer (Frankreich)</li>
            <li>1999-2004 Assistent in der Gastroenterologie an der Universität Lüttich</li>
            <li>Seit 2004 Privatpraxis in St-Vith</li>
            <li>2004-2021 Gastroenterologe in der Klinik Ste-Elisabeth in Verviers</li>
            <li>Seit 2011 Gastroenterologe in der Klinik St-Joseph in St-Vith</li>
            <li>2014-2015 Privatpraxis in Wemperhardt (Großherzogtum Luxemburg)</li>
            <li>2014-2015 Arzt im CH du Nord in Wiltz (Großherzogtum Luxemburg)</li>
        </ul>
    
        <h2>Abschlüsse</h2>
        <ul>
            <li>1999 Approbation als Arzt (Gesundheitsverwaltung des Landes Berlin)</li>
            <li>2004 Facharztanerkennung in Gastroenterologie (Belgien)</li>
            <li>2004 Diplom "Virale Hepatitis" (Universität Paris VI)</li>
            <li>2010 Diplom "Ernährung in der Gastroenterologie" (Deutsche Gesellschaft für Verdauungs- und Stoffwechselerkrankungen (DGVS))</li>
            <li>2012 Diplom "Hepatologie" (Deutsche Gesellschaft für Verdauungs- und Stoffwechselerkrankungen (DGVS))</li>
            <li>2016 Diplom "Chronisch entzündliche Darmerkrankungen" (Deutsche Gesellschaft für Verdauungs- und Stoffwechselerkrankungen (DGVS))</li>
        </ul>
    
        <h2>Haupttechniken</h2>
        Gastroskopien, Koloskopien, Platzierung von Magenballons und Gastrostomien, Leberbiopsien, abdominale Ultraschalluntersuchungen
    </div>,
    "team.nadia_torres.subtitle": "Gastroenterologe",
    "team.nadia_torres.description": <div>
        <h2>Interessengebiete</h2>
        <ul>
            <li>Pankreas und Gallengang</li>
            <li>Echo-Endoskopie und ERCP</li>
            <li>Interventionelle Endoskopie</li>
            <li>Hepatologie</li>
        </ul>

        <h2>Qualifikationen</h2>
        <ul>
            <li>Doktor der Medizin im Jahr 2016 (ULg)</li>
            <li>Spezialisierung in Gastroenterologie im Jahr 2022 (ULg), mit dem letzten Ausbildungsjahr in Mont-Godinne (UCL)</li>
            <li>Universitätsabschluss in Strahlenschutz im Jahr 2023 (ULg)</li>
        </ul>

        <h2>Professionelle Gesellschaften</h2>
        <ul>
            <li>Mitglied der SFED (Französische Gesellschaft für Gastroenterologie)</li>
            <li>Mitglied des CFE (Frankophoner Club für Echo-Endoskopie)</li>
            <li>Mitglied der ESGE (Europäische Gesellschaft für gastrointestinale Endoskopie)</li>
            <li>Mitglied der EASL (Europäische Vereinigung für Leberforschung)</li>
            <li>Mitglied der SRBGE (Königlich Belgischen Gesellschaft für Gastroenterologie)</li>
        </ul>
    </div>,
    "team.astrid_denis.subtitle": "Gastroenterologe",
    "team.astrid_denis.description": <div>
        <h2>Interessengebiete</h2>
        <ul>
            <li>Allgemeine Gastroenterologie</li>
            <li>Funktionelle Ösophaguspathologie (Ösophagusmanometrie, pH-Impedanzmessung)</li>
            <li>Videokapselendoskopie</li>
        </ul>
    
        <h2>Qualifikationen</h2>
        <ul>
            <li>Doktor der Medizin im Jahr 2000 (ULg)</li>
            <li>Spezialisierung in Gastroenterologie im Jahr 2005 (ULg)</li>
            <li>Interuniversitäres Diplom in funktioneller Verdauungspathologie im Jahr 2018 (Lyon)</li>
        </ul>
    </div>,
    


    /*
        PATHOLOGIES
    */
    "pathologies.home.title": "Entdecken Sie die Pathologien, auf die wir uns spezialisiert haben.",
    "pathologies.home.subtitle": "Wählen Sie eine Pathologie in der Schwimmleiste auf der linken Seite aus, um zu beginnen.",
    "pathologies.general.title": "Allgemein",
    "pathologies.cancer.title": "Krebs",
    "pathologies.cancer.content": <div>
        <h2>
        Allgemeine Präsentation
        </h2>

        Eine Krebserkrankung ist die abnormale Teilung und Vermehrung einer Zelle im menschlichen Körper. Diese abnormalen Zellen gruppieren sich zu einer tumorösen Masse, die wächst und in das Organ eindringt, in dem sie sich entwickelt hat, um allmählich dessen normale Funktion zu stören.
        <br/><br/>
        Infiltriert die Tumormasse ein Blutgefäß, gelangen abnorme Zellen in den Kreislauf und heften sich an andere Organe (Leber, Lunge, Gehirn). Sie entwickeln sich dort und blockieren auch seine normale Funktion, das nennt man Metastasen.
        Ohne angemessene Behandlung endet eine Krebserkrankung für den Patienten oft tödlich.
        <br/><br/>
        Jede Zelle im menschlichen Körper hat die Möglichkeit, eine krebsartige Transformation darzustellen. Bei einigen Organen ist eine solche Umwandlung häufig (Prostata, Brust, Dickdarm, Lunge) Bei anderen Organen ist diese Entwicklung selten oder sogar außergewöhnlich (Dünndarm, Muskel, Knochen, Herz)
        <br/><br/>
        Der Gastroenterologe ist der Arzt, der für die Erkennung von Krebserkrankungen der Speiseröhre, des Magens, der Leber, der Gallenwege, der Bauchspeicheldrüse, des Dünndarms (Dünndarm), des Dickdarms (Kolon und Rektum) und des Analkanals verantwortlich ist.
        <br/><br/>
        Die Spezialisten für die Behandlung von Krebserkrankungen in St. Elisabeth sind die Onkologen: Dr. Gauthier Demolin und Dr. Geoffrey Matus.
    
        <h2>
        Risikofaktoren
        </h2>
        Verschiedene Faktoren beeinflussen das Auftreten einer Krebserkrankung:
        <ul style={{listStyleType: "circle"}}>
        <li><b>Alter:</b> Krebs tritt häufiger bei Menschen über 50 auf und das Risiko steigt mit dem Alter;</li>
        <li><b>Vererbung:</b> Bestimmte Gene, die von Generation zu Generation weitergegeben werden, prädisponieren für das Auftreten von Krebs bei Mitgliedern derselben Familie, beispielsweise in der Brust oder im Dickdarm;</li>
        <li><b>Präkanzerosen:</b> In bestimmten Organen wie Dickdarm, Haut oder Blase beginnt das Auftreten von Krebs mit kleinen präkanzerösen Läsionen, die oft Jahre vor dem Krebs vorhanden sind. Diese Läsionen tun nicht weh und können jahrelang unbekannt bleiben, manchmal bis sie sich in Tumore verwandeln. Dies ist beispielsweise bei Muttermalen auf der Haut, Dickdarmpolypen oder bestimmten Bauchspeicheldrüsenzysten der Fall. Diese Krebsvorstufen können regelmäßig durch Vorsorgeuntersuchungen erkannt werden;</li>
        <li><b>Tabak:</b> Hauptrisikofaktor für Lungenkrebs. In Verbindung mit Alkohol fördert es das Auftreten von Erkrankungen des Mund-, Rachen- und Speiseröhrenbereichs. Alkohol und Tabak prädisponieren auch für eine Erkrankung der Bauchspeicheldrüse (chronische Pankreatitis), die zu Krebs ausarten kann;</li>
        <li><b>Lebensstil:</b> Die Qualität unserer Nahrung (z. B. verkochte tierische Fette), Luftverschmutzung, bestimmte Berufschemikalien, die wir schlucken oder einatmen, wirken sich auf die Entstehung von Krebs aus;</li >
        <li><b>Strahlung (Radioaktivität):</b> Auch die chronische Exposition gegenüber ionisierender Strahlung fördert die Entstehung von Krebs. Die Strahlenquelle kann natürlich (bestimmte Gesteine), zufällig (Atomunfall), beruflich (Fabriken) und manchmal medizinisch (erheblicher Überschuss an radiologischen Untersuchungen und Strahlentherapie) sein;</li>
        <li><b>Medikamente:</b> Bestimmte Medikamente zur Heilung schwerer Krankheiten können ausnahmsweise für das Auftreten von Sekundärkrebs verantwortlich sein.</li>
        </ul>
    </div>,
    "pathologies.alimentary_canal.title": "Verdauungstrakt",
    "pathologies.barrett.title": "Barrett",
    "pathologies.barrett.content": <div>
        Dies ist die Umwandlung der Ösophagusschleimhaut in Magenschleimhaut, die stärker ist, um den Aggressionen von Magensekreten zu widerstehen, die zum Reflux beitragen.
        <br/><br/>
        Dieses Phänomen tritt im Laufe der Zeit auf, wenn ein abnormer gastroösophagealer Reflux mehrere Jahre anhält.
        <br/><br/>
        Das Barrett-Screening ist bei Risikopatienten wichtig; Refluxbeschwerden sollten nicht kleingeredet werden und verdienen zumindest eine fachärztliche Beratung.
        <br/><br/>
        Die Behandlung des Barrett-Ösophagus erfordert eine umfassende medikamentöse und ernährungsphysiologische Betreuung des Patienten; Dieser Ansatz sollte mit Ihrem behandelnden Arzt und Facharzt für Gastroenterologie besprochen werden.
    </div>,
    "pathologies.reflux.title": "Gastroösophageale Refluxkrankheit",
    "pathologies.reflux.content": <div>
        Es ist per Definition der Aufstieg von Sekreten aus dem Magen in die Speiseröhre: Die damit verbundene Magen- und/oder Gallenflüssigkeit fließt aus der Magenhöhle durch den Speiseröhrengang zurück.
        <br/><br/>
        Dieses Phänomen kann unter bestimmten Umständen, insbesondere nach einer reichhaltigen Mahlzeit, als normal, dh physiologisch, angesehen werden. Letzteres kann säuerlich, sauer, meist vorübergehend, aber lästig, als Unbehagen in der Brust oder als bitterer Geschmack im Mund empfunden werden.
        <br/><br/>
        Gastroösophagealer Reflux gilt als anormal, wenn er die Lebensqualität beeinträchtigt; Häufiges oder intensives Aufstoßen kann die Verdauung täglich, tagsüber und/oder nachts stören.
        <br/><br/>
        Die Symptome können vielfältig sein und betreffen den Verdauungsbereich, HNO, Herz, Atemwege in Form insbesondere von Herzklopfen, Brennen; diese müssen in Absprache mit der Gastroenterologie evaluiert werden.
        <br/><br/>
        Während des Besuchs beim Facharzt schlägt Ihnen der Arzt die am besten geeignete Entwicklung vor und berät Sie zur Behandlung, sowohl in Bezug auf Medikamente als auch auf Ernährung.
    </div>,
    "pathologies.colorectal_cancer.title": "Kolorektales Karzinom (CRC)",
    "pathologies.colorectal_cancer.content": <div>
        Darmkrebs (CRC) ist ein großes Problem der öffentlichen Gesundheit. Diese Krankheit tritt besonders häufig bei Menschen über 50 Jahren auf. Es ist der dritthäufigste Krebs bei Männern nach dem der Prostata und der Lunge und der zweithäufigste bei Frauen nach dem der Brust.
        <br/><br/>
        In Belgien werden jährlich +/- 5000 neue Fälle von CRC gemeldet.
        <br/><br/>
        Frühzeitig entdeckt, ist CRC in 90 % der Fälle heilbar. Bei später Entdeckung im metastasierten Stadium ist die Prognose deutlich zurückhaltender. Die Behandlung von Darmkrebs richtet sich nach dem Stadium der Erkrankung zum Zeitpunkt der Diagnose und kann Operation, Chemotherapie und Strahlentherapie kombinieren.
        <br/><br/>
        Die meisten kolorektalen Karzinome entwickeln sich aus kleinen präkanzerösen Läsionen, Polypen oder Adenome genannt, die Jahre vor dem Auftreten des Krebses vorhanden sind.
        <br/><br/>
        Der Patient mit einem Polypen hat normalerweise keine Symptome. Die frühzeitige Erkennung dieser Krebsvorstufen und ihre Resektion kann das Auftreten von Krebs verhindern. Dies ist der Zweck der Darmkrebsvorsorge.
        <br/><br/>

        <ArticleImage
            src={"/images/pathologies/polype_colon.jpg"}
            caption={<div>
                Dickdarmpolyp<br/>
                Quelle: <a href="https://www.gastrocochin.com/endoscopy-colonoscopy">https://www.gastrocochin.com/endoscopy-colonoscopy</a>
            </div>}/>



        <ArticleImage
            src={"/images/pathologies/cancer_colon.jpg"}
            caption={<div>
                Darmkrebs<br/>
                Quelle: <a href="https://www.researchgate.net">https://www.researchgate.net</a>
            </div>}/>

        <h2>
        Wie erkennt man Darmkrebs?
        </h2>
        Das Screening auf Darmkrebs ist für <b>asymptomatische</b> Patienten über 50 Jahren vorgesehen. Es besteht aus der Entnahme einer Stuhlprobe durch den Patienten zu Hause. Diese Probe wird dann im Labor auf mikroskopische Spuren von Blut untersucht. Das Vorhandensein von Blut im Stuhl kann mit dem Vorhandensein von Polypen oder sogar Krebs in Verbindung gebracht werden.
        Dieser Test ist einfach, schnell und kostengünstig und kann bei Ihrem Hausarzt durchgeführt werden. Ein positiver Suchtest bei einem Patienten über 50 erfordert eine Rücksprache mit dem Gastroenterologen und die Durchführung einer Darmspiegelung.
        <br/><br/>
        <em>
        Bei Darmkrebs in der eigenen oder familiären Vorgeschichte oder Verdachtssymptomen ist der Screening-Test durch Stuhlanalyse nicht indiziert und der Patient muss unverzüglich einen Gastroenterologen aufsuchen.
        </em>
        <br/><br/>
        <h2>
        Welche Symptome können auf Darmkrebs oder einen Polypen von erheblicher Größe hindeuten?
        </h2>
        Wenn CRC vorliegt, kann der Patient verschiedene Symptome aufweisen:
        <ul style={{listStyleType: "circle"}}>
        <li>Bauchschmerzen,</li>
        <li>Gewichtsverlust,</li>
        <li>Appetitlosigkeit,</li>
        <li>unerklärliche Müdigkeit,</li>
        <li>Blutverlust im Stuhl</li>
        <li>Veränderung beim Transit: Auftreten von Verstopfung oder Durchfallstuhl ohne Erklärung,</li>
        <li>Notfälle zum Stuhlgang,</li>
        <li>falsches Bedürfnis, Stuhlgang zu haben,</li>
        <li>das Vorhandensein von Anämie und Eisenmangel bei einem Bluttest.</li>
        </ul>
        <br/><br/>
        Je nach Alter, persönlicher und familiärer Vorgeschichte und dem Datum der letzten durchgeführten Dickdarmuntersuchung wird einem Patienten mit diesen Symptomen eine Darmspiegelung angeboten.
    </div>,
    "pathologies.crohn.title": "Morbus Crohn",
    "pathologies.crohn.content": <div>
        Morbus Crohn ist eine chronische Entzündung des Verdauungssystems, die durch Schübe (oder Krisen) und Remissionsphasen verläuft. Sie ist hauptsächlich durch Bauchschmerzen und Durchfall gekennzeichnet, die einige Wochen bis einige Monate andauern. Ohne Behandlung können Müdigkeit, Gewichtsverlust und sogar Mangelernährung auftreten. In einigen Fällen können nicht-verdauliche Symptome, die Haut, Gelenke, Leber oder Augen betreffen, mit der Krankheit einhergehen.
        <br/><br/>
        Die Entzündung bei dieser Krankheit kann jeden Abschnitt des Verdauungstrakts betreffen, vom Mund bis zum Anus. Aber meistens setzt es sich an der Kreuzung von Dünndarm und Dickdarm (Dickdarm) ab.
        <br/><br/>
        Die <b>Ursachen</b> dieser Krankheit sind unbekannt und wahrscheinlich vielfältig und beinhalten genetische, Autoimmun- und Umweltfaktoren.

        <h3>
        Genetische Faktoren
        </h3>
        Einige Gene erhöhen das Risiko, an der Krankheit zu erkranken, aber andere Faktoren sind notwendig, damit die Krankheit auftritt. Wie bei vielen anderen Krankheiten scheint eine genetische Veranlagung in Kombination mit Umwelt- oder Lebensstilfaktoren die Krankheit auszulösen.
       
        <h3>
        Autoimmunfaktoren
        </h3>
        Morbus Crohn hat Merkmale einer Autoimmunerkrankung. Wissenschaftler glauben, dass eine Entzündung des Verdauungstrakts mit einer übermäßigen Immunreaktion des Körpers gegen im Darm vorhandene Viren oder Bakterien zusammenhängt.

        <h3>
        Umweltfaktoren
        </h3>
        Morbus Crohn ist in Industrieländern häufiger. Dies deutet darauf hin, dass Umweltfaktoren, die wahrscheinlich mit dem westlichen Lebensstil zusammenhängen, den Ausbruch der Krankheit beeinflussen könnten. Rauchen erhöht das Risiko von Krankheitsschüben. Auch die sitzende Lebensweise könnte einen Einfluss haben.
        <br/><br/>
        Es ist möglich (kein absoluter Beweis), dass der Verzehr von zu viel Fleisch und Zucker das Risiko erhöht. Auch der psychische Zustand könnte einen Einfluss auf das Auftreten von Rückfällen haben.
        <br/><br/>
        Auch das Ungleichgewicht der Darmflora (also Bakterien, die natürlicherweise im Verdauungstrakt vorhanden sind) könnte die Ursache der Erkrankung sein.
   
        <h2>
        Evolution
        </h2>
        Es ist eine chronische Krankheit, die das ganze Leben lang besteht. Am häufigsten entwickelt sich Morbus Crohn in Schüben, die mit Remissionsperioden von sehr unterschiedlicher Dauer durchsetzt sind. Rezidive (oder Krisen) folgen eher unvorhersehbar aufeinander und sind von unterschiedlicher Intensität.
        <br/><br/>
        Einige Morbus Crohn sind relativ mild und mit weit auseinander liegenden Attacken. Andere sind leider schwerer mit häufigeren Attacken. Es gibt daher mehrere mögliche Arten dieser Krankheit.
       
        <h2>
        Unterstützt
        </h2>
        Eine optimale medizinische Überwachung ist bei dieser Krankheit unerlässlich und viele Behandlungen sind möglich. Unser Service bietet eine personalisierte Betreuung: Das vertrauensvolle Arzt-Patienten-Verhältnis ist in dieser Betreuung wichtiger denn je und ermöglicht eine Behandlung, die wirklich auf den Menschen mit dieser Krankheit abgestimmt ist.
        <br/><br/>
        Mehrere Mitglieder des Teams sind auf diese Krankheit spezialisiert und geben ihre Ratschläge und Erfahrungen weiter. Darüber hinaus wird die Zusammenarbeit mit anderen Fachgebieten selbstverständlich nach den Bedürfnissen des Patienten eingerichtet (Chirurgie, Radiologie, Diätetik, Anatomopathologie, Tabak, Psychologie usw.)
        <br/><br/>
        Unser Service unterhält privilegierte Verbindungen zu anerkannten internationalen Referenzzentren, die es dem Patienten ermöglichen, bei Bedarf von neuen Behandlungen zu profitieren, die in Zusammenarbeit mit diesen renommierten Zentren untersucht werden.
        <br/><br/>
        Um den eigenen Umgang mit dieser Art von chronischen Erkrankungen zu verbessern, ist es manchmal sinnvoll, einer Patientenvereinigung beizutreten.
        <br/><br/>
        Nützlicher Link: <a href="http://www.mici.be/">http://www.mici.be/</a>
    </div>,
    "pathologies.ulcerative_colitis.title": "Colitis ulcerosa",
    "pathologies.ulcerative_colitis.content": <div>
        Colitis ulcerosa ist eine chronisch entzündliche Darmerkrankung (IBD) des Dickdarms und Rektums. Im Gegensatz zu Morbus Crohn, der jeden Abschnitt des Verdauungstrakts betreffen kann, ist Colitis ulcerosa auf Dickdarm und Rektum beschränkt.
        <br/><br/>
        Das Rektum ist immer betroffen und je nach RCUH-Form ist eine unterschiedliche Länge des Dickdarms von der Krankheit betroffen. In einigen Fällen können nicht-verdauliche Symptome, die Haut, Gelenke, Leber oder Augen betreffen, mit der Krankheit einhergehen.
        <br/><br/><br/>
        Die <b>Ursachen</b> dieser Krankheit sind unbekannt und wahrscheinlich vielfältig und beinhalten genetische, Autoimmun- und Umweltfaktoren.
        <br/><br/>
        Es ist eine entzündliche Erkrankung mit einem komplexen Mechanismus. Es ist keine ansteckende Krankheit und sie ist nicht direkt auf eine Infektion durch einen Keim oder Virus zurückzuführen. Auch das Ungleichgewicht der Darmflora (also Bakterien, die natürlicherweise im Verdauungstrakt vorhanden sind) könnte die Ursache der Erkrankung sein.
        <br/><br/>
        Stress und Nahrungsmittelunverträglichkeiten können bei manchen Menschen die Symptome auslösen, aber diese Faktoren sind nicht die Ursache der Krankheit.
    
        <h2>Entwicklung</h2>
        Es handelt sich um eine chronische Krankheit, die sich in Schüben entwickelt, die von mehr oder weniger langen Remissionsphasen getrennt sind.
        <br/><br/>
        Auf das Rektum beschränkte Formen sind weniger schwerwiegend als Formen, die den gesamten Dickdarm umfassen.
        <br/><br/>
        Es gibt eine sehr schwere Form mit dem Risiko einer Perforation des Dickdarms, die eine dringende Operation erfordern kann.

        <h2>Unterstützung</h2>
        Eine optimale medizinische Überwachung ist bei dieser Krankheit unerlässlich und viele Behandlungen sind möglich. Unser Service bietet eine personalisierte Betreuung: Das vertrauensvolle Arzt-Patienten-Verhältnis ist in dieser Betreuung wichtiger denn je und ermöglicht eine Behandlung, die wirklich auf den Menschen mit dieser Krankheit abgestimmt ist.
        <br/><br/>
        Mehrere Mitglieder des Teams sind auf diese Krankheit spezialisiert und geben ihre Ratschläge und Erfahrungen weiter. Darüber hinaus wird die Zusammenarbeit mit anderen Fachrichtungen selbstverständlich nach Bedarf des Patienten eingerichtet (Chirurgie, Radiologie, Diätologie, Anatomopathologie etc.)
        <br/><br/>
        Bei Bedarf unterhält unser Service privilegierte Verbindungen zu anerkannten internationalen Referenzzentren, die es dem Patienten ermöglichen, von neuen Behandlungen zu profitieren, die in Zusammenarbeit mit diesen renommierten Zentren untersucht werden.
        <br/><br/>
        Um den eigenen Umgang mit dieser Art von chronischen Erkrankungen zu verbessern, ist es manchmal sinnvoll, einer Patientenvereinigung beizutreten.
        <br/><br/>
        Nützlicher Link: <a href="http://www.mici.be/">http://www.mici.be/</a>
    </div>,
    "pathologies.liver.title": "Leber",
    "pathologies.viral_hepatitis.title": "Virushepatitis",
    "pathologies.viral_hepatitis.content": <div>
        Der Begriff Hepatitis bezeichnet jeden entzündlichen Prozess in der Leber. Der Begriff chronische Hepatitis bezeichnet eine fortschreitende Entzündung der Leber, die länger als sechs Monate anhält. Unterhalb dieser sechs Monate gilt der Prozess als akut.
        Virushepatitis ist eine Entzündung der Leber, die durch eine Infektion mit einem Virus verursacht wird. Es gibt verschiedene Arten von Viren, die die Leber entzünden können:
        <ol type='a'>
        <li>unspezifische Leberviren (z. B. CMV, EBV, …) und </li>
        <li>spezifische Leberviren (Hepatitis-A-, -B-, -C-, -D-, -E-Viren).</li>
        </ol>

        <h2>
        Hepatitis A
        </h2>
        <h3>
        Zusammenfassung
        </h3>
        Hepatitis A ist eine virale Lebererkrankung, deren Verlauf meistens gutartig ist, aber in manchen Fällen schwerwiegend sein kann.
        Das Hepatitis-A-Virus wird durch die Aufnahme von kontaminiertem Wasser oder Lebensmitteln oder durch direkten Kontakt mit einer infizierten Person übertragen.
        Fast jeder wird vollständig von Hepatitis A geheilt, indem er für den Rest seines Lebens immun ist. Ein sehr kleiner Teil der Infizierten stirbt jedoch an einer fulminanten Hepatitis.
        Das Virus wird hauptsächlich verbreitet, wenn eine nicht infizierte (oder ungeimpfte) Person Wasser oder Lebensmittel zu sich nimmt, die mit dem Kot einer infizierten Person kontaminiert sind. In der Familie kann dies vorkommen, wenn eine infizierte Person mit schmutzigen Händen Essen für Familienmitglieder zubereitet. Das Virus kann auch durch engen Körperkontakt mit einer infizierten Person übertragen werden, aber es wird nicht durch gewöhnlichen Kontakt von Person zu Person verbreitet.
        Es gibt einen sicheren und wirksamen Impfstoff zur Vorbeugung von Hepatitis A.
        <br/><br/>
        Im Gegensatz zu Hepatitis B und Hepatitis C verursacht Hepatitis A keine chronische Lebererkrankung.

        <h3>
        Symptome
        </h3>
        Die Inkubationszeit für Hepatitis A beträgt in der Regel 14 bis 28 Tage.
        Die Symptome von Hepatitis A können leicht oder schwer sein: Fieber, schlechter Allgemeinzustand, Appetitlosigkeit, Durchfall, Übelkeit, Bauchbeschwerden, dunkler Urin und Gelbsucht (Gelbfärbung der Haut und des Augenweißes). Nicht alle Infizierten haben alle diese Symptome.
        Erwachsene zeigen häufiger Krankheitssymptome als Kinder

        <h3>
        Wer sind die gefährdeten Personen?
        </h3>
        Jeder, der noch nie infiziert oder geimpft wurde, kann Hepatitis A bekommen.

        <h3>
        Behandlung
        </h3>
        Es gibt keine spezifische Behandlung für Hepatitis A. Die Erholung von den Symptomen nach einer Infektion kann langsam sein und mehrere Wochen oder Monate dauern. Das Wichtigste ist, unnötige Medikamente zu vermeiden. Verschreiben Sie kein Paracetamol/Paracetamol oder Antiemetika (Medikamente gegen Erbrechen).
        Es ist nicht sinnvoll, den Patienten ins Krankenhaus einzuweisen, wenn er sich nicht mit akuter Hepatitis-Insuffizienz vorstellt. Hauptziel der Behandlung ist es, ein gewisses Wohlbefinden und eine ausgewogene Ernährung aufrechtzuerhalten, insbesondere um Flüssigkeitsverluste durch Erbrechen und Durchfall auszugleichen.

        <h3>
        Verhütung
        </h3>
        Die Ausbreitung von Hepatitis A kann begrenzt werden durch:
        gute persönliche Hygiene, einschließlich regelmäßiges Händewaschen mit sauberem Wasser.


        <h3>
        Impfung
        </h3>
        Mehrere inaktivierte injizierbare Hepatitis-A-Impfstoffe sind auf dem internationalen Markt erhältlich. Alle sind in Bezug auf Schutz vor dem Virus und Nebenwirkungen vergleichbar. Für Kinder unter einem Jahr ist kein Impfstoff zugelassen.
        Weltweit haben Millionen von Menschen den injizierbaren inaktivierten Hepatitis-A-Impfstoff erhalten, ohne dass schwerwiegende Nebenwirkungen aufgetreten sind.

        Einige Länder empfehlen auch die Impfung von Personen mit erhöhtem Hepatitis-A-Risiko, darunter:
        <ul style={{listStyleType: "circle"}}>
            <li>Reisende in Länder, in denen das Virus endemisch ist;</li>
            <li>Männer, die Sex mit Männern haben;</li>
            <li>Freizeitdrogenkonsumenten.</li>
            <li>Menschen mit chronischer Lebererkrankung (aufgrund des erhöhten Risikos schwerwiegender Komplikationen, wenn sie sich mit HAV infizieren).</li>
        </ul>

        <h2>
        Hepatitis B
        </h2>

        <h3>
        Zusammenfassung
        </h3>
        Hepatitis B ist eine Virusinfektion, die die Leber angreift. Es kann akute oder chronische Erkrankungen verursachen. Die chronische Form setzt die Patienten einem erheblichen Todesrisiko durch Zirrhose und Leberkrebs aus.
        Das Virus wird durch Kontakt mit Blut oder anderen Körperflüssigkeiten einer infizierten Person übertragen.
        Hepatitis B ist ein erhebliches Berufsrisiko für Beschäftigte im Gesundheitswesen.
        Hepatitis B kann mit dem derzeit verfügbaren sicheren und wirksamen Impfstoff verhindert werden. Dieser Impfstoff ist zu 95 % wirksam bei der Verhinderung von Infektionen und der Entwicklung von chronischer Hepatitis und Leberkrebs aufgrund von Hepatitis B.

        <h3>
        Übertragung
        </h3>
        Das Hepatitis-B-Virus kann außerhalb des Körpers mindestens 7 Tage überleben. Während dieser Zeit ist es immer noch wahrscheinlich, dass das Virus eine Infektion verursacht, wenn es in den Körper einer nicht durch Impfung geschützten Person eindringt. Die Inkubationszeit für Hepatitis B beträgt durchschnittlich 75 Tage, kann aber zwischen 30 und 180 Tagen variieren. Das Virus ist 30 bis 60 Tage nach der Infektion nachweisbar und kann im Körper persistieren und eine chronische Hepatitis B verursachen.

        In Gebieten mit hoher Endemie (Afrika, Asien) sind die häufigsten Ausbreitungswege von Hepatitis B die perinatale Übertragung (von der Mutter auf das Kind) und die horizontale Übertragung (Kontakt mit infiziertem Blut), insbesondere zwischen einem infizierten Kind und einem nicht infizierten Kind während der ersten 5 Lebensjahre. Der Beginn einer chronischen Infektion ist sehr häufig bei Säuglingen, die von ihrer Mutter oder vor dem 5. Lebensjahr infiziert wurden.
        Hepatitis B wird auch durch perkutane Exposition oder durch Schleimhäute sowie durch Speichel, Menstruationsausfluss oder Vaginal- und Samensekrete verbreitet. Auch eine sexuelle Übertragung von Hepatitis B ist möglich, insbesondere bei ungeimpften Männern, die Sex mit Männern haben, und bei heterosexuellen Menschen mit mehreren Partnern oder Kontakten zu Sexarbeiterinnen.
        Im Erwachsenenalter führt die Infektion in weniger als 5 % der Fälle zu einer chronischen Hepatitis. Das Virus kann auch durch die Wiederverwendung von Nadeln oder Spritzen im Gesundheitswesen oder unter Personen, die Drogen injizieren, übertragen werden. Eine Infektion kann auch während medizinischer, chirurgischer oder zahnärztlicher Eingriffe, Tätowierungen oder bei der Verwendung von Rasierern oder ähnlichen Gegenständen auftreten, die mit infiziertem Blut kontaminiert sind.

        <h3>
        Symptome
        </h3>
        In der akuten Phase der Infektion treten in den meisten Fällen keine Symptome auf. Manche Menschen haben jedoch eine akute Erkrankung mit Symptomen, die mehrere Wochen anhalten, darunter Gelbfärbung der Haut und Augen (Gelbsucht), dunkel gefärbter Urin, extreme Müdigkeit, Übelkeit, Erbrechen und Bauchschmerzen.
        Eine kleine Anzahl von Menschen mit akuter Hepatitis entwickelt ein akutes Leberversagen, das zum Tod führen kann.
        Bei manchen Menschen kann das Hepatitis-B-Virus eine chronische Leberinfektion verursachen, die später zu Leberzirrhose oder Leberkrebs führen kann.

        <h3>
        HBV-HIV-Koinfektion
        </h3>
        Etwa 1 % der HBV-Infizierten (2,7 Millionen Menschen) haben auch HIV. Umgekehrt beträgt die weltweite Prävalenz einer HBV-Infektion bei HIV-infizierten Personen 7,4 %.

        <h3>
        Diagnose
        </h3>
        Es ist klinisch nicht möglich, Hepatitis B von Hepatitis zu unterscheiden, die durch andere Viruserreger verursacht wird, daher ist eine Laborbestätigung der Diagnose unerlässlich. Zur Diagnose und Überwachung von Menschen mit Hepatitis B stehen mehrere Bluttests zur Verfügung. Sie können auch zur Unterscheidung akuter von chronischen Infektionen verwendet werden.

        <h3>
        Behandlung
        </h3>
        Es gibt keine spezifische Behandlung für akute Hepatitis B. Die Pflege zielt darauf ab, das Wohlbefinden des Patienten und das Ernährungsgleichgewicht zu erhalten, insbesondere mit einer Flüssigkeitssubstitution bei Erbrechen und Durchfall.
        Chronische Hepatitis B kann mit Medikamenten behandelt werden, einschließlich oraler antiviraler Mittel. Eine solche Behandlung kann das Fortschreiten der Zirrhose verlangsamen, das Auftreten von Leberkrebs reduzieren und das Langzeitüberleben verbessern.
        <br/><br/>
        Unter den Langzeitkomplikationen von HBV-Infektionen sind Zirrhose und hepatozelluläres Karzinom für eine erhebliche Krankheitslast verantwortlich. 

        <h3>
        Verhütung
        </h3>
        Der Hepatitis-B-Impfstoff ist der Schlüssel zur Vorbeugung dieser Krankheit. Die WHO empfiehlt, diesen Impfstoff allen Säuglingen so bald wie möglich nach der Geburt zu verabreichen. Die geringe Inzidenz chronischer HBV-Infektionen bei Kindern unter 5 Jahren kann auf die weitverbreitete Verwendung des Hepatitis-B-Impfstoffs zurückgeführt werden.
        Weltweit wurde die Prävalenz dieser Infektion in dieser Altersgruppe im Jahr 2015 auf 1,3 % geschätzt, verglichen mit 4,7 % vor Einführung der Impfung. Auf die Geburtsdosis sollten 2-3 weitere Dosen folgen, um die erste Impfserie zu vervollständigen.
        <br/><br/>
        In Ländern mit niedriger oder mittlerer Endemie sollten alle noch nicht geimpften Kinder und Jugendlichen unter 18 Jahren geimpft werden. In solchen Situationen ist es möglich, dass sich mehr Mitglieder von Risikogruppen infizieren und diese Gruppen daher ebenfalls geimpft werden müssen. In diesem Fall ist es:
        <ul style={{listStyleType: "circle"}}>
            <li>Personen, die häufig Blut oder Blutprodukte benötigen, Dialysepatienten und Empfänger von Transplantaten fester Organe;</li>
            <li>Häftlinge;</li>
            <li>injizierende Drogenkonsumenten;</li>
            <li>Personen, die Sex mit Personen haben, die chronisch von HBV betroffen sind;</li>
            <li>Menschen mit mehreren Sexualpartnern:</li>
            <li>Personal des Gesundheitswesens und Personen, bei denen das Risiko einer beruflichen Exposition gegenüber Blut und Blutprodukten besteht; und</li>
            <li>Reisenden, die nicht vollständig geimpft wurden, sollte der Impfstoff angeboten werden, bevor sie in ein Endemiegebiet reisen.</li>
        </ul>
        Der Impfstoff hat in der Vergangenheit bemerkenswerte Sicherheit und Wirksamkeit gezeigt. Seit 1982 wurden weltweit mehr als eine Milliarde Impfdosen gegen Hepatitis B verabreicht.

        <h2>
        Hepatitis C
        </h2>
        Hepatitis C ist eine durch ein Virus verursachte Lebererkrankung. Das Hepatitis-C-Virus kann sowohl akute als auch chronische Leberinfektionen verursachen, deren Schweregrad von einer leichten Form, die einige Wochen anhält, bis zu einer schweren Erkrankung reicht, die ein Leben lang anhält.
        Das Hepatitis-C-Virus wird durch Blut übertragen, und die häufigsten Infektionswege resultieren aus dem Kontakt mit kleinen Blutmengen, die durch intravenösen Drogenkonsum, unsichere Injektionen, gefährdete Gesundheitsversorgung und die Transfusion von Blut oder abgeleiteten Produkten auftreten, für die es keine gibt Screening.
        Weltweit sind etwa 71 Millionen Menschen chronische Träger von Hepatitis C.
        Bei einer beträchtlichen Anzahl von Menschen mit der chronischen Form der Krankheit schreitet die Infektion zu Zirrhose oder Leberkrebs fort.
        Etwa 399.000 Menschen sterben jedes Jahr an Hepatitis C, meist an Zirrhose oder hepatozellulärem Karzinom.
        Antivirale Medikamente können mehr als 95 % der mit dem Hepatitis-C-Virus infizierten Menschen heilen und das Todesrisiko durch Leberkrebs oder Leberzirrhose verringern.
        In Belgien kennen sich 50 % der Infizierten nicht. Daher ist es wichtig, die Risikopersonen zu untersuchen:
        Gegenwärtige Behandlungen sind kurze Behandlungen (8 bis 12 Wochen), mit sehr wenigen Nebenwirkungen (Kopfschmerzen, Müdigkeit, Schlaflosigkeit) und sehr effektiv (95 bis 100 % Viruseradikation).
        Derzeit gibt es keinen Impfstoff gegen Hepatitis C.

        <h3>
        Symptome
        </h3>
        Wie bei anderen Hepatitiden sind die Symptome unspezifisch. Bei einer chronischen Infektion ist Müdigkeit das häufigste Symptom.

        <h3>
        Übertragung
        </h3>
        Es wird durch Blut gemacht.

        <h3>
        Diagnose
        </h3>
        Die Diagnose wird durch einen Bluttest gestellt:
        <ol>
        <li>Antikörper (Screening)</li>
        <li>Forschung nach viraler RNA durch PCR (diagnostisch)</li>
        </ol>

        <h3>
        Impfung
        </h3>
        Gegen dieses Virus gibt es keine Impfung.

        <h3>
        Behandlung
        </h3>
        Die Behandlungen sind sehr wirksam (über 95 % wirksam bei Ausrottung des Virus). Sie dauern an (8 bis 12 Wochen). Sie haben wenige Nebenwirkungen (Müdigkeit, Kopfschmerzen, Schlaflosigkeit).

        <h3>
        Screening
        </h3>
        50 % der infizierten Patienten sind sich ihrer selbst nicht bewusst. Es ist wichtig, das Screening in Risikogruppen zu optimieren

        <h3>
        Risikogruppen
        </h3>
        <ul style={{listStyleType: "circle"}}>
            <li>injizierende Drogenkonsumenten;</li>
            <li>injizierende Drogenkonsumenten;</li>
            <li>Personen, die infizierte Blutprodukte erhielten oder sich invasiven Untersuchungen in Gesundheitseinrichtungen mit mangelhafter Infektionskontrolle oder vor 1970 unterzogen haben;</li>
            <li>Kinder von Müttern, die mit HCV infiziert sind;</li>
            <li>Personen, deren Sexualpartner mit HCV infiziert sind;</li>
            <li>Menschen mit einer HIV-Infektion;</li>
            <li>Gefangene oder Personen, die in der Vergangenheit inhaftiert waren; und</li>
            <li>Personen, die Tätowierungen oder Piercings haben.</li>
        </ul>

        <h3>
        Beratung im Infektionsfall zur Vermeidung von Kontaminationen
        </h3>
        Teilen Sie keine Nagelscheren, Nagelknipser, Zahnbürsten, Haarbürsten, Kämme, Rasierer usw.

        <h2>
        Hepatitis-Delta
        </h2>
        Nur vorhanden, wenn der Patient mit dem Hepatitis-B-Virus infiziert ist.


        <h2>
        Hepatitis E
        </h2>
        Hepatitis E ist eine Lebererkrankung, die durch eine Infektion mit einem Virus, dem Hepatitis-E-Virus (HEV), verursacht wird.
        Das Hepatitis-E-Virus wird fäkal-oral übertragen, hauptsächlich durch kontaminiertes Wasser oder nach dem Verzehr von unzureichend gekochtem Fleisch (Schweinefleisch, Wildschwein, Hirsch) oder Schalentieren.
        Hepatitis E tritt weltweit auf, die Prävalenz ist jedoch in Ost- und Südasien am höchsten.
        Die Infektion ist normalerweise akut und folgenlos, kann aber manchmal zu einer fulminanten Hepatitis führen. Bei Immunsuppression kann die Infektion chronisch werden und eine medikamentöse Behandlung mit Ribavirin erforderlich machen.
        In einigen Fällen kann es zu extrahepatischen (z. B. neurologischen) Manifestationen kommen.
    </div>,
    "pathologies.hepatitis_misc.title": "Sonstige Hepatitis",
    "pathologies.hepatitis_misc.content": <div>
        Es gibt andere Ursachen für Hepatitis, wie z. B. Hepatitis im Zusammenhang mit Arzneimitteltoxizität.
    </div>,
    "pathologies.hepatitis_alc.title": "Alkoholische Hepatitis",
    "pathologies.hepatitis_alc.content": <div>
        Chronischer Alkoholkonsum kann zu Hepatitis (Leberentzündung) führen, die zu anormalen Leberwerten führt. Es kann zu einer Leberfibrose führen, die, wenn sie schwerwiegend ist, einer Zirrhose entspricht.
    </div>,
    "pathologies.nafld.title": "Dysmetabolische steatotische Lebererkrankung (NAFLD)",
    "pathologies.nafld.content": <div>
        Die metabolische steatotische Lebererkrankung (NAFLD – Non Alcoholic Fatty Liver Disease) ist die übermäßige Ansammlung von Fett in den Leberzellen, die nichts mit übermäßigem Alkoholkonsum zu tun hat.
        <br/><br/>
        Dieses überschüssige Fett in der Leber kann zu einer Entzündung (nichtalkoholische Steatohepatitis oder NASH) und zu einer Leberfibrose („Narbenbildung“) führen.
        <br/><br/>
        Es ist neben der Epidemie von Fettleibigkeit und Diabetes die Hauptursache für chronische Lebererkrankungen, von denen 20-30 % der Weltbevölkerung betroffen sind.

        <h2>
            Risikofaktoren
        </h2>

        <h3>Alter und Geschlecht</h3>

        Prävalenz und Schweregrad der NAFLD sind bei Männern größer als bei Frauen und nehmen mit zunehmendem Alter zu.

        <h3>Ethnizität</h3>


        Es gibt sozioökonomische, kulturelle und genetische Faktoren, die eine unterschiedliche Prävalenz je nach ethnischer Zugehörigkeit erklären.

        <h3>Stoffwechselrisikofaktoren</h3>


        Dies ist der Hauptrisikofaktor für die Krankheit. Übergewicht/Adipositas, Diabetes, arterielle Hypertonie, metabolisches Syndrom (Zusammenschluss mehrerer Stoffwechselanomalien), Dyslipidämie (Hypercholesterinämie oder Hypertriglyzeridämie) sind Risikofaktoren für NAFLD (und ihren Schweregrad)

        <h3>Lebensstil</h3>


        Überschüssige Kalorien, Art der Ernährung (überschüssige Fette, Limonaden, Fruchtsäfte), Mangel an körperlicher Aktivität und Bewegungsmangel sind Risikofaktoren für NAFLD.

        <h3>Genetik</h3>


        Bestimmte genetische Faktoren modifizieren das Risiko, an der Krankheit zu erkranken


        <h2>Diagnose</h2>

        Es gibt keine spezifischen Symptome der Krankheit. Die Diagnose wird im Allgemeinen auf der Grundlage einer Blutuntersuchung (auffällige Leberwerte) und / oder eines Ultraschalls des Abdomens (zu „glänzende“ Leber, die überschüssiges Fett hervorruft) hervorgerufen.

        Bei Verdacht auf NAFLD muss eine komplette Abklärung erfolgen: komplette Blutbiologie (insbesondere zum Ausschluss anderer Lebererkrankungen, Suche nach Diabetes, Cholesterinüberschuss etc.), Leberultraschall und nicht-invasive Messung der Fibrose ( bei einem Bluttest oder durch eine Elastometrie).


        <h2>Entwicklung und Komplikationen</h2>

        Die Entwicklung kann zu einer entzündlichen Leber (nichtalkoholische Steatohepatitis) und zu einer Fibrose („Narbe“) der Leber erfolgen, die manchmal zu einer Zirrhose führen kann.

        Abgesehen von Komplikationen in der Leber erhöht diese Krankheit das Risiko für Herz-Kreislauf-Erkrankungen und bestimmte Krebsarten.

        <h2>Unterstützung</h2>

        Das Management besteht aus der Kontrolle metabolischer Risikofaktoren:
        <ul style={{listStyleType: "circle"}}>
            <li>Gewichtsverlust bei Übergewicht/Adipositas, normalerweise 5–10 % des Körpergewichts</li>
            <li>Kontrolle von Bluthochdruck</li>
            <li>Korrektur von Dyslipidämie (Cholesterin - Triglyceride)</li>
            <li>Alkoholkonsum einschränken</li>
            <li>Diabeteskontrolle</li>
            <li>Regelmäßige körperliche Aktivität</li>
        </ul>

        Einige Behandlungen zur Verhinderung des Fortschreitens der Fibrose befinden sich in der Entwicklung.

        Je nach Profil und Stadium der Fibrose ist eine Nachsorge indiziert und an jeden Patienten angepasst.
    </div>,
    "pathologies.rare_diseases.title": "Seltene Krankheiten: PBC, CSP, Autoimmunhepatitis, Hämochromatose",
    "pathologies.rare_diseases.content": <div>
        Es gibt andere seltene Lebererkrankungen, die entweder mit Autoimmunerkrankungen, primärer biliärer Cholangitis, primär sklerosierender Cholangitis, autoimmuner Hepatitis, oder mit Überlastungen (z. B. Eisenüberladung) in Verbindung stehen.
    </div>,
    "pathologies.cirrhosis.title": "Zirrhose",
    "pathologies.cirrhosis.content": <div>
        Zirrhose ist eine schwere Fibrose der Leber. Fibrose ist eine Narbe, die mittelschwer oder schwer sein kann (Zirrhose). Diese Narbe kann die normale Funktion der Leber beeinträchtigen.
        <br/><br/>
        Diese Narbe (Fibrose) ist meistens eine Reaktion auf eine Leberentzündung, die verschiedene Ursachen haben kann (Alkohol, Fettleibigkeit, virale Hepatitis, Autoimmunhepatitis etc.).
        <br/><br/>
        Die Symptome sind zu Beginn der Erkrankung nicht sehr spezifisch (Müdigkeit). Bei einer fortgeschritteneren und symptomatischen Zirrhose (dies wird als „dekompensierte“ Zirrhose bezeichnet) sind die Symptome spezifischer. Es kann Verwirrtheit, Gelbsucht, Wasser in den Beinen oder im Magen sein. In manchen Fällen kann es bei Krampfadern zu Verdauungsblutungen kommen (Venenerweiterung in Verbindung mit hohem Druck infolge des Widerstands gegen die Blutpassage in der Leber).
        <br/><br/>
        Die Diagnose einer Zirrhose kann anhand von Bildgebung und Blutuntersuchungen vermutet werden. Dennoch ermöglicht eine nicht-invasive Untersuchung wie Fibroscan oder Elastometrie, den Grad der Fibrose zu beurteilen, indem die „Härte“ der Leber bildgebend gemessen wird. In einigen Fällen kann eine Leberbiopsie vorgeschlagen werden.
        <br/><br/>
        Die Behandlung richtet sich im Wesentlichen nach der Behandlung der Ursache (z. B. Viruseradikation, Alkoholentwöhnung etc.) oder nach der Behandlung von Komplikationen.
        <br/><br/>
        Eine halbjährliche Ultraschallkontrolle ist unerlässlich. Tatsächlich erhöht eine Zirrhose das Risiko von Lebertumoren, und ein halbjährliches Screening kann kleine Läsionen erkennen, für die meistens eine kurative Behandlung vorgeschlagen werden kann.
        <br/><br/>
        Am häufigsten wird eine salzarme Ernährung empfohlen. Es muss auch kalorienreich und proteinreich sein.
    </div>,
    "pathologies.bile_ducts_and_pancreas.title": "Gallengänge & Bauchspeicheldrüse",
    "pathologies.biliopancreatic_pathology.title": "Bilio-Pankreas-Pathologie",
    "pathologies.biliopancreatic_pathology.content": <div>
        Die Gallenwege sind innere Kanäle, die die von der Leber produzierte Galle in den Verdauungstrakt auf Höhe des Zwölffingerdarms ableiten.
        Die gelb/grüne Galle ist ein Verdauungsenzym, das kontinuierlich von der Leber produziert wird. Abgesehen von einer Mahlzeit wird die Galle in der Gallenblase gespeichert, einer kleinen Reservoirtasche, die am Hauptgallengang (dem Choledochus) befestigt ist. Während einer Mahlzeit zieht sich die Gallenblase zusammen und Galle wird bei Kontakt mit Nahrung in den Zwölffingerdarm gegossen, um die Verdauung zu beginnen.
        <br/><br/>
        Die Bauchspeicheldrüse ist ein hinter dem Magen gelegenes Organ, das an der Verdauung, aber auch an der Regulierung des Blutzuckerspiegels beteiligt ist.
        Verdauungsenzyme aus der Bauchspeicheldrüse werden durch den Wirsung-Kanal in den Verdauungstrakt abgegeben.
        Der gemeinsame Gallengang der Leber und der Wirsung-Gang der Bauchspeicheldrüse münden in den Zwölffingerdarm durch eine gemeinsame Öffnung: die Vaterampulle (siehe Abbildung unten).

        <ArticleImage src={"/images/pathologies/bilio-pancreatic_duct_de.png"} caption={"Quelle: WIKIPEDIA"} />

        <br/><br/>
        Erkrankungen der Gallengänge und der Bauchspeicheldrüse treten am häufigsten als Folge einer Obstruktion dieser Gänge auf.
        <br/><br/>
        Die Verstopfung der Gänge hängt meistens mit dem Durchgang eines Steins aus der Gallenblase zusammen. Das Hindernis kann aber auch auf eine Krebserkrankung zurückzuführen sein. Es handelt sich dann um einen Krebs der Gallenwege oder um einen Krebs der Bauchspeicheldrüse.
        Seltener können autoimmune oder fibrosierende entzündliche Erkrankungen die Gallenwege oder den Pankreasgang verstopfen (chronische kalzifizierende Pankreatitis, autoimmune Pankreatitis, sklerosierende Cholangitis, primär biliäre Zirrhose)
        <br/><br/>
        Die Steinwanderung verursacht, je nachdem, wo sie aufhört, unterschiedliche Symptome (Cholezystitis, Cholangitis, akute Pankreatitis) und kann eine unterschiedliche Behandlung erfordern.
        Dabei ist zu beachten, dass auch eine akute Pankreatitis und andere Erkrankungen der Bauchspeicheldrüse häufig mit einer chronischen Alkoholvergiftung einhergehen.
        <br/><br/>
        Symptome, die auf eine biliopankreatische Erkrankung hindeuten, sind:
        <ul>
        <li>starke Schmerzen, die plötzlich oberhalb des Bauchnabels und/oder unterhalb der rechten Rippen auftreten und in den Rücken ausstrahlen</li>
        <li>Übelkeit und Erbrechen nach dem Essen</li>
        <li>starker Appetitlosigkeit,</li>
        <li>Auftreten von Gelbsucht (Gelbsucht),</li>
        <li>Erscheinen von Fieber</li>
        <li>in einigen Fällen Müdigkeit und erheblicher Gewichtsverlust.</li>
        </ul>
        <br/><br/>
        Das Auftreten dieser Symptome erfordert sofortige ärztliche Hilfe.
        <br/><br/>
        Eine Obstruktion der Gallengänge oder der Bauchspeicheldrüse kann eine notfallmäßige endoskopische Untersuchung oder Operation erforderlich machen, falls erforderlich.
    </div>,
    "pathologies.anus.title": "Anus",
    "pathologies.hemorrhoids.title": "Hämorrhoiden",
    "pathologies.hemorrhoids.content": <div>
        Hämorrhoiden sind erweiterte Venen im Bereich des Anus und kommen bei jedem Menschen vor.<br/><br/>
        Man unterscheidet zwischen inneren Hämorrhoiden, die sich im Inneren des Analkanals befinden, und äußeren Hämorrhoiden, die unter der Haut außerhalb des Analkanals auftreten können. Innere Hämorrhoiden spielen eine wichtige Rolle für die Kontinenz.<br/><br/>
        
        <ArticleImage
            src={"/images/pathologies/hemorrhoids_de.jpg"}
            caption={<div>
                Unterscheidung zwischen inneren und äußeren Hämorrhoiden
            </div>}/>
        
        Hämorrhoiden können manchmal anschwellen, unangenehm werden und Blutungen verursachen. In diesem Fall spricht man von einer Hämorrhoidalerkrankung.<br/><br/>

        <h2>Ursachen der Hämorrhoidalerkrankung</h2>
        <ul>
            <li>Störungen der Darmbewegungen (Durchfall oder Verstopfung)</li>
            <li>Schwangerschaft</li>
            <li>Alkohol, würzige Mahlzeiten</li>
            <li>Bestimmte Sportarten (Gewichtheben, Radfahren)</li>
        </ul>

        <h2>Medikamentöse Behandlung</h2>
        <ul>
            <li>Sanfte Abführmittel wie zum Beispiel Macrogol, um die Darmbewegungen zu verbessern</li>
            <li>Salben, insbesondere solche auf der Basis von Cortison, zur Linderung lokaler Entzündungen</li>
            <li>Medikamente zur Verbesserung des Blutflusses (venotonische Medikamente)</li>
        </ul>

        <h2>Instrumentelle Behandlung</h2>
        <ul>
            <li>Wird bei inneren Hämorrhoiden angewendet, wenn die medikamentöse Behandlung erfolglos war</li>
            <li>Anwendung von elastischen Ligaturen, Infrarot-Photokoagulation</li>
        </ul>

        <h2>Chirurgische Behandlung</h2>
        <ul>
            <li>Wird angewendet, wenn instrumentelle Behandlungen versagen oder wenn die Hämorrhoidalerkrankung bereits fortgeschritten ist</li>
            <li>Es gibt verschiedene chirurgische Techniken, wie zum Beispiel die Laserbehandlung, die Hämorrhoidopexie nach Longo, die radikale Hämorrhoidektomie nach der Milligan-Morgan-Technik usw.</li>
        </ul>

        <h2>Hämorrhoidale Thrombose</h2>
        Manchmal kann es zu einer akuten und schmerzhaften "Krise" kommen, begleitet von dem Auftreten eines "bläulichen Knotens" nach außen. In den meisten Fällen handelt es sich dabei um eine Hämorrhoidale Thrombose.<br/><br/>
        Bei einer Hämorrhoidalen Thrombose handelt es sich um ein Blutgerinnsel in einer Hämorrhoide mit Ödemen, das sich innerhalb von 3-4 Tagen von selbst auflöst. Das Gerinnsel kann sich niemals lösen und zum Beispiel eine Lungenembolie verursachen.<br/><br/>
        Wenn die Schmerzen zu stark sind, können sie durch einen Einschnitt in die äußere Hämorrhoide gelindert werden. Dieser Eingriff sollte innerhalb von 48 Stunden nach dem Auftreten der Thrombose in der Notaufnahme oder bei einem Gastroenterologen durchgeführt werden.
    </div>,
    "pathologies.anal_fissure.title": "Analfissur",
    "pathologies.anal_fissure.content": <div>
        Eine Analfissur ist ein Riss in der Haut des Analkanals und kommt sehr häufig vor.<br/><br/>
        Sie verursacht Schmerzen, insbesondere während des Stuhlgangs, und kann mehrere Minuten oder Stunden nach der Darmentleerung anhalten. Diese Wunde führt häufig zu Blutungen.<br/><br/>
        Die Ursachen der Analfissur sind nicht eindeutig geklärt. Als mögliche Ursachen werden traumatische Einflüsse genannt, wie der Passage harter Stuhl, langanhaltender Druck auf den Anus und eine schlechte Durchblutung in diesem Bereich.<br/><br/>
        Die Heilung der Analfissur gestaltet sich schwierig, da oft ein Krampf des inneren Schließmuskels des Analkanals (der Muskel der Kontinenz) auftritt, was die Blutzufuhr zur Wunde reduziert, die für die Heilung erforderlich ist. Man geht davon aus, dass dieser Krampf hauptsächlich für die Schmerzen verantwortlich ist.<br/><br/>
        Die Hauptbehandlung bei Analfissuren besteht darin, eine Salbe aufzutragen, die den inneren Schließmuskel entspannt.
    </div>,
    "pathologies.anal_fistula.title": "Anale Fistel und perianales Abszess",
    "pathologies.anal_fistula.content": <div>
        Ein <b>analer Abszess</b> (eine mit Eiter gefüllte Höhle) kann sich manchmal um eine kleine infizierte Drüse im Inneren des Anus entwickeln. Es kann dann eine Verbindung zwischen der infizierten Drüse oder dem Abszess und der umgebenden Haut entstehen, was als <b>anale Fistel</b> bezeichnet wird.<br/><br/>
        Anale Fisteln äußern sich in der Regel durch Eiterbildung.<br/><br/>
        Bei einem analen Abszess tritt konstanter, pochender Schmerz im Analbereich auf, der nicht durch Stuhlgang gelindert wird.<br/><br/>
        Die einzige wirksame Behandlung für Fisteln und Abszesse ist ein chirurgischer Eingriff.
    </div>,
    "pathologies.anal_warts.title": "Anale Warzen",
    "pathologies.anal_warts.content": <div>
        Anale Warzen sind Hautläsionen, die durch eine sexuell übertragbare Infektion mit dem humanen Papillomavirus (HPV) entstehen. Anale Warzen sind hoch ansteckend, und der Gebrauch von Kondomen bietet keinen vollständigen Schutz. Sie können rund um den Anus, im Genitalbereich und im Mund auftreten.<br/><br/>
        Anale Warzen können von selbst innerhalb weniger Wochen verschwinden, aber wenn sie bestehen bleiben, wird empfohlen, sie zu behandeln, um das Übertragungsrisiko zu verringern und einer möglichen Entwicklung zu präkanzerösen Zellen (Dysplasie) vorzubeugen.<br/><br/>
        Es gibt verschiedene Behandlungsmöglichkeiten, um die Warzen zu entfernen (das Virus selbst wird nicht beseitigt), darunter die Anwendung einer Creme, Kryotherapie mit flüssigem Stickstoff, Elektrokoagulation mit einem elektrischen Skalpell oder chirurgische Entfernung.<br/><br/>
        Es ist wichtig, auch den sexuellen Partner zu behandeln.<br/><br/>
        Das Wiederauftreten von analen Warzen ist häufig.
    </div>,
    "pathologies.gall_and_bile_duct_stones.title": "Gallenblasen- und Gallengangsteine",
    "pathologies.gall_and_bile_duct_stones.content": <div>
        Die Gallensteinpathologie bezieht sich auf das Vorhandensein von Gallensteinen in der Gallenblase. Gallensteine, auch als Gallensteine oder biliäre Konkremente bekannt, bilden sich, wenn bestimmte Substanzen in der Galle, wie Cholesterin und Gallensalze, kristallisieren und verfestigen. Diese Steine können in ihrer Größe variieren, von kleinen Sandkörnern bis hin zu großen Felsbrocken.<br/><br/>
        <ArticleImage
            src={"/images/pathologies/gallstones_de.jpg"}
            caption={<div>
                Gallensteine<br/>
                (Steine, die sich aus ausgefallenen Gallenbestandteilen gebildet haben) <br/>
                Quelle: <a href="https://stock.adobe.com">Adobe Stock</a>
            </div>}
            smaller={true}/>
        Zu den Risikofaktoren für die Entwicklung von Gallensteinen gehören eine fettreiche und cholesterinreiche Ernährung, Fettleibigkeit, schneller Gewichtsverlust, eine familiäre Vorgeschichte von Gallensteinen, bestimmte Medikamente und bestimmte medizinische Erkrankungen wie Diabetes und Leberzirrhose.<br/><br/>
        Die Behandlung der Gallensteinpathologie hängt vom Vorhandensein oder Fehlen von Symptomen ab.<br/><br/>
        Bei fehlenden Symptomen kann eine regelmäßige Ultraschalluntersuchung empfohlen werden, um den Verlauf der Gallensteine zu überwachen.<br/><br/>
        Tritt jedoch eine Symptomatik auf, wie starke Bauchschmerzen (Gallenkolik) oder Komplikationen wie eine Entzündung der Gallenblase (Cholezystitis), kann ein medizinischer Eingriff erforderlich sein.<br/><br/>
        Die Diagnose der Gallenblasenpathologie basiert auf der Krankengeschichte, klinischen Untersuchungen, Bluttests und bildgebenden Verfahren wie Ultraschall, endoskopischem Ultraschall, CT oder abdominalem MRT.<br/><br/>
        Bei symptomatischen Fällen wird in der Regel eine Operation zur Entfernung der Gallensteine empfohlen. Das bekannteste Verfahren ist die offene Cholezystektomie (die selten geworden ist), die einen größeren Bauchschnitt erfordert, und die laparoskopische Cholezystektomie, ein weniger invasiver Ansatz, bei dem kleine Schnitte und eine Kamera den Chirurgen führen.<br/><br/>
        <ArticleImage
            src={"/images/pathologies/laparoscopic_cholecystectomy_de.jpg"}
            caption={<div>
                Laparoskopische Cholezystektomie<br/>
                Quelle: <a href="https://stock.adobe.com">Adobe Stock</a>
            </div>}/>
        Neben der Operation gibt es auch andere Behandlungsmöglichkeiten für Gallensteine, wie die chemische Auflösung mit spezifischen Medikamenten. Diese Methode ist jedoch weniger üblich und wird in der Regel nur in speziellen Fällen angewendet, in denen eine Operation für den Patienten mit hohem Risiko verbunden ist.<br/><br/>
        Die Gallengangsteine, auch als Choledocholithiasis bezeichnet, beziehen sich auf das Vorhandensein von Gallensteinen im gemeinsamen Gallengang, der Galle von der Leber zum Dünndarm transportiert. Diese Steine gelangen aufgrund von Gallenblasenkontraktionen vom Gallenblasen in den Gallengang. Seltener treten sie spontan im Gallengang aufgrund einer Gallenstase aufgrund einer Blockade auf.<br/><br/>
        Die Choledocholithiasis kann verschiedene gesundheitliche Probleme verursachen, wie Gallenkoliken, Angiocholitis (Entzündung der Gallengänge) und Pankreatitis (Entzündung der Bauchspeicheldrüse). Zu den häufigen Symptomen gehören starke Bauchschmerzen, Gelbsucht, Übelkeit, Erbrechen und Fieber.<br/><br/>
        Auch hier basiert die Diagnose der Choledocholithiasis auf der Krankengeschichte, klinischen Untersuchungen, Bluttests und bildgebenden Verfahren. Die beiden genauesten Untersuchungen zur Diagnose von Gallengangsteinen sind die endoskopische retrograde Cholangiopankreatographie (ERCP) oder die abdominale MRT.<br/><br/>
        Die Behandlung der Choledocholithiasis kann je nach Schwere der Symptome und dem Vorliegen von Komplikationen variieren. Hier sind einige mögliche Behandlungsansätze:
        <ol>
            <li><a href='../examinations/ercp'>Endoskopische retrograde Cholangiopankreatographie (ERCP)</a>: Bei diesem Verfahren wird ein Endoskop durch den Mund bis zur Papilla Vateri (der Stelle, an der der gemeinsame Gallengang in den Dünndarm mündet) eingeführt. Anschließend wird eine endoskopische Sphinkterotomie, ein kleiner Einschnitt im Schließmuskel, durchgeführt. Spezielle Instrumente können verwendet werden, um die Gallensteine zu entfernen, zu zerkleinern oder zu zertrümmern, um ihren Durchgang zu erleichtern. Falls der Stein nicht entfernt werden kann, kann vorübergehend ein Stent (röhrenförmige Prothese) eingesetzt werden, um den Gallenfluss zu ermöglichen.</li>
            <li><b>Extrakorporale Stoßwellenlithotripsie (ESWL)</b>: Diese Technik kann verwendet werden, um Gallensteine mithilfe von außerhalb des Körpers erzeugten Stoßwellen zu zerkleinern. Die Fragmente können dann mithilfe von ERCP leicht entfernt werden.</li>
        </ol>
        Es ist wichtig zu beachten, dass die Behandlung der Choledocholithiasis entsprechend der spezifischen klinischen Situation jedes Patienten individuell angepasst werden sollte. Es wird empfohlen, einen Gastroenterologen oder spezialisierten Chirurgen für eine genaue Diagnose und um die am besten geeigneten Behandlungsmöglichkeiten zu besprechen.
    </div>,
    "pathologies.irritable_bowel_syndrome.title": "Reizdarmsyndrom (RDS)",
    "pathologies.irritable_bowel_syndrome.content": <div>
        Dies ist eine echte chronische Verdauungskrankheit, häufig, immer gutartig, aber mit erheblichen Auswirkungen auf die Lebensqualität des Patienten.<br/>
        Die Hauptsymptome sind Störungen des Darms (Durchfall, Verstopfung oder eine Kombination aus Durchfall und Verstopfung), Bauchschmerzen und Blähungen.
        
        <h2>Ursachen</h2>
        Die Ursachen sind multifaktoriell und teilweise identifiziert. Dazu gehören Störungen der Darmmotilität (zu schnell oder zu langsam), viszerale Hypersensitivität und Störungen bei der Analyse schmerzhafter Botschaften im zentralen Nervensystem, Mikroentzündung der Darmwand, eine Zunahme der Permeabilität der Darmwand (was die Passage von Molekülen aus dem Darm zur Verdauungswand ermöglicht und mikroentzündliche Reaktionen auslöst), ein Ungleichgewicht der Darmflora (Dysbiose).<br/>
        Stress und Angst können die Symptome verschlimmern.<br/>
        Reizdarmsyndrom kann auch nach einer schweren infektiösen Gastroenteritis auftreten.<br/>
    
        <h2>Diagnose</h2>     
        Die Diagnose ist eine Ausschlussdiagnose. Ihr Arzt kann bestimmte Tests verschreiben, um andere Pathologien auszuschließen, aber diese werden normal sein, wenn es sich um ein Reizdarmsyndrom handelt.
    
        <h2>Behandlung</h2>
        Die Behandlung zielt darauf ab, die Häufigkeit und Intensität der Symptome zu reduzieren. Sie muss für jeden Patienten individuell angepasst werden, mit oft notwendigen Anpassungen.<br/><br/>
        Eine gesunde und ausgewogene Ernährung wird empfohlen. Es kann eine Diät mit niedrigem FODMAPs (Fermentierbare Oligosaccharide, Disaccharide, Monosaccharide und Polyole) vorgeschlagen werden, um die für Schmerzen, Blähungen und Durchfall verantwortlichen Verdauungsgärungsphänomene zu reduzieren. Dies wird hauptsächlich die Reduzierung des Verbrauchs von Laktose, bestimmten Getreidesorten, bestimmten Gemüsesorten (Knoblauch, Zwiebel, Lauch, Kohl, ...), bestimmten Früchten (Mango, Birne, Apfel, Pflaume, Trockenfrüchte), künstlichen Süßstoffen, industriellen Gerichten, ... beinhalten.<br/><br/>
        Es kann eine medikamentöse Behandlung vorgeschlagen werden, um den Transit zu regulieren (sanfte Abführmittel oder Antidiarrhoika) und die Schmerzen zu reduzieren (Krampflöser). Probiotika können ebenfalls wirksam sein.<br/><br/>
        In einigen Fällen können kleine Dosen von Antidepressiva nützlich sein, aufgrund ihrer schmerzlindernden Wirkung auf viszerale Hypersensitivität.<br/><br/>
        Das Management von Stress ist wichtig, und körperliche Aktivität verbessert die Symptome.<br/><br/>
        Hypnose hat ebenfalls eine wirkliche Wirksamkeit gezeigt.
    </div>,
    

    /*
        EXAMINATIONS
    */
    "examinations.home.title": "Erfahren Sie mehr über die Bedingungen der von uns durchgeführten Bewertungen.",
    "examinations.home.subtitle": "Wählen Sie in der Schwimmleiste auf der linken Seite eine Prüfung aus, um mehr zu erfahren.",
    "examinations.gastroscopy.title": "Gastroskopie",
    "examinations.gastroscopy.content": <div>
        Gastroskopie ist eine visuelle Untersuchung der Speiseröhre, des Magens und des ersten Teils des Dünndarms (Zwölffingerdarm) mit einer Kamera, die für die Endoskopie bestimmt ist; es ermöglicht somit, Geschwüre zu diagnostizieren, einen gastroösophagealen Reflux oder eine Nahrungsmittelallergie aufzuzeigen.
        <br/><br/>
        Hier finden Sie einige Informationen zur Gastroskopie und Antworten auf Fragen, die Sie im Allgemeinen zu dieser Untersuchungstechnik stellen.
        <ul style={{listStyleType: "circle"}}>
            <li>Warum eine Magenspiegelung?</li>
            <li>Wie bereitet man sich auf diese Prüfung vor?</li>
            <li>Wie wird die Prüfung durchgeführt? </li>
            <li>Wie mache ich einen Termin?</li>
        </ul>

        Die Untersuchung ist indiziert, um Symptome wie Brennen, Schweregefühl, Verdauungsbeschwerden oder Oberbauchschmerzen zu untersuchen.
        <br/><br/>
        Die endoskopische Exploration dauert nur wenige Minuten, manchmal etwas länger, wenn Biopsien durchgeführt werden sollen. Vor allem die Vorbereitung und Konditionierung des Patienten auf die Untersuchung nimmt Zeit in Anspruch.
        <br/><br/>
        Bei der Endoskopie kann eine Probe (Biopsie) entnommen und an ein Labor zur Analyse (CHC-Labor) geschickt werden; ein Anteil für diese Analyse kann auch vom jeweiligen Labor angefordert werden.
        <br/><br/>

        <h2>
            Bereiten Sie sich auf die Prüfung vor
        </h2>

        Sie müssen mit nüchternem Magen zu Ihrem Termin kommen.
        <br/><br/>
        In den 6 Stunden vor der Untersuchung ist auf jede feste Nahrungsaufnahme zu verzichten.
        <br/><br/>
        Wenn die Endoskopie am späten Vormittag stattfindet, können Sie ggf. auf ärztlichen Rat hin bis 2 Stunden vor der Untersuchung trinken (Getränke wie Saft, Kaffee, Tee, Wasser, keine Milch).
        <br/><br/>
        Eine Prämedikation auf der Basis von Dormicum und/oder Rapifen wird Ihnen intravenös verabreicht, um die Übelkeit zu lindern, die manchmal durch die Passage des Endoskops auf Höhe des Luft-Verdauungs-Übergangs oder des Magens verursacht wird. Ein Betäubungsspray wird auch in den Rachen gesprüht, um diesen Bereich zu betäuben.
        <br/><br/>
        Informieren Sie den medizinischen Untersucher, wenn Sie allergisch gegen Xylocain sind.
        <br/><br/>
        Sie sollten sich während der Untersuchung auf die Atmung konzentrieren. Dies beginnt mit der Speiseröhre, nachdem das Endoskop durch den Mund geführt und die Kreuzung zwischen dem Verdauungstrakt und den Atemwegen überquert wurde; dann wird das Gerät sanft durch den Magen in den Zwölffingerdarm (Beginn des Dünndarms) geschoben.

        <h2>
            Prüfungsverfahren
        </h2>

        Es ist keine schmerzhafte Untersuchung, aber manchmal unbequem. Konzentrieren Sie sich während des Eingriffs vor allem auf die Atmung.
        Im Magen und Zwölffingerdarm werden häufig Biopsien durchgeführt, insbesondere um beispielsweise das Vorliegen einer Infektion oder einer Allergie festzustellen. Diese Biopsien werden mit Einwegzangen durchgeführt und erfordern manchmal das Absetzen gerinnungshemmender Medikamente (Sintrom, Pradaxa, Eliquis usw.).
        Sobald die Prüfung vorbei ist, können Sie sich für 1/2 Stunde oder 1 Stunde im Ruheraum entspannen, um sich zu erholen. Anschließend werden Sie erneut vom Arzt aufgesucht, um sich über die Untersuchung zu informieren und eine therapeutische und/oder diätetische Beratung zu erhalten.
        Sie müssen grundsätzlich in Begleitung sein, da Sie nach der Prüfung nicht mehr fahren dürfen.
        <br/><br/>
        Es ist zu beachten, dass endoskopische Geräte zu Ihrer Sicherheit zwischen jeder Untersuchung gemäß einem von den zuständigen Behörden in Bezug auf öffentliche Gesundheit und Hygiene validierten Verfahren gründlich desinfiziert werden.
        <br/><br/>
        Wenn Sie weitere Informationen zu dieser Untersuchung wünschen, vereinbaren Sie bitte vorab telefonisch im Endoskopie-Sekretariat unter <a href="tel:003287213891"> einen Beratungstermin
        087/21.38.91
        </a>.
    </div>,
    "examinations.anorectal_echoendoscopy.title": "Anorektale Echoendoskopie",
    "examinations.anorectal_echoendoscopy.content": <div>
        Die anorektale Echoendoskopie wird durchgeführt, um den letzten Abschnitt des Dickdarms (Rektum) sowie den Anus und seine Schließmuskeln morphologisch zu untersuchen. Diese Untersuchung ermöglicht die Visualisierung der verschiedenen Schichten der Anus- und Rektumwand sowie des umgebenden Gewebes (Vorhandensein von Lymphknoten usw.).<br/><br/>
        Die Untersuchung wird in der Regel gut vertragen und sollte keine Schmerzen verursachen. Manchmal kann es zu leichten Unannehmlichkeiten im Zusammenhang mit der Einführung des Geräts kommen. Gelegentlich werden kleine Mengen Wasser in den Enddarm injiziert. Die Untersuchung erfordert keine Vorbereitung zu Hause.<br/><br/>
        Bei Ankunft in der Klinik wird Ihnen zunächst ein rektaler Einlauf verabreicht. Anschließend werden Sie auf einen Tisch gelegt, um sich bequem auf der linken Seite hinzulegen.<br/><br/>
        Der Arzt wird vorsichtig eine dünne Sonde in Ihren Anus einführen. Gelegentlich wird ein kleiner Ballon in der Größe einer Walnuss aufgeblasen. Mit der am Ende der Sonde befindlichen Ultraschallsonde wird der Arzt nun Ihre Rektumampulle und/oder den Anus und seine Schließmuskeln untersuchen. Es können beispielsweise die Muskeln, die die Anusschließmuskeln bilden (bei der Untersuchung der Ursachen von Inkontinenz), untersucht werden; Größe und Tiefe von Tumoren im Anus oder Rektum können bestimmt werden; und Abszesse und Fisteln können gesucht werden.<br/><br/>
        Am Ende der Untersuchung wird die Sonde entfernt und Sie können die Klinik sofort verlassen.<br/><br/>
        Die Untersuchung dauert etwa 10 Minuten. Sie erfordert keine Narkose, und Sie müssen nicht nüchtern sein, aber es wird empfohlen, eine Stunde vor der Untersuchung einen rektalen Einlauf durchzuführen.
    </div>,
    "examinations.anorectal_manometry.title": "Anorektale Manometrie",
    "examinations.anorectal_manometry.content": <div>
        Eine anorektale Manometrie wird durchgeführt, um die Sensibilität und motorische Funktion Ihrer Analregion und Ihres Rektums (des letzten Teils des Dickdarms) zu untersuchen. Sie kann bei anhaltender Verstopfung, unerklärlichen Schmerzen im Analbereich, Schwierigkeiten beim Zurückhalten von Stuhl oder Gas (Inkontinenz) oder vor und nach einem chirurgischen Eingriff angefordert werden.<br/><br/>
        Die Untersuchung ist nicht schmerzhaft. Sie müssen nicht nüchtern sein, aber es ist ratsam, eine Stunde vor der Untersuchung einen rektalen Einlauf durchzuführen.<br/><br/>
        Bei Ankunft in der Klinik wird Ihnen, falls zu Hause kein rektaler Einlauf durchgeführt wurde, eine Krankenschwester einen rektalen Einlauf verabreichen. Anschließend werden Sie auf einen Tisch gelegt, um sich bequem auf der linken Seite hinzulegen.<br/><br/>
        Der Arzt wird vorsichtig eine dünne Sonde in Ihren Anus einführen. Die Sonde ist kaum spürbar. In diesem Stadium der Untersuchung misst der Arzt nur die Drücke am Niveau des Analsphinkters. Anschließend werden Sie gebeten, bestimmte Bewegungen auszuführen, wie das Zusammenkneifen der Gesäßmuskeln oder Husten. Auch hier ist die Untersuchung niemals schmerzhaft. Die Manometrie dauert etwa 10 Minuten.<br/><br/>
        Am Ende der Untersuchung wird die Sonde entfernt, und Sie können die Klinik sofort verlassen.
    </div>,
    "examinations.esophageal_manometry.title": "Ösophagusmanometrie",
    "examinations.esophageal_manometry.content": <div>
        Eine Manometrie wird durchgeführt, um die motorische Funktion der Speiseröhre zu untersuchen und wenn andere Untersuchungen eine Diagnose nicht ausreichend ermöglicht haben. Manchmal führen wir diese Untersuchung auch vor der Operation durch, um sicherzustellen, dass die Speiseröhre richtig funktioniert. Eine andere Möglichkeit ist die Suche nach Brustschmerzen, die nicht auf Herzprobleme zurückzuführen sind.
        <br/><br/>
        Die Untersuchung ist nicht schmerzhaft. Es bedarf keiner Vorbereitung. Sie müssen nur 6 Stunden vorher nüchtern sein. Sie werden auf einem Sessel platziert und an beiden Nasenlöchern mit einem Spray betäubt.
        <br/><br/>
        Der Arzt führt dann eine dünne Sonde mit dem Durchmesser einer Spaghetti in Ihre Nase ein. Er wird die Sonde sanft zum Magen drücken, was nicht schmerzhaft ist. Selten kann Übelkeit auftreten. Die Manometrie dauert etwa 10 Minuten. Während dieser Zeit müssen Sie auf Anweisung des Arztes mehrmals Wasser oder Zwiebackstückchen schlucken.
        <br/><br/>
        Am Ende der Untersuchung wird die Sonde entfernt und Sie können die Klinik sofort verlassen.
    </div>,
    "examinations.urea_breath_test.title": "Harnstoff-Atemtest",
    "examinations.urea_breath_test.content": <div>
        Dieser Test kann feststellen, ob das Bakterium Helicobacter Pylori noch in Ihrem Magen vorhanden ist oder nicht.
        Dieses Bakterium wird normalerweise durch Biopsien nachgewiesen, die während einer Gastroskopie durchgeführt werden.
        <br/><br/>
        Helicobacter Pylori ist ein Risikofaktor für Magengeschwüre, aber auch für Magenkrebs.
        <br/><br/>
        Nach Erhalt der entsprechenden Antibiotikabehandlung führen wir diesen einfachen, schmerzlosen und schnellen Test durch, um festzustellen, ob die Bakterien ausgerottet wurden. Dadurch wird eine erneute Magenspiegelung vermieden.
        <br/><br/>
        Für diesen Test werden Sie gebeten, sich eines Tages morgens nüchtern bei den Schwestern zu melden. Wir werden Sie dann bitten, in kleine Reagenzgläser zu blasen und Ihren Atem zu sammeln. Sie müssen dann eine kleine Harnstoffkapsel zusammen mit einem Apfel- oder Orangensaft schlucken, der Ihnen zur Verfügung gestellt wird. Dreißig Minuten nachdem Sie den Saft getrunken haben, pusten Sie ein zweites Mal in die Reagenzgläser. Dann ist die Prüfung abgeschlossen. Der Inhalt der Reagenzgläser wird dann zur Analyse ins Labor geschickt und Ihr behandelnder Arzt wird einige Tage nach der Untersuchung über das Ergebnis informiert.
        <br/><br/>
        Im Falle einer Persistenz der Bakterien im Magen wird Ihnen Ihr Arzt möglicherweise eine zweite antibiotische Behandlung vorschlagen.
    </div>,
    "examinations.upper_digestive_tract_echo_endoscopy.title": "Echo-Endoskopie des oberen Verdauungstraktes",
    "examinations.upper_digestive_tract_echo_endoscopy.content": <div>
        Die Echoendoskopie ist eine Untersuchung, die Endoskopie und Ultraschall kombiniert. Eine Miniatur-Ultraschallsonde wird am Ende eines Endoskops befestigt und in die Nähe eines zu untersuchenden Organs gebracht. Diese Technik eignet sich zur genauen Analyse der Wand des Verdauungstraktes und der umliegenden Organe (Speiseröhre, Magen, Zwölffingerdarm, Bauchspeicheldrüse, Leber, Gallenblase, Gallenwege, innere Lymphknoten).
        Die Echoendoskopie vervollständigt die Analyse von Anomalien, die durch Gastroskopie, Scanner oder MRT festgestellt wurden. Schließlich ermöglicht es, Informationen zu erhalten, die mit anderen Techniken nicht gewonnen werden können. In einigen Fällen ermöglicht es die Entnahme von Proben aus dem erkrankten Organ, die im Labor untersucht werden, um die Art des Problems (Bakterien, Zyste, gutartiger oder bösartiger Tumor) zu bestimmen.
        <br/><br/>
        Bei der Echoendoskopie des oberen Verdauungstraktes müssen Sie wie bei der Magenspiegelung nüchtern in die Klinik kommen. Eine leichte Anästhesie wird von einem Anästhesisten durchgeführt, um eine angenehme Untersuchung für den Patienten und den Arzt zu ermöglichen. Anschließend wird ein flexibles Endoskop durch den Mund eingeführt und bis zum zu untersuchenden Bereich vorgeschoben. Der Patient wird dann zum Aufwachen in sein Zimmer zurückgebracht. Der Arzt gesellt sich wenig später zum Patienten, um ihm seine Ergebnisse zu erklären.
        <br/><br/>
        Um Untersuchungen in optimaler Qualität anbieten zu können, verfügen wir im St. Elisabeth Heusy über hochwertige Echoendoskopie-Geräte der neuesten Generation, die eine möglichst genaue Analyse ermöglichen. Die Echoendoskopie des oberen Verdauungstraktes wird von Dr. Olivier Cajot und Dr. Maxime Seivert durchgeführt.
        <br/><br/>
        Es ist wichtig darauf hinzuweisen, dass wie bei jedem anderen medizinischen Eingriff oder Eingriff am menschlichen Körper auch bei größtmöglicher Fachkenntnis und Sicherheit Komplikationen bei der oberen Echoendoskopie möglich sind. Diese sind jedoch Ausnahmen (Perforationen des Verdauungstrakts, Verdauungsblutungen, Infektionen), können jedoch einen Krankenhausaufenthalt des Patienten und manchmal eine chirurgische Behandlung erfordern.
        <br/><br/><br/><br/>
        <i>Diese Beschreibung ist inspiriert von der Website <a href="www.SFED.org">www.SFED.org</a>.</i>
    </div>,
    "examinations.ultrasound.title": "Ultraschall",
    "examinations.ultrasound.content": <div>
        Der Bauchultraschall ist eine Untersuchung, die hauptsächlich die festen oder flüssigkeitshaltigen Bauchorgane untersucht. Ultraschall setzt keine Röntgenstrahlen aus: Es verwendet Ultraschall, der von einer Sonde emittiert wird. Der Ultraschall durchdringt die Haut und das Gewebe des Patienten, um die Organe zu erreichen, die den Ultraschall je nach Dichte in Richtung des Geräts reflektieren, das ihn in Bilder umwandelt. So ermöglicht Ultraschall mit geringerer Auflösung als CT oder MRT die Darstellung der Bauchorgane wie Leber, Gallenblase, Bauchspeicheldrüse, Milz, Nieren, Hauptschlagader, große Bauchlymphknoten.
        <br/><br/>
        Dies ist der Test der Wahl zum Nachweis von Steinen (Steinen) in der Gallenblase.

        <h2>Wie wird ein abdominaler Ultraschall durchgeführt?</h2>
        Sie liegen bequem auf dem Rücken, Ihr Bauch ist bis zum Beckenboden unbedeckt. Der Arzt trägt ein leitfähiges Gel auf die Bauchhaut auf, um den Durchgang des Ultraschalls durch die Haut zu erleichtern. Die Durchführung eines Bauchultraschalls dauert etwa zwanzig Minuten.

        <h2>Praktische Informationen</h2>
        Es ist notwendig, mindestens 6 Stunden zu fasten.
        <br/><br/>
        Termin vereinbaren: <Link to={"../contact"}>Kontaktseite</Link>
    </div>,
    "examinations.ercp.title": "Endoskopische retrograde Cholangiopankreatographie (ERCP)",
    "examinations.ercp.content": <div>
        Die endoskopische retrograde Cholangiopankreatographie (ERCP) ist eine endoskopische Untersuchung, mit der bestimmte Probleme der Gallenwege und der Bauchspeicheldrüse behandelt werden können. Dieses Verfahren kann die Entfernung von Steinen umfassen, die Drainage der Bauchspeicheldrüse und/oder der Gallengänge mit dem Einsetzen einer Prothese ermöglichen.
        <br/><br/>
        Daher wird häufig eine vorläufige Bewertung durchgeführt, um das zu behandelnde Problem zu spezifizieren. Dies kann beispielsweise ein Ultraschall, ein CT, ein NMR oder eine Gallen- und Pankreas-Echo-Endoskopie sein.
        <br/><br/>
        Auch die Echoendoskopie findet in Narkose statt, es ist in unserem Service immer möglich, diese Voruntersuchung und die therapeutische ERCP in derselben Narkose durchzuführen, was wiederholte Narkosen reduziert und die Behandlung beschleunigt.

        <h2>Prüfungsablauf</h2>
        Die Gallen- und Bauchspeicheldrüsengänge münden in den Anfangsteil des Dünndarms (Zwölffingerdarm) durch eine Öffnung, die „Papille“ oder Vaterampulle genannt wird. Die Untersuchung erfolgt mit einem Endoskop, das durch den Mund in den Zwölffingerdarm geschoben wird. Dann wird durch das Endoskop ein Katheter eingeführt, der zur Papille geführt wird, um ein Kontrastmittel in den Gallen- und/oder Pankreastrakt zu injizieren. Anschließend werden Röntgenaufnahmen gemacht. Dann wird eine Behandlung durchgeführt.
        <ArticleImage 
            src={"/images/examinations/ercp_de.jpg"} 
            caption={<div>
                Prüfungsablauf<br/>
                Quelle: <a href="https://stock.adobe.com">Adobe Stock</a>
            </div>}/>
        Die Gesamtdauer der ERCP variiert stark je nach Schwierigkeitsgrad der Untersuchung und der durchzuführenden Behandlung (zwischen 15 und 120 Minuten).

        <h2>Praktische Informationen</h2>
        Vor dieser Untersuchung ist der Kontakt mit einem Arzt unbedingt erforderlich.
        <br/><br/>
        Die Untersuchung wird in Narkose durchgeführt. Meist ist ein 24-Stunden-Krankenhausaufenthalt notwendig. Diese Untersuchung wird in der Klinik St. Elisabeth in Heusy durchgeführt.
        <br/><br/>
        Wir führen diese Untersuchung durch, indem wir CO2 in den Verdauungstrakt blasen, um mit erhöhtem Komfort für den Patienten richtig zu sehen. Liegen keine Kontraindikationen vor, wird ein entzündungshemmendes Zäpfchen verabreicht, um das Risiko von Nebenwirkungen zu minimieren.
        <br/><br/>
        Am Prüfungstag dürfen Sie nicht essen. Am nächsten Tag wird die Ernährung wieder aufgenommen, es sei denn, es treten Komplikationen auf oder andere Untersuchungen sind erforderlich.
    
        <h2>Was sind die möglichen Komplikationen?</h2>
        <b>Jeder medizinische Eingriff, jede Untersuchung, jeder Eingriff am menschlichen Körper, auch wenn er unter sachkundigen und sicheren Bedingungen gemäß den aktuellen medizinischen Daten und den geltenden Vorschriften durchgeführt wird, birgt das Risiko von Komplikationen. Die häufigsten Komplikationen der therapeutischen ERCP (endoskopische Sphinkterotomie und begleitende Behandlung) sind: akute Pankreatitis, Infektion der Gallenwege oder der Gallenblase, Perforation der Verdauungswand, Verdauungsblutung. Die Häufigkeit jeder dieser Komplikationen liegt im Bereich von 1-4 %.</b>
        <br/><br/>
        Andere Komplikationen sind Ausnahmen wie Herz-Kreislauf- oder Atemwegserkrankungen. Diese Komplikationen können durch Ihre medizinische und chirurgische Vorgeschichte oder durch die Einnahme bestimmter Medikamente begünstigt werden.
        <br/><br/>
        Alle diese Komplikationen können eine Verzögerung des Zeitpunkts der Wiederernährung, eine Verlängerung des Krankenhausaufenthalts und die Notwendigkeit einer weiteren Endoskopie oder Operation erforderlich machen. Blutungen können zu Transfusionen von Blut oder Blutderivaten führen.
        <br/><br/>
        Komplikationen treten am häufigsten während der Endoskopie auf, können aber auch einige Tage nach der Untersuchung auftreten (Bauch- oder Brustschmerzen, Fieber, Schüttelfrost usw.). Es ist dann sehr wichtig, dass Sie sich umgehend mit dem behandelnden Arzt und/oder Anästhesisten in Verbindung setzen. Wenn eine Erreichbarkeit nicht möglich ist, ist es sehr wichtig, dass Sie sich schnellstmöglich an Ihren Arzt wenden oder den Notdienst der Klinik St. Elisabeth aufsuchen.

        <h2>Was sind die anderen Alternativen zu dieser Prüfung?</h2>
        Ultraschall, Scanner, Echoendoskopie und manchmal Magnetresonanz werden häufig vor der ERCP durchgeführt. Die Magnetresonanz kann es ermöglichen, die Ausführungsgänge von Leber und Bauchspeicheldrüse deutlich zu sehen und, wenn sie normal sind, eine ERCP zu vermeiden.
        <br/><br/>
        Die ERCP ist jedoch die einzige Untersuchung, die eine simultane Behandlung (Steinextraktion - Prothesenversorgung) erlaubt. Eine chirurgische Behandlung kann eine Alternative zur endoskopischen Behandlung sein, erfordert jedoch einen längeren Krankenhausaufenthalt und höhere Risiken, insbesondere bei älteren Menschen.

        <h2>Im Falle einer Ablehnung</h2>
        Wird die von Ihrem Arzt vorgeschlagene Untersuchung nicht durchgeführt, kann dies schädliche Folgen für Ihre Gesundheit haben. Eine am Gallengang verbleibende, gutartige Erkrankung kann unter anderem zu einer schweren, manchmal tödlichen Infektion führen.
    </div>,
    "examinations.colonoscopy.title": "Darmspiegelung",
    "examinations.colonoscopy.content": <div>
        Die Koloskopie besteht aus einer visuellen Untersuchung (mit einer Kamera) des Dickdarms vom Anus bis zum Caecum (Anfang des Dickdarms) sowie in einigen Fällen auch der Untersuchung des Endes des Dünndarms (Ileum). Diese Untersuchung ermöglicht es, den Ursprung von Symptomen (Transitstörungen, Bauchschmerzen, positiver Screening-Test, Anämie usw.) zu ermitteln, Schleimhautbiopsien durchzuführen (ein kleines Gewebestück zur Analyse unter dem Mikroskop zu entnehmen) und um nach Polypen oder Darmkrebs zu suchen. Es ermöglicht auch, soweit möglich, bei der Untersuchung entdeckte Polypen zu entfernen.

        <h2>
            Vor der Prüfung
        </h2>
        In den allermeisten Fällen profitieren Sie von einem vorherigen Beratungsgespräch mit dem gastroenterologischen Arzt, um die Indikation für die Untersuchung zu prüfen und erhalten ausführliche Erläuterungen, die Vorbereitung, die Methoden der endoskopischen Untersuchung sowie ein Notizbuch, das diese Informationen enthält. In diesem Notizbuch sind auch die Einwilligungen (für die Anästhesie und für die Endoskopie) enthalten, die für den Tag der Untersuchung gelesen und unterschrieben werden müssen. Während dieser Beratung haben Sie die Möglichkeit, alle Ihre Fragen zu stellen.

        <h3>
            Was ist die Vorbereitung?
        </h3>
        Für eine optimale Erkundung des Dickdarms ist es unbedingt erforderlich, einen möglichst sauberen, möglichst rückstandsfreien Dickdarm zu erhalten.
        <br/>
        Das Präparat wird individuell auf jeden Patienten abgestimmt (Dauer der Diät, Art des Abführpräparats) und im Vorgespräch zur Koloskopie festgelegt.
        <br/><br/>
        Es ist wichtig, diese Anweisungen strikt zu befolgen, um die Qualität der Untersuchung so weit wie möglich zu verbessern. Dennoch ist bei manchen Patienten die Vorbereitung trotz Beachtung der Hinweise nicht optimal und die Untersuchung muss teilweise in kurzen Abständen wiederholt oder durch eine weitere Untersuchung ergänzt werden.

        <ul>
            <li>
                <em>Rückstandsfreie Diät</em> (Dauer wird in Absprache festgelegt, abhängig von Ihrer persönlichen Situation, in der Regel 2 bis 3 Tage)
                <ul style={{listStyleType: "circle"}}>
                    <li><em>Vermeiden Sie:</em> Vollkornbrote, Getreidebrote, Müsli, Vollkornprodukte und Lebensmittel aus Vollkornprodukten, alle Obst- und Gemüsesorten, Fruchtsäfte mit Fruchtfleisch (Grapefruit, Ananas, Tomaten, Pflaumen), Milch und Milchprodukte mit Ausnahme von Joghurts ohne Fruchtstücke, Marmeladen und Gelees</li>
                    <li><em>Zugelassen:</em> Weißbrot, weißer Reis, Nudeln, Grieß, gekochter Schinken, Hähnchenbrust, mageres Fleisch, gekochtes Ei (gekocht oder gekocht), unpanierter Fisch, Kartoffeln ohne Schale, Fruchtsaft ohne Fruchtfleisch, Kompott, Gemüsebrühe, Apfelsaft, Joghurt ohne Fruchtstücke, Honig, Gewürze (Curry, Paprika, Pfeffer, Thymian, Lorbeerblatt, Estragon, Kerbel, Petersilie, Tomatensauce ohne Schale, Salz).</li>
                </ul>
            </li>
            <li>
                <em>Abführpräparat</em> (individuell in Absprache, abhängig von Ihrer persönlichen Situation)
                <br/>
                Es handelt sich um eine „Entschlackung“ auf Basis abführender Produkte. Es stehen mehrere Produkte zur Verfügung und die Wahl hängt vom Profil jedes Patienten ab. Im Allgemeinen erfordert die Vorbereitung 2 Schüsse; eine am Abend vor und die andere am Morgen der Prüfung (unter Einhaltung eines Fastenintervalls von 2 bis 3 Stunden vor der Prüfung).
                <br/><br/>
                Bei manchen Patienten kann einige Tage vor der Untersuchung eine Abführmittelbehandlung verordnet werden, beispielsweise bei schwerer Verstopfung oder nicht optimaler Vorbereitung in der Vorgeschichte.
                Diese Vorbereitung erfolgt in der Regel zu Hause, die Untersuchung erfolgt in einer Tagesklinik. Dennoch kann es in bestimmten Situationen vorkommen, dass Sie gebeten werden, am Tag vor der Untersuchung ins Krankenhaus eingeliefert zu werden, um die Blutung im Krankenhaus durchzuführen.
            </li>

            <li>
                <em>Vorbesprechung mit einem Arzt des Anästhesieteams</em> (nicht zwingend am Tag der Untersuchung)
                <br/>
                Er erklärt Ihnen die Sedierung, analysiert Ihre Krankenakte (Anamnese/Medikamente) und gibt Ihnen Anweisungen zur Fortsetzung oder zum Absetzen Ihrer Medikamente für die Untersuchung.
                Sie haben die Möglichkeit, Fragen zur Anästhesie zu stellen.
            </li>
        </ul>

        <h2>
            Während der Prüfung
        </h2>
        Die Koloskopie erfordert einen kurzen Krankenhausaufenthalt (im Allgemeinen in einer Tagesklinik) sowie eine Anästhesie vom Typ „tiefe Sedierung“, die vom Anästhesisten und Beatmungsgerät durchgeführt wird.
         Im Schlaf führt der Gastroenterologe die Untersuchung durch, ohne dass Sie nach der Untersuchung Schmerzen oder Erinnerungen verspüren.
         Die Untersuchungsdauer variiert je nach Untersuchungsbefund und ggf. zu entfernenden Polypen (in der Regel 30 bis 45 Minuten).
         Anschließend werden Sie in den Aufwachraum gebracht, wo Sie nach der Untersuchung schnell aufwachen und anschließend in Ihr Zimmer zurückgebracht werden.

         <h2>
             Nach der Prüfung
         </h2>
         Sie gehen zurück in Ihr Zimmer, um sich auszuruhen, und werden erneut vom Gastroenterologen untersucht, um Ihre Ergebnisse und die weitere Nachsorge zu besprechen. Sofern Proben entnommen wurden (Biopsien, Polypenresektionen), liegt das Ergebnis der mikroskopischen Analyse ca. 10 Tage später bei Ihrem behandelnden Arzt vor. Abhängig vom Untersuchungsergebnis kann Ihnen der Gastroenterologe ein Überwachungsintervall vorgeben.
         Aufgrund der Sedierung können Sie erst am Tag nach der Untersuchung Auto fahren, arbeiten oder wichtige Entscheidungen treffen. Für die Rücksendung ist daher eine Begleitung erforderlich.
         In Ausnahmefällen kann es sein, dass Sie gebeten werden, in der Nacht nach der Untersuchung unter Aufsicht zu bleiben.
    </div>,
    "examinations.defeco_mri.title": "Dynamische Beckenboden-MRT (Defäko-MRT)",
    "examinations.defeco_mri.content": <div>
        Die Untersuchung erfolgt in der Radiologieabteilung und wird zur Beurteilung von Beckenbodenstörungen durchgeführt. Dabei wird die Position der Beckenorgane während des Pressens, Zurückhaltens und Entleerens untersucht. Sie kann zur Untersuchung von Prolaps (harnröhren-, genital- oder rektalbedingt) oder Organabsenkung eingesetzt werden.<br/><br/>
        Im Gegensatz zur <b>Kolpo-Zysto-Defäkographie</b> ist keine Harnkatheterisierung oder Trinkvorbereitung erforderlich.
    </div>,
    "examinations.twenty_four_hour_ph_impedance_testing.title": "24-Stunden-pH-Impedanzmessung",
    "examinations.twenty_four_hour_ph_impedance_testing.content": <div>
        <h2>Warum?</h2>
        Bestätigen Sie das Vorhandensein oder Nichtvorhandensein eines pathologischen gastroösophagealen Refluxes (GER), ob sauer oder nicht sauer, und stellen Sie sicher, dass die vom Patienten präsentierten Symptome gut mit dem gastroösophagealen Reflux korreliert sind.<br/><br/>
        Zum Beispiel: wenn die Gastroskopie keine signifikanten Ösophagitis-Läsionen zeigt, die die GER-Diagnose bestätigen, wenn die Symptome nicht typisch sind, wenn die antazide Behandlung unwirksam ist, um bestimmte Indikationen für die Anti-Reflux-Chirurgie zu bestätigen, ...
    
        <h2>Wie?</h2>
        Eine dünne Sonde mit pH- und Impedanzsensoren wird durch die Nase in die Speiseröhre eingeführt und ist mit einem am Schultergurt getragenen Gerät verbunden, das Daten für 24 Stunden aufzeichnet.<br/><br/>
        Das Einsetzen der Sonde ist nicht schmerzhaft, kann jedoch vorübergehende Beschwerden im Hals und Übelkeit verursachen.<br/><br/>
        Wenn Sie Antazida einnehmen, wird der Arzt, der den Test verschreibt, Ihnen mitteilen, ob Sie es einige Tage vorher absetzen sollen oder nicht, abhängig von Ihrer Situation.<br/><br/>
        Sie sollten 6 Stunden vor dem Test nichts essen oder trinken.<br/><br/>
        Während des Tests sollten Sie normal leben, ohne das zu vermeiden, was Sodbrennen auslöst. Sie werden gebeten, die Zeiten zu notieren, in denen Sie Symptome haben, die Essenszeiten und wenn Sie liegen.<br/><br/>
        Sie müssen am nächsten Tag zurückkommen, um die Sonde zu entfernen und das Aufnahmegerät abzugeben, das dann analysiert wird.<br/><br/>
    </div>,
    
    "examinations.video_capsule_endoscopy.title": "Video-Kapsel-Endoskopie (VCE)",
    "examinations.video_capsule_endoscopy.content": <div>
        Diese Untersuchung ermöglicht die Erkundung des Dünndarms, von dem der Großteil mit konventioneller Gastroskopie und Koloskopie nicht zugänglich ist. Die Hauptindikation ist die Suche nach Läsionen, die zu Verdauungsblutungen führen.
    
        <h2>Wie?</h2>
        Die Kapsel, die so groß wie eine große Kapsel ist, wird mit etwas Wasser geschluckt und macht während ihres Transits durch den Dünndarm mehrere Fotos der Schleimhaut. Diese Fotos werden in einem am Schultergurt getragenen Gerät übertragen und aufgezeichnet.<br/>
        Eine Vorbereitung des Darms ist erforderlich, mit dem Absetzen jeder oralen Eisenaufnahme 7 bis 10 Tage vor der Untersuchung, der Einnahme einer oralen Abführmittel am Vorabend und dem vollständigen Fasten, um die Visualisierung der Darmwand zu ermöglichen.
        <br/><br/>
        Die Aufzeichnung dauert 8 bis 9 Stunden, während derer Sie nach Hause zurückkehren und gemäß den Ihnen gegebenen Anweisungen essen können. Sie werden gebeten, das Gerät am Abend oder am nächsten Tag zur Analyse zurückzugeben.<br/>
        Die verwendete Kapsel ist zur einmaligen Verwendung bestimmt und wird mit dem Stuhl ausgeschieden; sie sollte nach der Untersuchung nicht zurückgenommen werden.
        <br/><br/>
        Die wichtigsten Kontraindikationen für diese Untersuchung sind Verengungen des Dünndarms, die zu einer Retention der Kapsel führen können. Es ist daher wichtig, alle Anamnesen von Darmoperationen, Darmverschluss, entzündlichen Darmerkrankungen, Strahlentherapie, ... zu melden. Schluckstörungen sollten ebenfalls gemeldet werden, da sie in einigen Fällen eine spezielle Technik erfordern, um die Kapsel in den Darm zu platzieren.<br/>
        Eine Schwangerschaft ist eine Kontraindikation.<br/>
        Die Durchführung einer MRT (Magnetresonanztomographie) ist kontraindiziert, solange die Kapsel nicht sicher ausgeschieden wurde.
    </div>,
    
    /*
        CONTACT
    */
    "contact.title": "Kontakt",
    "contact.header.day": "Tag",
    "contact.header.morning": "Morgendliche Aktivität",
    "contact.header.afternoon": "Nachmittägliche Aktivität",
    "contact.monday": "Montag",
    "contact.tuesday": "Dienstag",
    "contact.wednesday": "Mittwoch",
    "contact.thursday": "Donnerstag",
    "contact.friday": "Freitag",
    "contact.saturday": "Samstag",
    "contact.sunday": "Sonntag",
    "contact.subtitle.schedule": "Öffnungszeiten",
    "contact.subtitle.appointment": "Terminvereinbarung",
    "contact.virginie_jadot.tuesday.morning": "Konsultationen in Heusy",
    "contact.virginie_jadot.tuesday.afternoon": "Endoskopien unter Sedierung in Heusy",
    "contact.virginie_jadot.wednesday.morning": "Ambulante Endoskopien in Heusy",
    "contact.virginie_jadot.wednesday.afternoon": "Konsultationen in Heusy",
    "contact.virginie_jadot.friday.morning": "Endoskopien (eine Woche mit Sedierung, eine Woche ambulant) in Malmedy",
    "contact.virginie_jadot.friday.afternoon": "Konsultationen in Malmedy",
    "contact.virginie_jadot.appointment": <div>Über das Sekretariat für Innere Medizin in Malmedy: <br/><a href='tel:+3280793158'>+32 80 793 158</a> oder <a href='tel:+3280793122'>+32 80 793 122</a></div>,
    "contact.ann_christine_dupin.tuesday.morning": "Ambulante Endoskopien in St. Vith",
    "contact.ann_christine_dupin.tuesday.afternoon": "",
    "contact.ann_christine_dupin.wednesday.morning": "Sedierte Endoskopien und Konsultationen in Eupen",
    "contact.ann_christine_dupin.wednesday.afternoon": "Sedierte Endoskopien und Konsultationen in Eupen",
    "contact.ann_christine_dupin.thursday.morning": "Konsultationen, ano-rektale Manometrie und ano-rektale Echo-Endoskopie in Heusy",
    "contact.ann_christine_dupin.thursday.afternoon": "Konsultationen, ano-rektale Manometrie und ano-rektale Echo-Endoskopie in Heusy",
    "contact.ann_christine_dupin.friday.morning": "Ambulante Endoskopie und sedierte Endoskopie in Heusy",
    "contact.ann_christine_dupin.friday.afternoon": "Ambulante Endoskopie und sedierte Endoskopie in Heusy",
    "contact.ann_christine_dupin.appointment": <div>
        Saint Vith: <a href="tel:+3280854111">+32 80 854 111</a><br/>
        Eupen: <a href="tel:+3287599312">+32 87 599 312</a><br/>
        Heusy: <a href="tel:+3287213700">+32 87 213 700</a>
    </div>,
    "contact.nadia_torres.monday.morning": "Sprechstunden in Heusy",
    "contact.nadia_torres.monday.afternoon": "Ambulante Endoskopien in Heusy",
    "contact.nadia_torres.tuesday.morning": "Sprechstunden in Eupen",
    "contact.nadia_torres.tuesday.afternoon": "Endoskopien unter Sedierung in Eupen",
    "contact.nadia_torres.wednesday.morning": "Endoskopien unter Sedierung in Heusy",
    "contact.nadia_torres.wednesday.afternoon": "Sprechstunden in Heusy",
    "contact.nadia_torres.friday.morning": "Endoskopien unter Sedierung in Eupen",
    "contact.nadia_torres.friday.afternoon": "Sprechstunden in Eupen",
    "contact.nadia_torres.appointment": <div>
        Eupen: <a href="tel:+3287599312">+32 87 599 312</a><br/>
        Heusy: <a href="tel:+3287213700">+32 87 213 700</a>
    </div>,
    
    "contact.astrid_denis.monday.morning": "Ambulante Endoskopien in Heusy",
    "contact.astrid_denis.monday.afternoon": "Ösophagusmanometrien / Sprechstunden in Heusy",
    "contact.astrid_denis.tuesday.morning": "Ambulante Endoskopien in Eupen",
    "contact.astrid_denis.tuesday.afternoon": "Sprechstunden in den Medizinischen Kabinetten de la Haie des Chêne in Dolembreux",
    "contact.astrid_denis.wednesday.morning": "Sprechstunden in den Medizinischen Kabinetten de la Haie des Chênes in Dolembreux",
    "contact.astrid_denis.wednesday.afternoon": "",
    "contact.astrid_denis.thursday.morning": "pH-Impedanzstudien / Sprechstunden in Heusy",
    "contact.astrid_denis.thursday.afternoon": "Endoskopien unter Sedierung in Heusy",
    "contact.astrid_denis.appointment": <div>
        Eupen: <a href="tel:+3287599312">+32 87 599 312</a><br/>
        Heusy: <a href="tel:+3287213700">+32 87 213 700</a>
    </div>,
    

    /*
        CONSTRUCTION
    */
    "construction.title": "Im Aufbau",
    "construction.description": "Diese Seite wird in Kürze verfügbar sein.",

    /*
        NOT FOUND
    */
    "not_found.title": "Fehler 404 - Seite nicht gefunden",
    "not_found.description": "Bist du verloren gegangen?",
    "not_found.call_to_action_1": "Starten Sie Ihre Suche erneut von der",
    "not_found.call_to_action_2": "Startseite",
    "not_found.call_to_action_3": "!",
};

export default german;