import React from "react";
import { Link, useLoaderData, useRouteError } from "react-router-dom";

// Styles
import "../styles/pages/Home.css";

import I18n from "../i18n/i18n";

export default function NotFound() {
  const error = useRouteError();
  console.log(error);
  useLoaderData();

  return (
    <div
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url("/images/backgrounds/not_found.jpg")`,
        color: "white",
        height: "calc(100vh - var(--navBarHeight))",
        width: "100vw",
      }}
    >
      <div className="fullSizeOpacityBoxDarker centerFlex">
        <div>
          <h3 style={{ marginBottom: "65px" }}>
            {I18n.getString("not_found.title")}
          </h3>
          <p>{I18n.getString("not_found.description")}</p>
          <p>
            {I18n.getString("not_found.call_to_action_1")}{" "}
            <Link to={`/${I18n.locale}/home`}>
              {I18n.getString("not_found.call_to_action_2")}
            </Link>
            {I18n.getString("not_found.call_to_action_3")}
          </p>
        </div>
      </div>
    </div>
  );
}
