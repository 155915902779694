import '../styles/components/Wiki.css';
import rightArrow from '../images/right_arrow_2.svg';
import I18n from '../i18n/i18n';
import { withParams } from '../lib/RouterUtils';

import React from 'react';
import { NavLink } from 'react-router-dom';
import NotFound from '../pages/NotFound';


class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };
  }

  render() {
    return(
      <div className={"accordion" + (this.state.collapsed ? " collapsed" : "")} onClick={this.props.onClick}>
        <div className='accordionButton' onClick={() => { this.setState({collapsed: !this.state.collapsed}); }}>
          <span>
            {this.props.title}
          </span>
          <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M69.8437,43.3876,33.8422,13.3863a6.0035,6.0035,0,0,0-7.6878,9.223l30.47,25.39-30.47,25.39a6.0035,6.0035,0,0,0,7.6878,9.2231L69.8437,52.6106a6.0091,6.0091,0,0,0,0-9.223Z"/></svg>
        </div>
        <div className='accordionContainer'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

class Wiki extends React.Component {
    constructor(props) {
      super(props); 
  
      this.state = {
        selected_entry: this.props.selected_entry,
        leftcol_toggle: false
      };

      this.refToScrollable = React.createRef();
    }

    selectEntry(index) {
      this.setState({
        selected_entry: index,
        leftcol_toggle: false
      });
      if (this.refToScrollable.current !== null) {
        this.refToScrollable.current.scrollTo(0, 0);
      }
    }

    hasContent(content_key) {
      for (var [key, value] of Object.entries(this.props.entries)) {
        if (value == null) {
          if (key === content_key) return true;
        } else {
          for (var v_key of value) {
            if (v_key === content_key) return true;
          }
        }
      }
      return false;
    }

    render() {
      if (this.state.selected_entry != null && !this.hasContent(this.state.selected_entry)) {
        return <NotFound/>;
      }

      return (
        <div>
          <div className='leftColumnMobileNav' onClick={(e) => {e.stopPropagation(); this.setState({leftcol_toggle: !this.state.leftcol_toggle});}}>
            <img src={rightArrow} alt="" className='leftColumnMobileNavImg'/>
          </div>
          <div className={'leftColumn' + (this.state.leftcol_toggle ? ' leftColumnToggle' : '')}>
            {Object.entries(this.props.entries).map(([key, value]) => { 
              if (value == null) {
                var selected = key === this.state.selected_entry;
  
                return (
                  <NavLink to={`/${I18n.locale}/${this.props.prefix}/${key}`} className={"selector" + (selected ? " selected" : "")} key={key} onClick={() => {this.selectEntry(key);}}>
                    {I18n.getString(`${this.props.prefix}.${key}.title`)}
                  </NavLink>
                );
              } else {
                return (
                  <Accordion title={I18n.getString(key)} key={key}>
                    {value.map((v_key) => { 
                      var selected = v_key === this.state.selected_entry;
        
                      return (
                        <NavLink to={`/${I18n.locale}/${this.props.prefix}/${v_key}`} className={"selector" + (selected ? " selected" : "")} key={v_key} onClick={() => {this.selectEntry(v_key);}}>
                          {I18n.getString(`${this.props.prefix}.${v_key}.title`)}
                        </NavLink>
                      );
                      })}
                  </Accordion>
                );
              }
              })}
          </div>
          {this.state.selected_entry == null && 
          <div className='rightColumn noPadding' onClick={() => {this.setState({leftcol_toggle: false});}}>  
            <div className='wikiHomeFullSize centerFlex'>
              {this.props.homePage}
            </div>
          </div>}
          {this.state.selected_entry != null &&
          <div className='rightColumn' ref={this.refToScrollable} onClick={() => {this.setState({leftcol_toggle: false});}}>
            <div>
              <h1>
                {I18n.getString(`${this.props.prefix}.${this.state.selected_entry}.title`)}
              </h1>
              {I18n.getString(`${this.props.prefix}.${this.state.selected_entry}.content`)}
            </div>
          </div>}
        </div>
      );
    }
  }

  export default withParams(Wiki);