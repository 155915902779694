import React from 'react';
import ArticleImage from '../../components/ArticleImage';
import { Link } from 'react-router-dom';

const french = {
    /* 
        NAVIGATION BAR 
    */
    "navbar.contact.title": "Contact",
    "navbar.examinations.title": "Examens",
    "navbar.pathologies.title": "Pathologies",
    "navbar.team.title": "Équipe",
    "navbar.news.title": "Actualités",
    "navbar.home.title": "Accueil",

    /* 
        HOMEPAGE 
    */
    "home.header.subtitle": "Le service de gastro-entérologie de l’est de la Belgique",

    "home.cornerstones.title": "Découvrez nos quatres piliers",

    "home.cornerstones.first.title": "la digestion de A à Z",
    "home.cornerstones.first.description": "Spécialisé dans la prévention, le diagnostic et le traitement des maladies de l’estomac, de l’oesophage, l’intestin, de l’anus, du foie, du pancréas, de la vésicule biliaire, des canaux biliaires et de l’obésité.",
    "home.cornerstones.second.title": "équipe multidisciplinaire & multi-horizons",
    "home.cornerstones.second.description": "Les gastro-entérologues de la clinique St Elisabeth CHC à Verviers, de la clinique St Nicolas à Eupen, de la clinique Saint Joseph à Saint Vith et de la clinique Reine Astrid à Malmédy ont réuni leurs compétences et leur expérience pour offrir une prise en charge digestive organisée, complète et en équipe.",
    "home.cornerstones.third.title": "prise en charge spécialisée",
    "home.cornerstones.third.description": "Notre service se caractérise par une prise en charge très spécialisée: chaque membre du service possède au moins un domaine d’excellence qu’il partage avec le reste de l’équipe.",
    "home.cornerstones.fourth.title": "humain & confiance",
    "home.cornerstones.fourth.description": "Votre relation de confiance avec votre médecin est indispensable pour l’efficacité de votre traitement. Nous privilégions ainsi la continuité cette relation que vous avez choisi avec un médecin de notre équipe. C’est votre gastro-entérologue qui s’entourera des compétences de votre médecin généraliste, de ses confrères gastro-entérologues, chirurgiens, radiologues, oncologues, internistes ou autres pour vous proposer une prise en charge optimale mais restant personnalisée et humaine.",
    "home.treatment_1": "Notre ",
    "home.treatment_2": "prise en charge",
    "home.treatment_3": " est réalisée le plus fréquemment en ambulatoire (consultations). En cas de maladie digestive plus sévère ou demandant un suivi quotidien, une hospitalisation est organisée dans notre service sur un des quatre sites hospitaliers. La clinique de jour est souvent utilisée pour la réalisation d'endoscopies sous sédation anesthésique ou de traitements par perfusions, par exemple.",
    "home.strengths.title": "Nos points forts",
    "home.strengths.first.title": "contact privilégié",
    "home.strengths.first.description": "Les maladies digestives sont de plus en plus traitées par plusieurs spécialités médicales et chirurgicales conjointement. La cancérologie digestive et l'obésité sont deux exemples de concertations multidisciplinaires à multiples intervenants. La particularité de notre service est que le gastro-entérologue que vous avez choisi reste votre référent. Cela permet de garder une relation médicale privilégiée avec le médecin de votre choix, qui reste humaine malgré la multitude d'intervenants parfois nécessaires.",
    "home.strengths.second.title": "soins 24h/24 & 7j/7 selon les nécessités",
    "home.strengths.second.description": "Nous sommes le service de référence pour les urgences endoscopiques, les examens endoscopiques biliaires et pancréatiques pour l'est de la Belgique pour 4 des 5 sites hospitaliers sur Verviers et Est de la Belgique.",
    "home.strengths.third.title": "matériel de pointe",
    "home.strengths.third.description": "Pour un diagnostic précis, des explorations endoscopiques ou fonctionnelles sont souvent nécessaires. Pour cela, le service dispose du meilleur matériel possible: endoscopes hautes résolution avec coloration électronique, écho-endoscopes dernière génération avec vision soit axiale (permettant des ponctions et des traitements endoscopiques), soit transversale à 360°(permets vision optimale de certaines anomalies), vidéocapsule permettant l'exploration de l'intestin grêle, manométrie haute résolution, impédancemètres, tests respiratoires au lactose, ...",
    "home.strengths.fourth.title": "sécurité du patient",
    "home.strengths.fourth.description": <div>
        <h3>Désinfection du matériel</h3>
        Le service est historiquement parmi les premiers services de la province de Liège à utiliser les pinces à biopsie à usage unique pour les prélèvements endoscopiques et les lave-endoscopes pour désinfecter les endoscopes. Actuellement, pratiquement tout le petit matériel d'endoscopie est à usage unique.
        <br/><br/>
        Les endoscopes sont désinfectés par des machines performantes et avec les produits les plus efficaces existants.
        <br/><br/>
        Chaque désinfection est tracée de A à Z et des contrôles de qualité de désinfection sont régulièrement réalisés par des prélèvements bactériologiques.
        <br/><br/>
        <h3>Vérifications multiples</h3>
        Avant toute endoscopie réalisée sous anesthésie, une checklist est utilisée pour chaque patient, comprenant entre autres la vérification de l'identité de celui-ci, de l'examen à réaliser, des allergies, du consentement du patient, ...
        <br/><br/>
        <h3>Accréditation Canada</h3>
        Un processus très exigeant d'<a href="https://accreditation.ca/qmentum-accreditation/">accréditation</a> a été suivi avec succès au CHC, piloté par un organisme international indépendant. Le CHC est accrédité "Or" par cette organisation après avoir visé en tous points possibles la sécurité du patient.
    </div>,
    "home.strengths.fifth.title": "dossier médical informatisé",
    "home.strengths.fifth.description": <div>
        100% des informations médicales de consultation, d'hospitalisation et des actes techniques sont introduits par les médecins du service dans cette base de donnée. Cela permet des contrôles statistiques, de croiser des informations et ainsi d'améliorer la qualité de la prise en charge dans notre service.
        <br/><br/>
        Par ailleurs, les consensus internationaux de prise en charge d'une maladie ou de réalisation d'un examen peuvent être intégrés sans délai dans la base de donnée, puisque celle-ci est gérée uniquement par les médecins du service.
    </div>,
    "home.strengths.sixth.title": "cohérence entre hyperspécialisations",
    "home.strengths.sixth.description": <div>
        Les gastro-entérologues ont chacun au moins un domaine d'hyperspécialisation, permettant de couvrir toutes les pathologies digestives (cancérologie, maladies du foie, Crohn, rectocolite ulcéro-hémorragique, pancréas, vésicule, proctologie, reflux et œsophagite, polypes coliques, obésité, ...).
        <br/><br/>
        Autant que possible, ces hyperspécialisations sont regroupées selon l'intérêt du patient par médecin.
        <br/><br/>
        Par exemple, ce sont les mêmes médecins qui réalisent les écho-endoscopies et les CPRE, permettant ainsi la vision d'un calcul dans le canal biliaire (écho-endoscopie) et l'extraction endoscopique de ce calcul (CPRE) durant une seule anesthésie. Ou encore, le médecin qui est formé à la manométrie anorectale pratique également l'écho-endoscopie anorectale, permettant au patient de réaliser ces examens gênants en un seul rendez-vous et avec une seule personne.
    </div>,
    
    /* 
        NEWS
    */
    "news.published_on": "Publié le",
    "news.covid_19.contents": <div>
        Bien que la situation s’améliore, le virus circule toujours et nos patients, vos proches, sont un public vulnérable.
        <br/><br/>
        Certaines précautions restent donc d’usage :
        <ul style={{listStyleType: "circle"}}>
        <li>le port du masque est obligatoire à l’intérieur de l’hôpital ;</li>
        <li>évitez de venir si vous présentez des signes de COVID-19, ou si vous avez été en contact proche avec une personne positive.</li>
        </ul>
    </div>,
    "news.covid_19.title": "COVID 19",

    /*
        TEAM
    */
    "team.title": "Notre équipe",
    "team.navigation.back": "Retour",

    "team.olivier_cajot.subtitle": "Gastro-entérologue",
    "team.olivier_cajot.description": <div>
        <h2>
        Centres d'intérêts
        </h2>
        <ul>
        <li>Pancréas et voies biliaires</li>
        <li>Maladies inflammatoires de l'intestin (Formation UCL et centre de référence CHU Lille)</li>
        <li>Echo-endoscopie (plus de 5000 examens d'expérience) et CPRE, endoscopie interventionnelle (multiples centres de formation experts : Lille, Paris, Bruxelles, Liège et Charleroi)</li>
        <li>Nutrition</li>
        </ul> 
        <h2>
        Diplomes
        </h2>
        <ul>
        <li>Docteur en médecine 1992 (UCL)</li>
        <li>Spécialisation en gastro-entérologie 1998 (UCL)</li>
        <li>Diplôme universitaire en Radio-protection en 2017 (ULG)</li>
        <li>Diplôme universitaire en maladies inflammatoire de l'intestin 2020 (Sorbonne, Paris)</li>
        </ul>
        <h2>
        Sociétés savantes
        </h2>
        <ul>
        <li>Membre du Belgian Inflammatory bowel disease Research and Development</li>
        <li>Membre du European Pancreatic Club</li>
        </ul>
    </div>,
    "team.maxime_seivert.subtitle": "Gastro-entérologue",
    "team.maxime_seivert.description": <div>
        Diplômé gastroentérologue à l'ULg en 2014, je suis un des derniers arrivés dans l'équipe de gastoentérologie de St Elisabeth à Heusy et le plus jeune médecin de l’équipe.  Jeune papa, dynamique et spontané, j’ai rapidement intégré l’équipe de St Elisabeth.
        <br/><br/>
        Formé en endoscopie interventionnelle à l'UCL chez le Pr Deprez et pratiquant, l’échographie abdominale, l'écho-endoscopie ainsi que la cholangiographie rétrograde, je m'intéresse particulièrement à la pathologie bilio-pancréatique.  
        <br/><br/>
        Bien que principalement endoscopiste, je reste évidemment gastroentérologue générale avec un intérêt particulier pour les pathologies inflammatoires intestinales après un passage en formation notamment dans les services du Pr Louis de l'ULg et du Pr Dewit à l'UCL.
    </div>,
    "team.virginie_jadot.subtitle": "Gastro-entérologue",
    "team.virginie_jadot.description": <div>
        <h2>
            Centres d’intérêt
        </h2>
        <ul>
            <li>Hépatologie (Liège et CHU de Lille)</li>
            <li>Gastro-entérologie générale (Liège)</li>
            <li>Echographie abdominale (en cours)</li>
        </ul>
        <h2>
            Diplômes
        </h2>
        <ul>
            <li>Docteur en Médecine (ULG 2013)</li>
            <li>Spécialisation en Gastro-entérologie (ULG 2019)</li>
            <li>Diplôme universitaire en maladies inflammatoires chroniques de l’intestin (Lille 2020)</li>
            <li>Diplôme universitaire en en stéatohépatite dysmétabolique/NASH (Angers 2021)</li>
        </ul>
    </div>,
    "team.alexandre_clotuche.subtitle": "Médecin hospitalier",
    "team.alexandre_clotuche.description": <div>
        Je m’appelle Alexandre Clotuche, médecin généraliste de formation.<br/><br/>
        Ayant voulu donner un nouvel élan à ma carrière, je suis devenu <b>médecin hospitalier</b>.<br/>
        En quoi cela consiste-t-il ?<br/><br/>
        Je suis en charge des patients hospitalisés dans le service de gastro-entérologie et oncologie dès leur entrée jusqu’à leur sortie et ce en étroite collaboration avec les différents médecins spécialistes mais également avec le médecin généraliste du patient et sa famille.<br/><br/>
        Mon travail consiste à poser le diagnostic, mettre au point le traitement, organiser des examens complémentaires ainsi que le suivi après l’hospitalisation chez le gastro-entérologue et/ou l’oncologue.
    </div>,
    "team.donald_vermer.subtitle": "Gastro-entérologue",
    "team.donald_vermer.description": <div>
        Cher patient, chère patiente,<br/><br/>
        Exerçant mon métier depuis plus de 15 ans, diplômé de la faculté universitaire de Louvain (UCL), je suis à votre disposition pour vous recevoir en consultation.<br/><br/>
        La médecine est un art, l’art de soigner, de soulager, de guérir.<br/><br/>
        Le patient doit être écouté pour être pris en charge dans sa globalité.<br/><br/>
        J’ai plutôt une vision holistique de la personne bien qu’il faille de temps en temps se focaliser sur un organe défectueux ou malade pour bien aider le patient dans la prise en charge de ses plaintes.<br/><br/>
        La sphère digestive est vaste ; bien que je prenne en charge tout type de problématique digestive, du reflux gastro-oesophagien à la pathologie hémorroïdaire, j’ai choisi de me spécialiser en échoendoscopie digestive et dans le suivi des maladies inflammatoires digestives (diplôme inter-universitaire de Lille sur la maladie de Crohn et la RCUH).
    </div>,
    "team.ann_christine_dupin.subtitle": "Gastro-entérologue",
    "team.ann_christine_dupin.description": <div>
        <h2>
            Formation
        </h2>
        <ul>
            <li><b>2003 :</b> Baccalauréat SGR (Rheinbach, Allemagne)</li>
            <li><b>2004-2012 :</b> Études de médecine à l’Université de Liège</li>

            <li><b>6/2012 :</b> Diplômée Docteur en médecine avec Grande Distinction</li>
        </ul>

        <h2>
            Expérience Professionnelle
        </h2>
        <ul>
            <li><b>2003-2004 :</b> Formation et stages infirmiers au Marienhospital Euskirchen (Allemagne)</li>
            <li><b>2010-2012 :</b> Stagiaire médecin de 3 ème et 4 ème Master au CHU de liège</li>
            <li><b>Octobre-novembre 2011 :</b> Mission humanitaire pour l’ONG « Femme SIDA », Lumbubashi (République Démocratique du Congo)</li>
            <li><b>2012- 2015 :</b> Médecin Assistant en médecine interne (tronc commun du Master complémentaire en gastro-entérologie) au St Nikolaus Hospital à Eupen</li>
            <li><b>2015 :</b> Médecin Assistant en médecine interne au HUG (Genève, Suisse)</li>
            <li><b>2016-2019 :</b> Médecin Assistant dans les services de gastro-entérologie à au CHR Verviers (1 ère année du tronc spécialisé), CHU de Liège et CHC de Liège </li>
            <li><b>Depuis octobre 2019 :</b> Gastro-Entérologue au CHC (sites de Heusy, Eupen et St Vith)</li>
        </ul>

        <h2>
            Publications et présentations
        </h2>
        <ul>
            <li><b>2/2018 :</b> Belgian Weeks of Gastro-Enterology, « The Duplicated Pancreas »</li>
            <li><b>2021 :</b> Publication du Case Report : « A rare cause of epigastric pain: Splenic artery dissection and aneurysm of celiac trunk in the Ehlers-Danlos syndrome type IV “,</li>
        </ul>

        <h2>
            Langues
        </h2>
        <ul>
            <li><b>Français- Allemand :</b> bilingue</li>
            <li><b>Anglais :</b> Courant</li>
        </ul>
        
        <h2>
            Centres d’intérêt
        </h2>
        Voyages, Lecture, Athlétisme
    </div>,
    "team.matthias_dietze.subtitle": "Gastro-entérologue",
    "team.matthias_dietze.description": <div>
        <h2>Cursus universitaire</h2>
        <ul>
            <li>1990-1992 Etudes de Médecine à l’Université de Leipzig (Allemagne)</li>
            <li>1992-1993 Etudes de Médecine à l’Université de Berlin (Allemagne)</li>
            <li>1993-1997 Etudes de Médecine à l’Université de Rennes I (France)</li>
        </ul>

        <h2>Carrière professionnelle</h2>
        <ul>
            <li>1997-1999 Médecin Généraliste à l’Hôpital de Gérardmer (France)</li>
            <li>1999-2004 Assistant en Gastro-entérologie à l’Université de Liège</li>
            <li>Depuis 2004 Cabinet privé à St-Vith</li>
            <li>2004-2021 Gastro-entérologue à la Clinique Ste-Elisabeth à Verviers</li>
            <li>depuis 2011 Gastro-entérologue à la Clinique St-Joseph à St-Vith</li>
            <li>2014-2015 Cabinet privé à Wemperhardt (Gd-Duché)</li>
            <li>2014-2015 Médecin au CH du Nord à Wiltz (Gd-Duché)</li>
        </ul>

        <h2>Diplômes</h2>
        <ul>
            <li>1999 Approbation de Médecin (Administration de Santé du Land de Berlin)</li>
            <li>2004 Autorisation d'exercice de Gastro-entérologie (Belgique)</li>
            <li>2004 Diplôme « Hépatites virales » (Université de Paris VI)</li>
            <li>2010 Diplôme « Nutrition en Gastro-entérologie » (Association Allemande des Maladies Métaboliques et Digestives (DGVS))</li>
            <li>2012 Diplôme « Hépatologie » (Association Allemande des Maladies Métaboliques et Digestives (DGVS))</li>
            <li>2016 Diplôme « Maladies intestinales chroniques » (Association Allemande des Maladies Métaboliques et Digestives (DGVS))</li>
        </ul>

        <h2>Techniques principales</h2>
        Gastroscopies, colonoscopies, mise en place de ballons gastriques et de gastrostomies, biopsies hépatiques, échographies abdominales
    </div>,
    "team.nadia_torres.subtitle": "Gastro-entérologue",
    "team.nadia_torres.description": <div>
        <h2>Centres d’intérêts</h2>
        <ul>
            <li>Pancréas et voies biliaires</li>
            <li>Echo-endoscopie et CPRE</li>
            <li>Endoscopie interventionnelle</li>
            <li>Hépatologie</li>
        </ul>

        <h2>Diplômes</h2>
        <ul>
            <li>Docteur en médecine en 2016 (ULg)</li>
            <li>Spécialisation en gastro-entérologie en 2022 (ULg) dont la dernière année en formation à Mont-Godinne (UCL)</li>
            <li>Diplôme universitaire en Radio-protection en 2023 (ULg)</li>
        </ul>

        <h2>Sociétés savantes</h2>
        <ul>
            <li>Membre de la SFED (Société Française d’Endoscopie Digestive)</li>
            <li>Membre de la CFE (Club Francophone d’Echo-endoscopie)</li>
            <li>Membre de l’ESGE (European Society of Gastro-intestinal Endoscopy)</li>
            <li>Membre de l’EASL(European Association for the Study of the Liver)</li>
            <li>Membre de la SRBGE (Société Royale Belge de Gastro-Entérologie)</li>
        </ul>
    </div>,
    "team.astrid_denis.subtitle": "Gastro-entérologue",
    "team.astrid_denis.description": <div>
        <h2>Centres d’intérêts</h2>
        <ul>
            <li>Gastro-entérologie générale</li>
            <li>Pathologie fonctionnelle œsophagienne (manometrie œsophagienne, pH-impédancemétrie)</li>
            <li>Videocapsule endoscopique</li>
        </ul>

        <h2>Diplômes</h2>
        <ul>
            <li>Docteur en Medecine en 2000 (ULg)</li>
            <li>Spécialisation en Gastro-entérologie en 2005 (ULg)</li>
            <li>Diplôme Inter Universitaire en pathologie digestive fonctionnelle en 2018 (Lyon)</li>
        </ul>
    </div>,

    /*
        PATHOLOGIES
    */
    "pathologies.home.title": "Explorez les pathologies dans lesquelles nous sommes spécialisés.",
    "pathologies.home.subtitle": "Sélectionnez une pathologie dans la barre de nagivation à gauche pour commencer.",
    "pathologies.general.title": "Général",
    "pathologies.cancer.title": "Cancer",
    "pathologies.cancer.content": <div>
        <h2>
        Présentation générale
        </h2>

        Une maladie cancéreuse correspond à la division et à la multiplication anormale d’une cellule du corps humain. Ces cellules anormales se regroupent en une masse tumorale qui grandit et qui envahit l’organe dans lequel elle s’est développée pour petit à petit perturber son fonctionnement normal.
        <br/><br/>
        Si la masse tumorale infiltre un vaisseau sanguin, des cellules anormales passent dans la circulation et vont s’accrocher à d’autres organes (le foie, les poumons, le cerveau). Elles s’y développent et en bloque également le fonctionnement normal, c’est ce que l’on appelle des métastases.
        Sans traitement approprié, une maladie cancéreuse est souvent fatale pour le patient.
        <br/><br/>
        Toute cellule du corps humain a la possibilité de présenter une transformation cancéreuse. Pour certains organes, une telle transformation est fréquente (prostate, sein, colon, poumon) Pour d’autres organes, cette évolution est rare voire exceptionnelle (petit intestin, muscle, os, cœur)
        <br/><br/>
        Le gastroentérologue est le médecin responsable de la détection des cancers de l’œsophage, de l’estomac, du foie, des voies biliaires, du pancréas, du petit intestin (intestin grêle), du gros intestin (colon et rectum) et du canal anal.  
        <br/><br/>
        Les spécialistes s’occupant du traitement des maladies cancéreuses à St Elisabeth sont les oncolgues : le Dr Gauthier Demolin et le Dr Geoffrey Matus.
    
        <h2>
        Les facteurs de risque
        </h2>
        Différents facteurs influencent l’apparition d’une maladie cancéreuse : 
        <ul style={{listStyleType: "circle"}}>
        <li><b>l’âge :</b> le cancer est plus fréquent chez les gens de plus de 50 ans et le risque augmentent avec les années;</li>
        <li><b>l’hérédité:</b> certains gènes transmis de génération en génération prédisposent à l’apparition de cancer chez les membres d’une même famille par exemple dans le sein ou le colon;</li>
        <li><b>les lésions précancéreuses :</b> dans certains organes comme le colon, la peau ou la vessie, l’apparition d’un cancer se fait au départ de petites lésions précancéreuses souvent présentes des années avant le cancer. Ces lésions ne font pas mal et peuvent rester inconnues durant des années, parfois jusqu’à leur transformation tumorale.  C’est le cas par exemple des grains de beauté sur la peau, des polypes du colon ou de certains kystes du pancréas. Ces lésions précancéreuses peuvent régulièrement être détectées par des examens de dépistage;</li>
        <li><b>le tabac :</b> facteur de risque principal du cancer du poumon. Associé à l’alcool, il favorise l’apparition de maladie de la bouche, de la gorge et de l’œsophage. L’alcool et le tabac prédispose également à une maladie du pancréas (la pancréatite chronique) pouvant dégénérer en cancer;</li>
        <li><b>le mode de vie :</b> la qualité des aliments que nous mangeons (les graisses animales trop cuites par exemple), la pollution de l’air, certains produits chimiques à usage professionnel que nous avalons ou respirons, ont un impact sur le développement de cancer;</li>
        <li><b>les irradiations (radioactivités) :</b> l’exposition chronique à des rayons ionisants favorise également l’apparition de cancer. La source de ses rayons peut être naturelle (certaines roches), accidentelle (accident nucléaire), professionnelle (usines) et parfois médicale (l’excès important d’examens radiologiques et la radiothérapie);</li>
        <li><b>les médicaments :</b> certains médicaments utilisés pour guérir des maladies graves peuvent exceptionnellement être responsables de l’apparition de cancer secondaire.</li>
        </ul>
    </div>,
    "pathologies.alimentary_canal.title": "Tube digestif",
    "pathologies.barrett.title": "Barrett",
    "pathologies.barrett.content": <div>
        Il s’agit de la transformation de la muqueuse oesophagienne en muqueuse gastrique, plus costaude pour résister aux aggressions des sécrétions gastriques qui participent au reflux.
        <br/><br/>
        Ce phénomène se produit au fil du temps lorsqu’un reflux gastro-oesophagien anormal persiste plusieurs années .
        <br/><br/>
        Le dépistage du Barrett est important chez les patients à risque ; les plaintes de reflux ne doivent pas être minimisées et méritent au moins une consultation spécialisée.
        <br/><br/>
        Le traitement de l’œsophage de Barrett nécessite une prise en charge globale du patient tant sur le plan médicamenteux que nutritionnel ; cette approche doit être abordée avec votre médecin traitant et le spécialiste gastro-entérologue.
    </div>,
    "pathologies.reflux.title": "Reflux gastro-œsophagien",
    "pathologies.reflux.content": <div>
        Il s’agit, par définition, de la remontée des sécrétions de l’estomac vers l’œsophage : du liquide gastrique et/ou biliaire associé reflue de la cavité gastrique vers le bas du conduit oesophagien.
        <br/><br/>
        Ce phénomène peut être considéré comme normal c’est-à-dire, physiologique, dans certaines circonstances, notamment après un repas copieux. Ce dernier peut occasionner des remontées acides, aigres, le plus souvent transitoires, mais gênantes, ressenties comme une gêne dans la poitrine ou un goût amer en bouche.
        <br/><br/>
        Un reflux gastro-oesophagien est considéré comme anormal lorsqu’il altère la qualité de vie ; des régurgitations fréquentes ou intenses peuvent perturber la digestion au quotidien, en période diurne et/ou nocturne.
        <br/><br/>
        Les symptômes peuvent être variés et toucher la sphère digestive, ORL, cardiaque, respiratoire sous forme notamment,de palpitations, de brûlant ; ceux-ci doivent être évalués en consultation de gastro-entérologie.
        <br/><br/>
        Lors de la visite chez le spécialiste, le médecin vous proposera la mise au point la plus adaptée et vous conseillera dans le cadre du traitement, tant sur le plan médicamenteux que nutritionnel.
    </div>,
    "pathologies.colorectal_cancer.title": "Cancer colo-rectal (CCR)",
    "pathologies.colorectal_cancer.content": <div>
        Le cancer colo-rectal (CCR) est un problème majeur de santé publique. Cette maladie est particulièrement fréquente chez les personnes de plus de 50 ans.  Il s’agit du 3ème cancer le plus fréquent chez l’homme après celui de la prostate et celui du poumon et le deuxième chez la femme après celui du sein.  
        <br/><br/>
        On rapporte +\- 5000 nouveaux cas de CCR par an en Belgique.
        <br/><br/>
        Découvert à un stade précoce, le CCR est curable dans 90% des cas. S’il est découvert tardivement, à un stade métastatique, le pronostic est beaucoup plus réservé.  Le traitement d’un cancer colo-rectal dépend du stade de la maladie à son diagnostic et peut associer une chirurgie, de la chimiothérapie et de la radiothérapie.  
        <br/><br/>
        La majorité des cancers colo-rectaux se développent à partir de petites lésions précancéreuses, appelées polypes ou adénomes, présentes des années avant l’apparition du cancer.  
        <br/><br/>
        Le patient porteur de polype ne présente le plus souvent aucun symptôme. La détection précoce de ces lésions précancéreuses et leur résection permet de prévenir l’apparition d’un cancer.  C’est le but du dépistage du cancer colo-rectal.
        <br/><br/>

        <ArticleImage 
            src={"/images/pathologies/polype_colon.jpg"} 
            caption={<div>
                Polype du colon<br/>
                Source: <a href="https://www.gastrocochin.com/endoscopie-coloscopie">https://www.gastrocochin.com/endoscopie-coloscopie</a>
            </div>}/>

        <ArticleImage 
            src={"/images/pathologies/cancer_colon.jpg"} 
            caption={<div>
                Cancer du colon<br/>
                Source: <a href="https://www.researchgate.net">https://www.researchgate.net</a>
            </div>}/>

        <h2>
        Comment dépister un cancer colo-rectal ?
        </h2>
        Le dépistage du cancer colo-rectal s’adresse au patient <b>asymptomatique</b> de plus de 50 ans.  Il consiste en la réalisation d’un échantillon de selles par le patient à son domicile.  Cet échantillon sera ensuite analysé en laboratoire pour rechercher la présence de traces microscopiques de sang.  La présence de sang dans les selles peut être associée à la présence de polypes voire d’un cancer.  
        Ce test est facile, rapide et bon marché et peut être réalisé chez votre médecin généraliste.  Un test de dépistage positif chez un patient de plus de 50 ans impose une consultation chez le gastroentérologue et la réalisation d’une coloscopie
        <br/><br/>
        <em>
        En cas d’antécédent personnel ou familial de cancer colo-rectal ou de symptômes suspects, le test de dépistage par analyse de selles n’est pas indiqué et le patient devra d’emblée consulter un gastroentérologue.
        </em>
        <br/><br/>
        <h2>
        Quels sont les symptômes pouvant suggérer la présence d’un cancer colo-rectal ou d’un polype de taille significative ? 
        </h2>
        En cas de présence d’un CCR, le patient peut présenter différents symptômes : 
        <ul style={{listStyleType: "circle"}}>
        <li>des douleurs abdominales,</li>
        <li>une perte de poids,</li>
        <li>une perte d’appétit,</li>
        <li>une fatigue inexpliquée,</li>
        <li>des pertes de sang au niveau des selles,</li>
        <li>une modification du transit : apparition d’une constipation ou de selles diarrhéique sans explication,</li>
        <li>des urgences pour aller à selles,</li>
        <li>de faux besoins d’aller à selles,</li>
        <li>la présence d’une anémie et d’un manque de fer lors d’une analyse de sang.</li>
        </ul>
        <br/><br/>
        En fonction de son âge, de ses antécédents personnels et familiaux et de la date des derniers examens coliques réalisés, un patient présentant ces symptômes se verra proposer une coloscopie.
    </div>,
    "pathologies.crohn.title": "Maladie de Crohn",
    "pathologies.crohn.content": <div>
        La maladie de Crohn est une inflammation chronique du système digestif, qui évolue par poussées (ou crises) et phases de rémission. Elle se caractérise principalement par des  douleurs abdominales et de diarrhée, durant quelques semaines à quelques mois. Fatigue, perte de poids et même dénutrition peuvent survenir si aucun traitement n’est entrepris. Dans certains cas, des symptômes non digestifs, qui touchent la peau, les articulations, le foie ou les yeux peuvent être associés à la maladie.
        <br/><br/>
        L'inflammation dans cette maladie peut toucher n’importe quel segment du tube digestif, de la bouche à l’anus. Mais le plus souvent, elle s’installe à la jonction de l’intestin grêle et du côlon (gros intestin).
        <br/><br/>
        Les <b>causes</b> cette maladie sont inconnues et vraisemblablement multiples, impliquant des facteurs génétiques, auto-immuns et environnementaux.

        <h3>
        Facteurs génétiques
        </h3>
        Certains gènes augmentent le risque de développer la maladie mais d’autres facteurs sont nécessaires pour que la maladie survienne. Comme dans bien d’autres maladies, il semble qu’une prédisposition génétique combinée à des facteurs de l’environnement ou du mode de vie déclenche la maladie.
        
        <h3>
        Facteurs auto-immuns
        </h3>
        La maladie de Crohn a des caractéristiques de maladie auto-immune. Des scientifiques pensent que l’inflammation du tube digestif serait liée à une réaction immunitaire excessive de l’organisme contre des virus ou des bactéries présents dans l’intestin.

        <h3>
        Facteurs environnementaux
        </h3>
        La maladie de Crohn est plus fréquente dans les pays industrialisés. Cela fait penser que des facteurs environnementaux, probablement liés au mode de vie occidental, pourraient influencer l’apparition de la maladie. Le tabac majore le risque de poussées de la maladie. Le mode de vie sédentaire pourrait aussi influencer.
        <br/><br/>
        Il est possible (pas de preuve absolue) que l’alimentation trop riche en viande et en sucre augmente le risque. L'état psychique pourrait également avoir une influence sur la survenue de poussées.
        <br/><br/>
        Le déséquilibre de la flore intestinale (c’est-à-dire des bactéries naturellement présentes dans le tube digestif) pourrait aussi être en cause de la maladie.
    
        <h2>
        Evolution
        </h2>
        Il s’agit d’une maladie chronique qui est présente toute la vie. Le plus souvent, la maladie de Crohn évolue par poussées entrecoupées de périodes de rémission de durée très variable. Les récidives (ou crises) se succèdent de façon assez imprévisible et sont d’intensité variable.
        <br/><br/>
        Certaines maladies de Crohn sont relativement légères et avec des crises fort espacées. D'autres sont malheureusement plus sévères avec des crises plus fréquentes. Il existe donc plusieurs types possibles de cette maladie.
        
        <h2>
        Prise en charge
        </h2>
        Un suivi médical optimal est indispensable dans cette maladie et de nombreux traitements sont possibles. Notre service propose une prise en charge personnalisée: la relation de confiance médecin-patient est plus que jamais indispensable dans cette prise en charge et permet un traitement vraiment adapté à la personne porteuse de cette maladie.
        <br/><br/>
        Plusieurs membres de l'équipe sont spécialisés dans cette maladie et partagent leur conseils et leur expérience. Par ailleurs, une collaboration avec d'autres spécialités est naturellement mise en place selon les besoins du patient (chirurgie, radiologie, diététique, anatomopathologie, tabacologie, psychologie...)
        <br/><br/>
        Notre service entretien des liens privilégiés avec des centres de référence internationaux reconnus, ce qui permet de faire bénéficier le patient si nécessaire de nouveaux traitements à l'étude en collaboration avec ces centres réputés.
        <br/><br/>
        Afin d'améliorer sa propre prise en charge de ce type de maladie chronique, il est parfois utile de s'affilier à une association de malades.
        <br/><br/>
        Lien utile: <a href="http://www.mici.be/">http://www.mici.be/</a>
    </div>,
    "pathologies.ulcerative_colitis.title": "Rectocolite hémorragique",
    "pathologies.ulcerative_colitis.content": <div>
        La rectocolite hémorragique est une maladie inflammatoire chronique intestinale (MICI) du côlon et du rectum. Au contraire de la maladie de Crohn qui peut atteindre n'importe quel segment du tube digestif, la rectocolite ulcèro-hémorragique est limitée au colon et rectum.
        <br/><br/>
        Le rectum est toujours atteint et selon les formes de RCUH, une longueur variable de colon est touchée par la maladie. Dans certains cas, des symptômes non digestifs, qui touchent la peau, les articulations, le foie ou les yeux peuvent être associés à la maladie.
        <br/><br/><br/>
        Les <b>causes</b> cette maladie  sont inconnues et vraisemblablement multiples, impliquant des facteurs génétiques, auto-immuns et environnementaux.
        <br/><br/>
        C’est une maladie inflammatoire, de mécanisme complexe. Ce n’est pas une maladie contagieuse et elle n’est pas due directement à une infection par un germe ou un virus. Le déséquilibre de la flore intestinale (c’est-à-dire des bactéries naturellement présentes dans le tube digestif) pourrait aussi être en cause de la maladie.
        <br/><br/>
        Le stress et des intolérances alimentaires peuvent déclencher les symptômes chez certaines personnes, mais ces facteurs ne seraient pas à l’origine de la maladie.
        
        <h2>Evolution</h2>
        C’est une maladie chronique qui évolue par poussées séparées par des périodes de rémission plus ou moins longues.
        <br/><br/>
        Les formes limitées au rectum sont moins graves que les formes comprenant tout le colon.
        <br/><br/>
        Il existe une forme très grave avec risque de perforation du colon, pouvant nécessiter une chirurgie urgente.

        <h2>Prise en charge</h2>
        Un suivi médical optimal est indispensable dans cette maladie et de nombreux traitements sont possibles. Notre service propose une prise en charge personnalisée : la relation de confiance médecin-patient est plus que jamais indispensable dans cette prise en charge et permet un traitement vraiment adapté à la personne porteuse de cette maladie.
        <br/><br/>
        Plusieurs membres de l'équipe sont spécialisés dans cette maladie et partagent leurs conseils et leur expérience. Par ailleurs, une collaboration avec d'autres spécialités est naturellement mise en place selon les besoins du patient (chirurgie, radiologie, diététique, anatomopathologie,...)
        <br/><br/>
        Si nécessaire, notre service entretien des liens privilégiés avec des centres de référence internationnaux reconnus, ce qui permet de faire bénéficier le patient de nouveaux traitements à l'étude en collaboration avec ces centres réputés.
        <br/><br/>
        Afin d'améliorer sa propre prise en charge de ce type de maladie chronique, il est parfois utile de s'affilier à une association de malades.
        <br/><br/>
        Lien utile: <a href="http://www.mici.be/">http://www.mici.be/</a>
    </div>,
    "pathologies.liver.title": "Foie",
    "pathologies.viral_hepatitis.title": "Hépatite virale",
    "pathologies.viral_hepatitis.content": <div>
        Le terme hépatite désigne tout processus inflammatoire du foie. Le terme hépatite chronique désigne une inflammation évolutive du foie qui dure depuis plus de six mois. En dessous de ces six mois, on considère que le processus est aigu.
        Une hépatite virale est une inflammation du foie causée par une infection par un virus. Il existe différents types de virus qui peuvent enflammer le foie : 
        <ol type='a'>
            <li>des virus non-spécifiques du foie (ex : CMV, EBV, …) et </li>
            <li>des virus spécifiques du foie (virus des hépatites A,B,C,D,E).</li>
        </ol>

        <h2>
        Hépatite A
        </h2>
        <h3>
        Résumé
        </h3>
        L’hépatite A est une maladie hépatique virale dont l’évolution est le plus souvent bénigne mais peut -être grave dans certains cas.
        Le virus de l’hépatite A se transmet par l’ingestion d’eau ou d’aliments contaminés ou par contact direct avec une personne infectée.
        Presque tout le monde guérit complètement de l’hépatite A en étant immunisé pour tout le reste de la vie. Néanmoins, on enregistre une très petite proportion de sujets infectés qui meurent d’hépatite fulminante.
        Le virus se propage essentiellement lorsqu’une personne non infectée (ou non vaccinée) ingère de l’eau ou des aliments contaminés par les matières fécales d’un sujet infecté. Dans le cadre familial, cela peut se produire lorsqu’un sujet infecté prépare avec des mains sales la nourriture pour les membres de sa famille. Le virus peut également se transmettre par contact physique étroit avec une personne infectée, mais il ne se propage pas à l’occasion des contacts ordinaires entre personnes.
        Il existe un vaccin sûr et efficace pour la prévention de l’hépatite A.
        <br/><br/>
        Contrairement à l’hépatite B et à l’hépatite C, l’hépatite A n’entraîne pas de maladie hépatique chronique.

        <h3>
        Symptômes
        </h3>
        La période d’incubation de l’hépatite A est généralement de 14 à 28 jours.
        Les symptômes de l’hépatite A peuvent être bénins ou graves: on peut observer une fièvre, un mauvais état général, une perte d’appétit, des diarrhées, des nausées, une gêne abdominale, des urines foncées et un ictère (coloration jaune de la peau et du blanc des yeux). Les personnes infectées ne présentent pas toutes l’ensemble de ces symptômes.
        Les adultes présentent plus souvent que les enfants des symptômes de la maladie

        <h3>
        Quelles sont les personnes à risque?
        </h3>
        Toute personne n’ayant jamais été infectée ni vaccinée peut contracter une hépatite A. 

        <h3>
        Traitement
        </h3>
        Il n’y a pas de traitement spécifique contre l’hépatite A. La guérison des symptômes consécutifs à l’infection peut être lente et prendre plusieurs semaines ou plusieurs mois. Le plus important est d’éviter les médicaments inutiles. Il ne faut pas prescrire d’acétaminophène/paracétamol ou d’antiémétiques (médicaments contre les vomissements).
        Il n’est pas utile d’hospitaliser le patient s’il ne présente pas d’insuffisance hépatite aiguë. Le traitement vise principalement à maintenir un certain confort et un bon équilibre nutritionnel, notamment à remplacer les pertes liquidiennes dues aux vomissements et à la diarrhée.

        <h3>
        Prévention
        </h3>
        On peut limiter la propagation de l’hépatite A par les moyens suivants:
        une bonne hygiène personnelle, notamment le fait de se laver régulièrement les mains avec de l’eau propre.


        <h3>
        Vaccination
        </h3>
        Plusieurs vaccins injectables inactivés contre l’hépatite A sont disponibles sur le marché international. Tous sont comparables en termes de protection contre le virus et d’effets secondaires. Aucun vaccin n’est homologué pour l’enfant âgé de moins d’un an. 
        Dans le monde, des millions de personnes ont reçu le vaccin injectable inactivé contre l’hépatite A sans présenter d’événements indésirables graves.

        Certains pays préconisent aussi de vacciner les personnes exposées à un risque accru d’hépatite A, notamment:
        <ul style={{listStyleType: "circle"}}>
        <li>les voyageurs se rendant dans des pays où le virus est endémique;</li>
        <li>les hommes ayant des rapports sexuels avec d’autres hommes;</li>
        <li>les consommateurs de drogues récréatives.</li>
        <li>les personnes souffrant de maladies hépatiques chroniques (en raison du risque accru de complications graves si elles contractent le VHA).</li>
        </ul>


        <h2>
        Hépatite B
        </h2>

        <h3>
        Résumé
        </h3>
        L’hépatite B est une infection virale qui s’attaque au foie. Elle peut être à l’origine de maladies aiguës ou chroniques. La forme chronique expose les malades à un risque important de décès par cirrhose et cancer hépatique.
        Le virus se transmet par contact avec le sang ou d’autres fluides corporels provenant d’une personne infectée.
        L’hépatite B est un risque professionnel important pour le personnel de santé.
        Il est possible de prévenir l’hépatite B avec le vaccin sûr et efficace dont on dispose actuellement. Ce vaccin est efficace à 95% dans la prévention de l’infection et du développement d’une hépatite chronique et d’un cancer du foie dû à l’hépatite B.

        <h3>
        Transmission
        </h3>
        Le virus de l’hépatite B peut survivre en dehors du corps pendant au moins 7 jours. Au cours de ce laps de temps, le virus est encore susceptible de provoquer une infection s’il pénètre dans l’organisme d’une personne non protégée par la vaccination. La période d’incubation de l’hépatite B est de 75 jours en moyenne, mais peut varier de 30 à 180 jours. Le virus est détectable 30 à 60 jours après l’infection et peut persister dans l’organisme pour donner une hépatite B chronique.

        Dans les zones de forte endémicité (Afrique, Asie), les modes de propagation les plus courants de l’hépatite B sont la transmission périnatale (de la mère à l’enfant) et horizontale (exposition à du sang infecté), notamment entre un enfant infecté et un enfant non infecté pendant les 5 premières années de vie. L’apparition d’une infection chronique est très fréquente pour les nourrissons infectés par leur mère ou avant l’âge de 5 ans.
        L’hépatite B se propage aussi par exposition percutanée ou à travers les muqueuses, et par le biais de la salive, des écoulements menstruels ou des sécrétions vaginales et séminales. Une transmission sexuelle de l’hépatite B peut aussi intervenir, en particulier chez les hommes non vaccinés ayant des rapports sexuels avec d’autres hommes et chez les personnes hétérosexuelles ayant des partenaires multiples ou des contacts avec des travailleurs du sexe.
        À l’âge adulte, l’infection débouche sur une hépatite chronique dans moins de 5% des cas. Le virus peut aussi se transmettre lors de la réutilisation d’aiguilles ou de seringues en milieu de soins ou parmi des personnes consommatrices de drogues par injection. En outre, l’infection peut se produire pendant des actes médicaux, chirurgicaux ou dentaires, des tatouages ou lors de l’utilisation de rasoirs ou d’objets similaires contaminés par du sang infecté.

        <h3>
        Symptômes
        </h3>
        Dans la plupart des cas, aucun symptôme ne se manifeste pendant la phase aiguë de l’infection. Cependant, certaines personnes présentent une maladie aiguë avec des symptômes qui durent plusieurs semaines, dont un jaunissement de la peau et des yeux (ictère), une coloration sombre des urines, une fatigue extrême, des nausées, des vomissements et des douleurs abdominales.
        Parmi les personnes atteintes d’hépatite aiguë, un petit nombre présenteront une insuffisance hépatique aiguë pouvant conduire au décès.
        Chez certaines personnes, le virus de l’hépatite B peut occasionner une infection chronique du foie susceptible d’évoluer ultérieurement en cirrhose hépatique ou en cancer du foie.

        <h3>
        Co-infection VHB-VIH
        </h3>
        Environ 1% des personnes infectées par le VHB (2,7 millions de personnes) sont également porteuses du VIH. Inversement, la prévalence mondiale de l’infection à VHB chez les sujets infectés par le VIH est de 7,4%. 

        <h3>
        Diagnostic
        </h3>
        Il n’est pas possible de distinguer l’hépatite B des hépatites provoquées par d’autres agents viraux sur le plan clinique, aussi est il indispensable de confirmer le diagnostic en laboratoire. Plusieurs tests sanguins sont disponibles pour diagnostiquer et surveiller les personnes atteintes d’une hépatite B. Ils peuvent aussi servir à différencier les infections aiguës des infections chroniques.

        <h3>
        Traitement
        </h3>
        Il n’existe pas de traitement spécifique contre l’hépatite B aiguë. Les soins visent à préserver le confort du malade et l’équilibre nutritionnel, avec notamment une substitution liquidienne en cas de vomissements et de diarrhée.
        L’hépatite B chronique peut être traitée par des médicaments, et notamment par des agents antiviraux oraux. Un tel traitement peut ralentir la progression de la cirrhose, réduire l’incidence des cancers du foie et améliorer la survie à long terme.
        <br/><br/>
        Parmi les complications des infections à VHB sur le long terme, la cirrhose et le carcinome hépatocellulaire sont responsables d’une charge de morbidité importante. 

        <h3>
        Prévention
        </h3>
        Le vaccin contre l’hépatite B est la clé de voute de la prévention de cette maladie. L’OMS recommande d’administrer ce vaccin à tous les nourrissons dès que possible après leur naissance. On peut attribuer la faible incidence de l’infection chronique à VHB chez les enfants de moins de 5 ans à l’usage généralisé du vaccin contre l’hépatite B.
        Dans le monde, on estimait à 1,3% en 2015 la prévalence de cette infection dans cette tranche d’âge, contre 4,7% avant l’avènement de la vaccination. La dose à la naissance doit être suivie de 2 à 3 autres doses pour achever la première série vaccinale. 
        <br/><br/>
        Dans les pays où l’endémicité est faible ou moyenne, il convient de vacciner tous les enfants et les adolescents de moins de 18 ans non encore vaccinés. Dans de tels contextes, il est possible que les membres des groupes à haut risque soient plus nombreux à contracter l’infection et ces groupes devront donc aussi être vaccinés. Il s’agit en l’occurrence:
        <ul style={{listStyleType: "circle"}}>
        <li>des personnes qui ont besoin fréquemment de sang ou de produits sanguins, sous dialyse et des bénéficiaires de transplantations d’organes solides;</li>
        <li>des détenus;</li>
        <li>des consommateurs de drogues injectable;</li>
        <li>des personnes ayant des rapports sexuels avec des individus affectés de façon chronique par le VHB;</li>
        <li>des personnes ayant des partenaires sexuels multiples:</li>
        <li>du personnel de santé et les personnes risquant d’être exposées à du sang et à des produits sanguins dans le cadre de leur activité professionnelle; et</li>
        <li>des voyageurs qui n’ont pas été complètement vaccinés devraient se voir proposer le vaccin avant de se rendre dans une zone d’endémie.</li>
        </ul>
        Le vaccin a fait la preuve historiquement d’une innocuité et d’une efficacité remarquables. Depuis 1982, plus d’un milliard de doses de vaccin contre l’hépatite B ont été administrées dans le monde. 

        <h2>
        Hépatite C 
        </h2>
        L’hépatite C est une maladie du foie causée par un virus. Le virus de l’hépatite C peut entraîner à la fois une infection hépatique aiguë et chronique, dont la gravité est variable, pouvant aller d’une forme bénigne qui dure quelques semaines à une maladie grave qui s’installe à vie.
        Le virus de l’hépatite C est transmis par le sang et les modes d’infection les plus fréquents résultent de l’exposition à de petites quantités de sang, se produisant lors de la consommation de drogues injectables, des injections à risque, de soins à risque et de la transfusion de sang ou de produits dérivés pour lesquels il n’y a pas eu de dépistage.
        À l’échelle mondiale, environ 71 millions d’individus sont porteurs chroniques de l’hépatite C.
        Pour un nombre important des personnes atteintes par la forme chronique de la maladie, l’infection évolue vers la cirrhose ou le cancer du foie.
        Environ 399 000 personnes meurent chaque année de l’hépatite C, la plupart du temps par cirrhose ou carcinome hépatocellulaire.
        Les médicaments antiviraux permettent de guérir plus de 95% des personnes infectées par le virus de l’hépatite C, réduisant ainsi le risque de décès par cancer hépatique ou par cirrhose.
        En Belgique, 50% des personnes infectées s’ignorent. Il est donc primordial de dépister les personnes à risques :
        Les traitements actuels sont des traitements courts (8 à 12 semaines), avec très peu d’effets secondaires (maux de tête, fatigue, insomnies) et très efficaces (95 à 100% d’érédication virale).
        Actuellement, il n’existe pas de vaccin contre l’hépatite C.

        <h3>
        Symptômes
        </h3>
        Comme pour les autres hépatites, les symptômes sont aspécifiques. En cas d’infection chronique, le symptôme le plus fréquent est la fatigue.

        <h3>
        Transmission
        </h3>
        Elle se fait par le sang.


        <h3>
        Diagnostique
        </h3>
        Le diagnostique se fait par une prise de sang :
        <ol>
        <li>Anticorps (dépistage)</li>
        <li>Recherche de l’ARN viral par PCR (diagnostique)</li>
        </ol>

        <h3>
        Vaccination
        </h3>
        Il n’y a pas de vaccination pour ce virus.

        <h3>
        Traitement
        </h3>
        Les traitements sont très effiaces (plus de 95% d’efficacité avec une éradication du virus). Ils sont cours ( 8 à 12 semaines). Ils n’ont que peu d’effets secondaires (fatigue, maux de tête, insomnie).

        <h3>
        Dépistage
        </h3>
        50% des patients infectés s’ignorent. Il est primordial d’optimiser le dépistage dans les groupes à risque

        <h3>
        Groupes à risque
        </h3>
        <ul style={{listStyleType: "circle"}}>
            <li>les consommateurs de drogues par injection;</li>
            <li>les consommateurs de drogues par voie nasale;</li>
            <li>les personnes ayant reçu des produits sanguins infectés ou ayant fait l’objet d’examens invasifs dans des établissements de soins où les pratiques de lutte contre les infections sont insuffisantes ou avant 1970;</li>
            <li>des enfants nés de mères infectées par le VHC;</li>
            <li>des personnes dont les partenaires sexuels sont infectés par le VHC;</li>
            <li>des personnes atteintes de l’infection à VIH;</li>
            <li>des prisonniers ou des personnes qui ont été incarcérées dans le passé; et</li>
            <li>des personnes qui sont tatouées ou portent des piercings.</li>
        </ul>
        

        <h3>
        Conseils en cas d’infection pour éviter la contamination
        </h3>
        Ne pas partager ciseaux à ongles, coupe ongles, brosse à dents, brosse à cheveux, peigne, rasoirs, etc.

        <h2>
        Hépatite Delta
        </h2>
        N’existe que si le patient est infecté par le virus de l’hépatite B.


        <h2>
        Hépatite E
        </h2>
        L’hépatite E est une maladie du foie provoquée par l’infection par un virus, le virus de l’hépatite E (VHE).
        Le virus de l’hépatite E se transmet par voie fécale-orale, principalement à travers de l’eau contaminée ou après avoir mangé de la viande (porc, sanglier, biche) mal cuite ou des crustacés.
        L’hépatite E sévit partout dans le monde, mais sa prévalence est la plus élevée en Asie de l’Est et du Sud.
        L’infection est le plus souvent aiguë et sans conséquence mais elle peut parfois conduire à une hépatite fulminante. En cas d’immunosuppression, l’infection peut devenir chronique et nécessité un traitement médicamenteux par Ribavirine.
        Dans certains cas, il peut y avoir des manifestations extra-hépatiques (ex : neurologique).
    </div>,
    "pathologies.hepatitis_misc.title": "Hépatites diverses",
    "pathologies.hepatitis_misc.content": <div>
        Il existe d’autres causes d’hépatites comme par exemple des hépatites liées à une toxicité médicamenteuse.
    </div>,
    "pathologies.hepatitis_alc.title": "Hépatite alcoolique",
    "pathologies.hepatitis_alc.content": <div>
        La prise chronique d’alcool peut amener à une hépatite (inflammation du foie) se traduisant par une anomalie des tests hépatiques. Elle peut conduire à une fibrose du foie qui si elle est sévère correspond à une cirrhose.
    </div>,
    "pathologies.nafld.title": "Hépatopathie stéatosique dysmétabolique (NAFLD)",
    "pathologies.nafld.content": <div>
        L’hépatopathie stéatosique métabolique (NAFLD - Non Alcoholic Fatty Liver Disease) correspond à l’accumulation excessive de graisse dans les cellules du foie non liée à une consommation excessive d’alcool. 
        <br/><br/>
        Cet excès de graisse dans le foie peut conduire à une inflammation (stéato-hépatite non alcoolique ou NASH) et évoluer vers une fibrose du foie (« cicatrices »). 
        <br/><br/>
        Il s’agit de la première cause d’hépatopathie chronique touchant 20 à 30% de la population mondiale parallèlement à l’épidémie d’obésité et de diabète. 

        <h2>
            Facteurs de risque
        </h2>

        <h3>Age et sexe</h3>

        La prévalence et la sévérité de la NAFLD sont plus importantes chez l’homme que la femme et augmente avec l’âge.

        <h3>Ethnie</h3>


        Il existe des facteurs socio-économiques, culturels et génétiques qui permettent d’expliquer une prévalence différente en fonction de l’ethnie 

        <h3>Facteurs de risque métaboliques</h3>


        Il s’agit du principal facteur de risque de la maladie. Le surpoids/obésité, le diabète, l’hypertension artérielle, le syndrome métabolique (association de plusieurs anomalies métaboliques), les dyslipidémies (hypercholestérolémie ou hypertriglycéridémie) sont des facteurs de risque de la NAFLD (et de sa sévérité)

        <h3>Mode de vie</h3>


        Les excès caloriques, le type de régime (excès de graisses, de sodas, de jus de fruits), l’absence d’activité physique et la sédentarité sont des facteurs de risque de NAFLD. 

        <h3>Génétique</h3>


        Certains facteurs génétiques modifient le risque de présenter la maladie


        <h2>Diagnostic</h2>

        Il n’y a pas de symptômes spécifiques de la maladie. Le diagnostic est généralement évoqué une base d’une prise de sang (anomalie des tests du foie) et/ou d’une échographie abdominale (foie trop « brillant » évoquant un excès de graisse). 

        En cas de suspicion de NAFLD, un bilan complet doit être réalisé: biologie sanguine complète (notamment pour exclure d’autres maladies du foie, rechercher un diabète, un excès de cholestérol, …), échographie du foie et mesure non invasive de la fibrose (dans une prise de sang ou en réalisant une élastométrie).


        <h2>Évolution et complications</h2>

        L’évolution peut se faire vers une inflammatoire du foie (stéato-hépatite non alcoolique) et vers une fibrose (« cicatrice ») du foie pouvant parfois mener à une cirrhose. 

        Hormis les complications au niveau du foie, cette maladie augmente le risque de maladie cardio-vasculaire et de certains cancers. 

        <h2>Prise en charge</h2>

        La prise en charge consiste en le contrôle des facteurs de risque métaboliques:
        <ul style={{listStyleType: "circle"}}>
            <li>Perte de poids en cas de surpoids/obésité, généralement de 5 à 10% du poids corporel</li>
            <li>Contrôle de l’hypertension artérielle</li>
            <li>Correction de la dyslipidémie (cholestérol - triglycérides)</li>
            <li>Limiter la consommation d’alcool</li>
            <li>Contrôle du diabète</li>
            <li>Activité physique régulière</li>
        </ul>

        Certains traitements pour éviter une évolution vers la fibrose sont en cours de développement. 

        Un suivi est indiqué et adapté à chaque patient, en fonction de son profil et de son stade de fibrose.
    </div>,
    "pathologies.rare_diseases.title": "Maladies rares : CBP, CSP, Hépatites auto-immunes, Hémochromatose",
    "pathologies.rare_diseases.content": <div>
        Il existe d’autres maladies rares du foie, liées soit à de l’auto-immunité Cholangite biliaire primitive, Cholangite Sclérosante primitive, hépatite auto-immune), soit à des surcharges (ex : surcharge en fer).
    </div>,
    "pathologies.cirrhosis.title": "Cirrhose",
    "pathologies.cirrhosis.content": <div>
        La cirrhose correspond à une fibrose sévère du foie. La fibrose est une cicatrice qui peut être modérée ou sévère (cirrhose). Cette cicatrice peut empêcher le fonctionnement normal du foie.
        <br/><br/>
        Cette cicatrice (fibrose) est le plus souvent réactionnelle à une inflammation du foie qui peut être d’origine diverse (alcool, stéatose, hépatites virales, hépatites auto-immune, ...).
        <br/><br/>
        Les symptômes sont peu spécifiques en début de maladie (fatigue). En cas de cirrhose plus avancée et symptomatique (on parle alors de cirrhose dite « décompensée »), les symptômes sont plus spécifiques. Il peut s’agir de confusion, de jaunisse, d’eau dans les jambes ou dans le ventre. Dans certains cas, des hémorragies digestives peuvent survenir en cas de varices (dilatation de veines liées à une pression importante secondaire à une résistance du passage du sang dans le foie). 
        <br/><br/>
        Le diagnostique de la cirrhose peut être suspecté à l’imagerie et à la prise de sang. Néanmoins, un examen non invasif de type Fibroscan ou Elastométrie permet d’évaluer le degré de fibrose en mesurant la « dureté » du foie par imagerie. Dans certains cas, une biopsie hépatique peut être proposée.
        <br/><br/>
        Le traitement repose essentiellement sur le traitement de la cause (ex : éradication virus, arrêt alcool, …) ou sur le traitement des complications.
        <br/><br/>
        Une surveillance par échographie semestrielle est indispensable. En effet, la cirrhose augmente le risque de tumeur du foie et un dépistage semestriel permet de dépister des lésions de petites tailles pour lesquelles un traitement curatif pourra le plus souvent être proposé.
        <br/><br/>
        Un régime pauvre en sel est le plus souvent recommandé. Celui-ci doit également être hypercalorique et normoprotéiné.
    </div>,
    "pathologies.bile_ducts_and_pancreas.title": "Voies biliaires & pancréas",
    "pathologies.biliopancreatic_pathology.title": "Pathologie bilio-pancréatique",
    "pathologies.biliopancreatic_pathology.content": <div>
        Les voies biliaires sont des canaux internes qui drainent la bile produite par le foie vers le tube digestif au niveau du duodénum.
        La bile, de couleur jaune/verte est un enzyme de digestion, produite de manière continue par le foie.  En dehors d’un repas, la bile est stockée dans la vésicule, petit poche réservoir accrochée à la voie biliaire principale (le choledoque).  Lors d’un repas, la vésicule se contracte et la bile est déversée dans le duodénum au contact des aliments pour commencer la digestion.
        <br/><br/>
        Le pancréas est un organe situé derrière l’estomac, qui est impliqué dans la digestion mais également dans la régulation du niveau de sucre dans le sang.
        Les enzymes de digestion du pancréas sont déversés dans le tube digestif par le canal de Wirsung.
        La voie biliaire principale venant du foie et du canal de Wirsung du pancréas s’abouche au duodénum  par un orifice commun: l’ampoule de Vater (voir illustration ci-dessous).

        <ArticleImage src={"/images/pathologies/bilio-pancreatic_duct_fr.png"} caption={"Source : WIKIPEDIA"} />

        <br/><br/>
        Les maladies des voies biliaires et du pancréas surviennent le plus souvent suite à une obstruction de ces canaux.
        <br/><br/>
        L’obstruction des canaux est le plus souvent liée au passage d’un calcul venant de la vésicule biliaire.  Toutefois, l’obstacle peut également être dû à une maladie cancéreuse.  Il s’agit alors d’un cancer des voies biliaires ou d’un cancer du pancréas.
        Plus rarement, des maladies inflammatoires auto-immunes ou fibrosantes peuvent obstruer les voies biliaires ou le canal pancréatique (pancréatite chronique calcifiante, pancréatite auto-immune, cholangite sclérosante, cirrhose biliaire primitive)
        <br/><br/>
        La migration d’un calcul provoque, en fonction de l’endroit où il s’arrête, des symptômes différents (Cholécystite, angiocholite, pancréatite aigüe) et peut nécessiter un traitement différent.
        Signalons ici que la pancréatite aigüe et d’autres maladies du pancréas sont également souvent liées à une intoxication chronique à l’alcool.
        <br/><br/>
        Les symptômes suggérant une maladie bilio-pancréatique sont : 
        <ul>
        <li>de vives douleurs apparues brutalement au-dessus du nombril et/ou sous les cotes à droite et irradiées jusque dans le dos,</li>
        <li>des nausées et des vomissements après les repas,</li>
        <li>un manque d’appétit sévère,</li>
        <li>l’apparition d’une jaunisse (ictère),</li>
        <li>l'apparition de fièvre</li>
        <li>dans certains cas, une fatigue et un amaigrissement important.</li>
        </ul>
        <br/><br/>
        L’apparition de ces symptômes nécessite de consulter un médecin rapidement.
        <br/><br/>
        Une obstruction des voies biliaires ou du pancréas peut nécessiter un examen endoscopique en urgences ou une chirurgie si nécessaire.
    </div>,
    "pathologies.anus.title": "Anus",
    "pathologies.hemorrhoids.title": "Hémorroïdes",
    "pathologies.hemorrhoids.content": <div>
        Les hémorroïdes sont des veines dilatées au niveau de l’anus et présentes chez tout le monde.<br/><br/>
        On distingue les hémorroïdes internes, qui se trouvent à l’intérieur du canal anal des hémorroïdes externes qui peuvent apparaitre sous la peau à l’extérieur du canal anal. Les hémorroïdes internes jouent un rôle important dans la continence.<br/><br/>
        
        <ArticleImage
            src={"/images/pathologies/hemorrhoids_fr.jpg"}
            caption={<div>
                Distinction entre les hémorroïdes internes et externes
            </div>}/>
        
        Les hémorroïdes peuvent parfois gonfler, devenir gênantes et provoquer des saignements. On parle alors de maladie hémorroïdaire.<br/><br/>

        <h2>Causes de la maladie hémorroïdaire</h2>
        <ul>
            <li>Troubles du transit (diarrhée ou constipation)</li>
            <li>Grossesse</li>
            <li>Alcool, repas épicés</li>
            <li>Certains sports (musculation, vélo)</li>
        </ul>

        <h2>Traitements médicamenteux</h2>
        <ul>
            <li>Laxatifs doux comme par exemple le Macrogol pour améliorer le transit</li>
            <li>Pommades, notamment à base de cortisone pour calmer l’inflammation locale</li>
            <li>Médicaments pour améliorer le flux sanguin (médicaments veinotoniques)</li>
        </ul>

        <h2>Traitements instrumentaux</h2>
        <ul>
            <li>S’applique aux hémorroïdes internes après échec du traitement médicamenteux</li>
            <li>Application de ligatures élastiques, photocoagulation infrarouge</li>
        </ul>

        <h2>Traitements chirurgicaux</h2>
        <ul>
            <li>En cas d’échec des traitements instrumentaux ou lorsque la maladie hémorroïdaire est d’emblée trop avancée.</li>
            <li>Il existe plusieurs techniques de chirurgie : traitement par laser, hémorroïdopexie selon Longo, hémorroïdectomie radicale par la technique de Milligan Morgan, etc.</li>
        </ul>

        <h2>Thrombose hémorroïdaire</h2>
        Parfois, on peut développer une « crise douloureuse » plus aiguë avec apparition d’une « boule bleutée » à l’extérieur. La plupart du temps, il s’agit alors d’une thrombose hémorroïdaire.<br/><br/>
        Il s’agit d’un caillot dans une hémorroïde avec de l’œdème qui va se résorber naturellement en 3-4 jours. Le caillot ne peut jamais migrer et être à l’origine d’une embolie pulmonaire par exemple.<br/><br/>
        Si la douleur est trop intense, cette dernière peut être soulagée par une incision de l’hémorroïde externe, qui doit se faire aux urgences ou chez le gastro-entérologue, dans les 48 après l’apparition de la thrombose.
    </div>,
    "pathologies.anal_fissure.title": "Fissure anale",
    "pathologies.anal_fissure.content": <div>
        La fissure anale est une déchirure de la peau au niveau du canal anal et est très fréquente.<br/><br/>
        Elle provoque des douleurs, surtout lors du passage des selles et persistent plusieurs minutes ou heures après la défécation. Cette plaie occasionne fréquemment des saignements.<br/><br/>
        Les causes de la fissure anale ne sont pas clairement établies. On évoque des causes traumatiques, comme le passage d’une selle dure, une pression prolongée au niveau de l’anus et une mauvaise circulation sanguine à ce niveau.<br/><br/>
        La cicatrisation de la fissure est difficile car on observe souvent un spasme du sphincter interne du canal anal (le muscle de la continence), ce qui diminue l’apport sanguin à la plaie nécessaire pour la cicatrisation. On pense que c’est surtout ce spasme qui est à l’origine de la douleur.<br/><br/>
        Le traitement principal de la fissure anale consiste donc à appliquer une pommade permettant de relâcher le sphincter interne.
    </div>,
    "pathologies.anal_fistula.title": "Fistule anale et abcès de la marge anale",
    "pathologies.anal_fistula.content": <div>
        Un <b>abcès anal</b> (cavité remplie de pus) peut parfois se développer au niveau d’une petite glande infectée à l’intérieur de l’anus. Une communication entre la glande infectée ou l’abcès peut alors se former entre le canal et la peau péri-anale, ce qu’on appelle une <b>fistule anale</b>.<br/><br/>
        La fistule anale se manifeste le plus souvent par une suppuration.<br/><br/>
        Lorsqu’il y a un abcès anal, on ressent une douleur anale permanente, insomniante, non rythmée par les selles.<br/><br/>
        Le seul traitement efficace des fistules et de l’abcès est de type chirurgical.
    </div>,
    "pathologies.anal_warts.title": "Condylomes anaux",
    "pathologies.anal_warts.content": <div>
        Ce sont les lésions cutanées (verrues) qui se développent suite à une infection sexuellement transmissible par le virus HPV (Papillomavirus). Les condylomes sont très contagieux et la protection par les préservatifs n’est pas complètement efficace. Ils peuvent se retrouver au niveau de l’anus (au pourtour et à l’intérieur), au niveau des parties génitales et au niveau buccal.<br/><br/>
        Les condylomes peuvent disparaitre seuls en quelques semaines, mais si elles persistent il est conseillé de les traiter pour diminuer le risque de transmission et afin d’éviter une éventuelle dégénérescence en cellules pré-cancéreuses (dysplasie).<br/><br/>
        Il existe plusieurs types de traitements pour faire disparaitre les lésions (on ne fait pas disparaitre le virus): application d’un crème, la cryothérapie par azote liquide, électrocoagulation au bistouri électrique ou résection chirurgicale.<br/><br/>
        Il est important de traiter également le partenaire sexuel.<br/><br/>
        La récidive des condylomes est fréquente.
    </div>,
    "pathologies.gall_and_bile_duct_stones.title": "Calculs vésiculaires et cholédociens",
    "pathologies.gall_and_bile_duct_stones.content": <div>
        La pathologie vésiculaire lithiasique se réfère à la présence de calculs biliaires dans la vésicule biliaire. Les calculs biliaires, également connus sous le nom de lithiases biliaires, se forment lorsque certaines substances présentes dans la bile, comme le cholestérol et les sels biliaires, se cristallisent et se solidifient. Ces calculs peuvent varier en taille, de petits grains de sable à de gros cailloux.<br/><br/>
        <ArticleImage 
            src={"/images/pathologies/gallstones_fr.jpg"} 
            caption={<div>
                Calculs vésiculaires<br/>
                (calculs formés à partir de composants précipités de la bile)<br/>
                Source: <a href="https://stock.adobe.com">Adobe Stock</a>
            </div>}
            smaller={true}/>
        Les facteurs de risque de développement de calculs biliaires comprennent une alimentation riche en graisses et en cholestérol, l’obésité, une perte de poids rapide, des antécédents familiaux de calculs biliaires, certains médicaments et certaines affections médicales telles que le diabète et la cirrhose du foie.<br/><br/>
        La prise en charge de la pathologie vésiculaire lithiasique dépend de la présence ou de l’absence de symptômes.<br/><br/>
        En l’absence de symptômes, un suivi régulier en échographie peut être recommandé pour surveiller l’évolution des calculs biliaires.<br/><br/>
        Cependant, si des symptômes se manifestent, tels que des douleurs abdominales intenses (coliques hépatiques) ou des complications telles qu’une inflammation de la vésicule biliaire (cholécystite), une intervention médicale peut être nécessaire.<br/><br/>
        Le diagnostic de la pathologie vésiculaire repose sur l’anamnèse, l’examen clinique, les analyses sanguines et une imagerie abdominale comme l’échographie, l’écho-endoscopie, le scanner ou l’IRM abdominale.<br/><br/>
        Dans les cas symptomatiques, la chirurgie est généralement recommandée pour enlever les calculs biliaires. La procédure la plus connue est la cholécystectomie ouverte (devenue rare), qui nécessite une incision abdominale plus importante, et la cholécystectomie laparoscopique, une approche moins invasive qui utilise de petites incisions et une caméra pour guider le chirurgien.<br/><br/>
        <ArticleImage 
            src={"/images/pathologies/laparoscopic_cholecystectomy_fr.jpg"} 
            caption={<div>
                Cholécystectomie laparoscopique<br/>
                Source: <a href="https://stock.adobe.com">Adobe Stock</a>
            </div>}/>
        Outre la chirurgie, il existe d’autres options de traitement pour les calculs biliaires, comme la dissolution chimique des calculs à l’aide de médicaments spécifiques. Cependant, cette méthode est moins courante et n’est généralement utilisée que dans des cas spécifiques où la chirurgie présente des risques élevés pour le patient.<br/><br/>
        La pathologie lithiasique cholédocienne, également connue sous le nom de lithiase biliaire du cholédoque ou migration lithiasique cholédocienne, fait référence à la présence de calculs biliaires dans le cholédoque, le canal qui transporte la bile du foie vers l’intestin grêle. Ces calculs sont expulsés de la vésicule biliaire vers le cholédoque suite à des contractions vésiculaires. Plus rarement, ils apparaissent spontanément dans le cholédoque suite à une stase de la bile à cause d’un obstacle.<br/><br/>
        La lithiase biliaire du cholédoque peut entraîner divers problèmes de santé, tels que la colique hépatique, l’angiocholite (inflammation des voies biliaires) et la pancréatite (inflammation du pancréas). Les symptômes courants comprennent des douleurs abdominales sévères, une jaunisse, des nausées, des vomissements et une fièvre.<br/><br/>
        Encore une fois, le diagnostic de la pathologie cholédocienne repose sur l’anamnèse du médecin, l’examen clinique, les analyses sanguines et une imagerie abdominale. Les deux examens les plus précis pour le diagnostic de calcul cholédocien est l’écho- endoscopie biliaire (sonde d’échographie placée à l’extrémité d’un endoscope inséré par la bouche) ou l’IRM abdominale.<br/><br/>
        La prise en charge de la pathologie lithiasique cholédocienne peut varier en fonction de la gravité des symptômes et de la présence de complications. Voici quelques approches de traitement possibles :
        <ol>
            <li><a href='../examinations/ercp'>Endoscopie rétrograde cholangiopancréatographie (ERCP)</a> : Il s’agit d’une procédure dans laquelle un endoscope est inséré par la bouche jusqu’à l’ampoule de Vater (sphincter où le cholédoque se connecte à l’intestin grêle). On réalise alors une sphinctérotomie endoscopique, petite incision du sphincter. Des instruments spéciaux peuvent ensuite être utilisés pour retirer les calculs biliaires, les fragmenter ou les écraser, facilitant ainsi leur passage. Il peut parfois être nécessaire d’appliquer un traitement provisoire à l’aide d’un stent (prothèse tubulaire) pour permettre l’écoulement de la bile lorsqu’il n’est pas possible d’extraire le calcul.</li>
            <li><b>Lithotripsie extracorporelle par ondes de choc (LEOC)</b> : Cette technique peut être utilisée pour fragmenter les calculs biliaires en utilisant des ondes de choc générées à l’extérieur du corps. Les fragments peuvent ensuite être éliminés plus facilement en ERCP.</li>
        </ol>
        Il est important de noter que la prise en charge de la pathologie lithiasique cholédocienne doit être personnalisée en fonction de chaque patient et de sa situation clinique spécifique. Il est recommandé de consulter un gastro-entérologue ou un chirurgien spécialisé pour obtenir un diagnostic précis et discuter des options de traitement les plus appropriées.
    </div>,
    "pathologies.irritable_bowel_syndrome.title": "Syndrome de l’intestin irritable (SII)",
    "pathologies.irritable_bowel_syndrome.content": <div>
        Il s’agit d’une véritable maladie digestive chronique, fréquente, toujours bénigne mais pouvant avoir un impact important sur la qualité de vie du patient.<br/>
        Ses symptômes principaux sont des troubles du transit (diarrhée, constipation ou alternance de diarrhée et de constipation), des douleurs abdominales et des ballonnements.
        
        <h2>Causes</h2>
        Ses causes sont multifactorielles et partiellement identifiées. Elles associent des troubles de la motricité intestinale (trop rapide ou trop lente), une hypersensibilité viscérale et des troubles de l’analyse des messages douloureux au niveau du système nerveux central, une micro inflammation de la paroi intestinale, une majoration de la perméabilité de la paroi intestinale (permettant le passage de molécules intra intestinale vers la paroi digestive et déclenchant des réactions micro inflammatoires), un déséquilibre de la flore intestinale (dysbiose).<br/>
        Le stress et l’anxiété peuvent aggraver les symptômes.<br/>
        Le SII peut également survenir dans les suites d’une gastro-entérite infectieuse sévère.<br/>

        <h2>Diagnostic</h2>     
        Son diagnostic est un diagnostic d’exclusion. Votre médecin peut être amené à prescrire certains examens dans le but d’exclure une autre pathologie, mais ceux-ci seront normaux si il s’agit d’un syndrome d’intestin irritable.

        <h2>Traitement</h2>
        Le traitement vise à diminuer la fréquence et l’intensité des symptômes. Il devra être personnalisé pour chaque patient, avec souvent des adaptations nécessaires.<br/><br/>
        Une alimentation saine et équilibrée est conseillée. Un régime pauvre en FODMAPs (oligosaccharides fermentescibles, disaccharides, monosaccharides et polyols) pourra être proposé afin de réduire les phénomènes de fermentation digestive responsables des douleurs, ballonnements et diarrhée. Il s’agira essentiellement de réduire la consommation de lactose, de certaines céréales, certains légumes (ail, oignon, poireau, choux, …), certains fruits (mangue, poire, pomme, prune, fruits secs), édulcorants artificiels, plats industriels, …<br/><br/>
        Un traitement médicamenteux pourra être proposé pour réguler le transit (laxatifs doux ou anti diarrhéique) et diminuer les douleurs (spasmolytiques). Les probiotiques peuvent également être efficaces.<br/><br/>
        Dans certains cas, des petites doses d’antidépresseur peuvent être utiles, par leur action antalgique sur l’hypersensibilité viscérale.<br/><br/>
        La gestion du stress est importante et l’activité physique améliore la symptomatologie.<br/><br/>
        L’hypnose a également montré une réelle efficacité.
    </div>,

    /*
        EXAMINATIONS
    */
   "examinations.home.title": "Prenez connaissances des modalités des examens que nous effectuons.",
   "examinations.home.subtitle": "Sélectionnez un examen dans la barre de nagivation à gauche pour en savoir plus.",
   "examinations.gastroscopy.title": "Gastroscopie",
   "examinations.gastroscopy.content": <div>
        La gastroscopie est une exploration visuelle de l'oesophage, de l'estomac et de la première partie du petit intestin (duodénum) avec une caméra dédiée à l'endoscopie; elle permet ainsi de diagnostiquer des ulcères, de mettre en évidence un reflux gastro-oesophagien ou une allergie alimentaire.
        <br/><br/>
        Voici quelques informations concernant la gastroscopie et la réponse aux questions que vous vous posez généralement au sujet de cette technique d'investigation.
        <ul style={{listStyleType: "circle"}}>
            <li>Pourquoi choisir la gastrocopie?</li>
            <li>Comment se préparer pour cet examen?</li>
            <li>Comment se déroule l'examen? </li>
            <li>Comment prendre RDV?</li>
        </ul>

        L'examen est indiqué pour explorer des symptômes de brûlant, de pesanteur, d'inconfort digestif ou de douleurs épigastriques.
        <br/><br/>
        L'exploration endoscopique ne dure que quelques minutes, parfois un peu plus longtemps s'il faut réaliser des biopsies. C'est surtout la préparation et le conditionnement du patient à l'examen qui prennent du temps.
        <br/><br/>
        Lors de l'endoscopie, un prélèvement (biopsie) peut être effectué et envoyé à un laboratoire pour analyse (laboratoire du CHC); une quote-part pour cette analyse vous sera par ailleurs peut-être demandée par le laboratoire en question. 
        <br/><br/>

        <h2>
            Se préparer à l'examen
        </h2>

        Vous devez vous présenter à jeûn à votre rendez-vous.
        <br/><br/>
        Toute prise alimentaire solide doit être évitée dans les 6 heures précédant l'examen.
        <br/><br/>
        Si l'endoscopie se déroule en fin de matinée, éventuellement, sur avis médical, vous pouvez boire jusque 2 heures avant l'examen (boissons type jus, café, thé, eau, pas de lait).
        <br/><br/>
        Une prémédication à base de Dormicum et/ou Rapifen vous est administrée par voie intraveineuse pour soulager les nausées parfois provoquées par le passage de l'endoscope au niveau du carrefour aéro-digestif ou de l'estomac. Un spray anesthésiant est aussi vaporisé dans l'arrière-gorge pour endormir cette zone. 
        <br/><br/>
        Mentionnez au médecin examinateur si vous êtes allergique à la Xylocaïne. 
        <br/><br/>
        Vous devez vous concentrer sur la respiration pendant toute la durée de l'examen. Celui-ci commence par l'oesophage après avoir passé l'endoscope par la bouche et franchi le carrefour entre les voies digestives et les voies respiratoires; ensuite, l'appareil est poussé doucement dans l'estomac jusque dans le duodénum (début de l'intestin grêle).

        <h2>
            Déroulement de l'examen
        </h2>

        Ce n'est pas un examen douloureux, mais parfois gênant. Il faut surtout se concentrer sur la respiration pendant la procédure. 
        Des biopsies sont souvent effectuées, au niveau de l'estomac et du duodénum, notamment pour déterminer la présence d'une infection ou d'une allergie, par exemple. Ces biopsies sont réalisées à l'aide d'une pince à usage unique et nécessitent parfois l'arrêt de médicaments anticoagulants (Sintrom, Pradaxa, Eliquis...).
        Une fois l'examen terminé, vous êtes installés pendant 1/2h ou 1 h dans la salle de repos pour récupérer à votre aise. Ensuite, vous êtes revu par le médecin pour obtenir des renseignements sur l'examen et recevoir des conseils thérapeutiques et/ou diététiques.
        Vous devez, en principe, être accompagné parce que vous ne pouvez pas reprendre le volant dans les suites de l'examen.
        <br/><br/>
        A noter que, pour votre sécurité, les appareils endoscopiques sont désinfectés rigoureusement entre chaque examen selon une procédure validée par les autorités compétentes en matière de santé publique et d'hygiène. 
        <br/><br/>
        Si vous souhaitez d'autres renseignements concernant cet examen, n'hésitez pas à prendre rendez-vous en consultation au préalable en téléphonant au secrétariat d'endoscopie au <a href="tel:003287213891">
        087/21.38.91
        </a>.
    </div>,
    "examinations.anorectal_echoendoscopy.title": "Écho-endoscopie anorectale",
    "examinations.anorectal_echoendoscopy.content": <div>
        Une écho-endoscopie anorectale sera réalisée pour examiner morphologiquement la toute dernière partie du colon (le rectum) ainsi que l'anus et ses sphincters. Cet examen permet de visualiser les différentes couches de la paroi de l’anus et du rectum, ainsi que les tissus au pourtour (présence de ganglions, etc.).<br/><br/>
        L'examen est en général très bien supporté et ne vous occasionnera aucune douleur. Parfois, il peut y avoir des petites gênes liées à l'introduction de l'appareil. Eventuellement, on va injecter des petites quantités d'eau dans votre rectum. L'examen ne demande aucune préparation à domicile.<br/><br/>
        À l'entrée à la clinique, une infirmière va d'abord vous administrer un lavement rectal. Vous serez ensuite placé sur une table pour vous allonger confortablement sur le coté gauche.<br/><br/>
        Le médecin va prudemment faire glisser une sonde fine dans votre anus. Eventellement, un mini-ballon sera gonflé, de la taille d'une noix. Grâce à la sonde d'échographie située au bout de la sonde, le médecin va maintenant examiner votre ampoule rectale et/ou l'anus et ses sphincters. On peut donc par exemple examiner les muscles qui forment les sphincters de l’anus ( dans le cas de la recherche des causes de l’incontinence) ; déterminer la taille et la profondeur de tumeurs de l’anus ou du rectum ; rechercher des abcès et des fistules.<br/><br/>
        À la fin de l'examen, l'a sonde est retirée et vous pouvez immédiatement quitter la clinique.<br/><br/>
        L'examen dure environ 10 minutes. Cet examen ne nécessite pas d’anesthésie et on ne doit pas être à jeun mais il est demandé de faire un lavement une heure avant l’examen.
    </div>,
    "examinations.anorectal_manometry.title": "Manométrie anorectale",
    "examinations.anorectal_manometry.content": <div>
        Une manométrie sera réalisée pour explorer la sensibilité et la fonction motrice de votre  région anale et du rectum (la toute dernière partie du gros intestin). Elle peut être demandée dans le cadre d'une constipation de longue durée, de douleurs anales inexpliquées, de difficultés de retenir les selles ou les gaz (incontinence) ou avant et après une intervention chirurgicale.<br/><br/>
        L'examen n'est pas douloureux. Vous ne devez pas être à jeun mais il est préférable de réaliser un lavement une heure avant l’examen.<br/><br/>
        À l'entrée à la clinique, une infirmière va d'abord vous administrer un lavement rectal si cela n'a pas été fait au préalable à votre domicile. Vous serez ensuite placé sur une table pour vous allonger confortablement sur le côté gauche.<br/><br/>
        Le médecin va prudemment faire glisser une sonde fine dans votre anus. La sonde est à peine aperçue. A cette étape de l'examen, le médecin va seulement mesurer les pressions au niveau du sphinter anal. Après, il vous demandera de faire certains mouvements, comme serrer les fesses ou tousser. La aussi, l'examen n'est jamais douloureux. La manométrie dure environ 10 minutes.<br/><br/>
        À la fin de l'examen, la sonde est retirée et vous pouvez immédiatement quitter la clinique.
    </div>,
    "examinations.esophageal_manometry.title": "Manométrie œsophagienne",
    "examinations.esophageal_manometry.content": <div>
        Une manométrie sera réalisée pour explorer la fonction motrice de l'œsophage et si d'autres examens n'ont pas permis suffisamment d'apporter un diagnostic. Parfois, on va aussi réaliser cet examen avant un acte chirurgical afin de s'assurer du bon fonctionnement de l'oesophage. Une autre possibilité est la recherche de douleurs dans la poitrine qui ne s'expliquent pas par des problèmes cardiaques.
        <br/><br/>
        L'examen n'est pas douloureux. Il ne vous demande aucune préparation. Il faut juste être à jeun 6 heures avant. Vous êtes placé sur un fauteuil avant d'être anesthésié au niveau des deux narines à l'aide d'un spray.
        <br/><br/>
        Le médecin va ensuite introduire dans votre nez une sonde fine qui a le diamètre d'un spaghetti. Il poussera la sonde doucement jusqu'à l'estomac, ce qui n'est pas douloureux. Rarement, des nausées peuvent apparaître. La manométrie dure environ 10 minutes. Pendant ce temps, vous devez avaler plusieurs fois de l'eau ou des petits morceaux de biscotte lorsque le médecin vous le demande.
        <br/><br/>
        À la fin de l'examen, la sonde est retirée et vous pouvez immédiatement quitter la clinique.
    </div>,
    "examinations.urea_breath_test.title": "Test respiratoire à l'urée",
    "examinations.urea_breath_test.content": <div>
        Ce test permet de déterminer si la bactérie Helicobacter Pylori est toujours présente dans votre estomac ou pas.
        Cette bactérie est habituellement mise en évidence grâce aux biopsies réalisées durant une gastroscopie.
        <br/><br/>
        L'Helicobacter Pylori est un facteur de risque de l'ulcère de l'estomac mais également du cancer de l'estomac.
        <br/><br/>
        Après avoir reçu le traitement antibiotique adapté, nous pratiquons ce test simple, indolore et rapide afin de déterminer si la bactérie est bien éradiquée. Ainsi, cela évite de devoir pratiquer une nouvelle gastroscopie.
        <br/><br/>
        Pour ce test, il vous sera demandé de vous présenter à jeun auprès des infirmières du service un jour au matin. Nous vous demanderons alors de souffler dans des petites éprouvettes et nous récolterons votre souffle.  Vous devrez alors avaler une petite gélule d'urée accompagnée d'un jus de pomme ou d'orange qui vous est fourni.  Trente minutes après avoir bu le jus, vous soufflerez une seconde fois dans les éprouvettes.  Le test est alors terminé.  Le contenu des éprouvettes est ensuite envoyé au laboratoire pour analyse et votre médecin traitant sera informé quelques jours après le test des résultats.
        <br/><br/>
        En cas de persistance de la bactérie dans l'estomac, il est possible qu'un deuxième traitement antibiotique vous soit proposé par votre médecin.
    </div>,
    "examinations.upper_digestive_tract_echo_endoscopy.title": "Écho-endoscopie du tube digestif supérieur",
    "examinations.upper_digestive_tract_echo_endoscopy.content": <div>
        L’écho-endoscopie est un examen qui combine une endoscopie et une échographie.  Une sonde d’échographie miniature est fixée au bout d’un endoscope et est amenée à proximité d’un organe à étudier.  Cette technique est utile pour l’analyse précise de la paroi du tube digestif et des organes de voisinage (Œsophage, estomac, duodénum, pancréas, foie, vésicule, voies biliaires ganglions internes).
        L’écho-endoscopie permet de compléter l’analyse d’anomalies détectées en gastroscopie, au scanner ou à l’IRM.  Elle permet enfin d’obtenir des informations qui ne peuvent être obtenues par d’autres techniques.  Dans certains cas, elle permet de réaliser des prélèvements de l’organe malade qui seront étudiés au laboratoire pour déterminer la nature du problème (bactérie, kyste, tumeur bénigne ou maligne).
        <br/><br/>
        Comme pour une gastroscopie, l’écho-endoscopie du tube digestif supérieur nécessite de se présenter à jeun à la clinique.  Une légère anesthésie est pratiquée par un anesthésiste pour permettre la réalisation d’un examen confortable pour le patient et le médecin.  Un endoscope souple est alors introduit par le bouche et poussé jusqu’à la zone à étudier.  Le patient est ensuite ramené dans sa chambre pour s’y réveiller.  Le médecin rejoint le patient un peu plus tard pour lui expliquer ses résultats.
        <br/><br/>
        Dans le but de proposer des examens de qualité optimale, nous disposons  à St Elisabeth Heusy d’un matériel d’écho-endoscopie de haute qualité et de dernière génération permettant l’analyse la plus exacte possible.  L’écho-endoscopie du tube digestif supérieur est pratiquée par le Dr Olivier Cajot et le Dr Maxime Seivert.
        <br/><br/>
        Il est important de signaler ici que comme tout autre acte médical ou intervention sur le corps humain, même réalisés dans des conditions de compétence et de sécurité maximum, des complications sont possibles en écho-endoscopie haute.  Celles-ci sont toutefois exceptionnelles (perforations du tube digestif, hémorragie digestive, infections) mais peuvent obliger le patient à rester hospitalisé et parfois à devoir subir un traitement chirurgical.
        <br/><br/><br/><br/>
        <i>Ce descriptif est inspiré du site <a href="www.SFED.org">www.SFED.org</a>.</i>
    </div>,
    "examinations.ultrasound.title": "Échographie",
    "examinations.ultrasound.content": <div>
        L’échographie abdominale est un examen qui explore principalement les organes abdominaux solides ou contenant du liquide. L’échographie n’expose pas aux rayons X: elle utilise des ultrasons émis par une sonde. Les ultrasons traversent la peau et les tissus du patient pour atteindre les organes qui, en fonction de leur densité, réfléchiront les ultrasons vers l’appareil qui les traduit en images. L'échographie permet ainsi de visualiser, avec une résolution moindre que le scanner ou l’IRM les organes abdominaux tels que foie, vésicule, pancréas, rate, reins, aorte, gros ganglions abdominaux.
        <br/><br/>
        Il s'agit de l'examen de choix pour la détection de calculs (pierres) dans la vésicule biliaire.

        <h2>Comment se déroule une échographie abdominale?</h2>
        Vous êtes placé confortablement en position couchée sur le dos, avec le ventre découvert jusqu’en bas du bassin. Le médecin place sur la peau du ventre un gel conducteur pour faciliter le passage des ultrasons à travers la peau. La réalisation d’une échographie abdominale dure une vingtaine de minutes.
    
        <h2>Informations pratiques</h2>
        Il est nécessaire d'être à jeun d'au moins 6 heures.
        <br/><br/>
        Prise de rendez-vous : <Link to={"../contact"}>page de contact</Link>
    </div>,
    "examinations.ercp.title": "Cholangio-pancréatographie rétrograde endoscopique (C.P.R.E.)",
    "examinations.ercp.content": <div>
        La cholangio-pancréatographie rétrograde endoscopique (C.P.R.E.) est un examen endoscopique qui permet de traiter certains problèmes des voies biliaires et du pancréas. Cette procédure peut comporter le retrait de calculs, permettre le drainage du pancréas et ou des voies biliaires avec le placement d'une prothèse.
        <br/><br/>
        Un bilan préalable est donc souvent réalisé pour préciser le problème à soigner. Cela peut être par exemple une échographie, un CT scanner, une RMN ou une écho-endoscopie biliaire et pancréatique.
        <br/><br/>
        L'echo-endoscopie se déroulant aussi sous anesthésie, il est chaque fois possible dans notre service de réaliser cet examen préalable et la CPRE thérapeutique durant la même anesthésie, ce qui réduit les narcoses répétées et accélère la prise en charge.
    
        <h2>Déroulement de l'examen</h2>
        Les canaux biliaires et pancréatiques s'écoulent dans la partie initiale de l'intestin grêle (duodénum) à travers un orifice appelé « papille » ou ampoule de Vater. L'examen utilise un endoscope qui est glissé par la bouche jusque dans le duodénum. Ensuite on insère à travers l'endoscope un cathéter, que l'on guide jusque la papille pour injecter un produit de contraste dans les voies biliaires et/ou pancréatiques. On réalise alors des radiographies. Ensuite un traitement est réalisé.
        <ArticleImage 
            src={"/images/examinations/ercp_fr.jpg"} 
            caption={<div>
                Déroulement de l'examen<br/>
                Source: <a href="https://stock.adobe.com">Adobe Stock</a>
            </div>}/>
        La durée totale de la CPRE est très variable selon la difficulté de l'examen et le traitement à réaliser (entre 15 et 120 minutes).
    
        <h2>Informations pratiques</h2>
        Avant cet examen, un contact avec un médecin est indispensable.
        <br/><br/>
        L'examen se réalise sous anesthésie. Une hospitalisation de 24h00 est le plus souvent nécessaire. Cet examen se réalise à la clinique St Elisabeth à Heusy.
        <br/><br/>
        Nous réalisons cet examen en insufflant du CO2 dans le tube digestif pour voir correctement avec un confort accru pour le patient. En l'absence de contre-indication, un suppositoire d'anti-inflammatoire est administré pour réduire au maximum le risque d'effets indésirables.
        <br/><br/>
        Il ne vous est pas permis de vous alimenter le jour de l'examen. L'alimentation est reprise le lendemain, sauf complication ou nécessité de réaliser d'autres examens.
    
        <h2>Quelles sont les complications possibles ?</h2>
        <b>Tout acte médical, exploration, intervention sur le corps humain, même conduit dans des conditions de compétence et de sécurité conformes aux données actuelles de la médecine et de la réglementation en vigueur, recèle un risque de complications. Les complications les plus fréquentes de la CPRE thérapeutique (sphinctérotomie endoscopique et traitement associés) sont les suivantes: pancréatite aigüe, infection des voies biliaires ou de la vésicule biliaire, perforation de la paroi digestive, hémorragie digestive. La fréquence de chacune de ces complications est de l'ordre de 1-4%.</b>
        <br/><br/>
        D'autres complications sont exceptionnelles telles que les troubles cardio-vasculaires ou respiratoires. Ces complications peuvent être favorisées par vos antécédents médico-chirurgicaux ou par la prise de certains médicaments.
        <br/><br/>
        Toutes ces complications peuvent nécessiter de retarder le moment de la réalimentation, de prolonger l'hospitalisation et de rendre une nouvelle endoscopie ou une opération nécessaire. Une hémorragie peut conduire à pratiquer des transfusions de sang ou de dérivés sanguins.
        <br/><br/>
        Les complications apparaissent le plus souvent lors de l'endoscopie mais peuvent également se révéler quelques jours après l'examen (douleurs abdominales ou thoraciques, fièvre, frissons, ...). Il est alors très important de contacter immédiatement le médecin et/ou l'anesthésiste qui se sont occupés de vous. En cas d'impossibilité de prendre contact avec eux, il est très important de prendre contact très rapidement avec votre médecin traitant ou de se rendre aux services des urgences de la clinique St Elisabeth.

        <h2>Quelles sont les autres alternatives à cet examen ?</h2>
        L'échographie, le scanner, l’échoendoscopie et parfois la résonance magnétique sont souvent pratiqués avant la CPRE. La résonance magnétique peut permettre de bien voir les canaux du foie et du pancréas et, s'ils sont normaux, d'éviter la CPRE.
        <br/><br/>
        Cependant, la CPRE est le seul examen qui permet un traitement simultané (extraction d'un calcul - pose d'une prothèse). Le traitement chirurgical peut être une alternative au traitement endoscopique mais nécessite une hospitalisation plus longue et des risques plus élevés, en particulier chez les sujets âgés.

        <h2>En cas de refus</h2>
        Si l'examen que votre médecin a proposé n'est pas réalisé, cela peut avoir des conséquences néfastes pour votre santé. Un calcul du cholédoque, maladie bénigne, laissé en place peut entraîner entre autres une infection sévère, parfois mortelle.
    </div>,
    "examinations.colonoscopy.title": "Colonoscopie",
    "examinations.colonoscopy.content": <div>
        La colonoscopie consiste en l’exploration visuelle (avec une caméra) du colon, depuis l’anus jusqu’au caecum (début du colon) ainsi que, dans certains cas, l’exploration de la fin du petit intestin (iléon). Cet examen permet de rechercher l’origine de symptômes (troubles du transit, douleurs abdominales, test de dépistage positif, anémie, …), de réaliser des biopsies de la muqueuse (prélever un petit morceau de tissu pour l’analyser au microscope) et de rechercher des polypes ou un cancer du colon. Il permet également, dans la mesure du possible, de réséquer les éventuels polypes découverts lors de l’examen.

        <h2>
            Avant l’examen
        </h2>
        Dans la très grande majorité des cas, vous bénéficiez au préalable d’une consultation auprès du médecin gastro-entérologue pour valider l’indication de l’examen et recevoir les explications complètes, la préparation, les modalités de l’examen endoscopique ainsi qu’un carnet qui comprend ces informations. Ce carnet contient également les consentements (pour l’anesthésie et pour l’endoscopie) qu’il est impératif de lire et de signer pour le jour de l’examen. Lors de cette consultation, vous aurez l’occasion de poser toutes vos questions.

        <h3>
            En quoi consiste la préparation?
        </h3>
        Pour une exploration optimale du colon, il est absolument nécessaire d’obtenir un colon le plus propre possible, idéalement indemne de tout résidu. 
        <br/>
        La préparation est individualisée en fonction de chaque patient (durée du régime alimentaire, type de préparation laxative) et déterminée en consultation pré-colonoscopie. 
        <br/><br/>
        Il est important de respecter ces consignes de façon stricte afin d’améliorer au maximum la qualité de l’examen. Néanmoins, chez certains patients, malgré le respect des consignes, la préparation n’est pas optimale et l’examen nécessite parfois d’être répété dans un intervalle rapproché ou complété par un autre examen. 

        <ul>
            <li>
                <em>Régime sans résidu</em> (durée déterminée en consultation, selon votre situation personnelle, généralement 2 à 3 jours)
                <ul style={{listStyleType: "circle"}}>
                    <li><em>À éviter :</em> pains complets, pains aux céréales, muesli, céréales complètes et aliments à base de céréales complètes, tous les fruits et légumes, jus de fruits avec pulpe (pamplemousse, ananas, tomates, pruneaux), lait et laitages à l’exception des yaourts sans morceaux de fruits, confitures et gelées</li>
                    <li><em>Autorisés :</em> pain blanc, riz blanc, pâtes, semoule, jambon cuit, blanc de poulet, viande maigre, oeuf cuit (dur ou à la coque), poisson non pané, pommes de terre sans peau, jus de fruits sans pulpe, fruits en compote, bouillon de légumes, jus de pomme, yaourts sans morceaux de fruits, miel, épices (curry, paprika, poivre, thym, laurier, estragon, cerfeuil, persil, sauce tomate sans peau ni pépins, sel)</li>
                </ul>
            </li>
            <li>
                <em>Préparation laxative</em> (individualisée en consultation, selon votre situation personnelle)
                <br/>
                Il s’agit d’une « purge » à base de produits laxatifs. Il existe plusieurs produits disponibles et le choix dépendra du profil de chaque patient. Généralement, la préparation requiert 2 prises; l’une la veille au soir et l’autre le matin de l’examen (en respectant un intervalle de jeûne de 2 à 3h avant l’examen). 
                <br/><br/>
                Chez certains patients, un traitement laxatif pourra être prescrit quelques jours avant l’examen, par exemple en cas de constipation sévère ou d’antécédent de préparation sous optimale.
                Cette préparation est généralement réalisée à domicile et l’examen est réalisé en hôpital de jour. Néanmoins, dans certaines situations, il peut vous être demandé d’être hospitalisé la veille de l’examen pour réaliser la purge à l’hôpital. 
            </li>

            <li>
                <em>Rencontre au préalable d'un médecin de l’équipe d’anesthésie</em> (par forcément le même que le jour de l’examen) 
                <br/>
                Il vous expliquera la sédation et analysera votre dossier médical (antécédents/médicaments) et vous donnera des consignes concernant la poursuite ou l’arrêt de vos médicaments en vue de l’examen. 
                Vous aurez la possibilité de poser vos questions concernant l’anesthésie. 
            </li>
        </ul>

        <h2>
            Pendant l’examen
        </h2>
        La colonoscopie nécessite une courte hospitalisation (généralement en hôpital de jour) ainsi qu’une anesthésie de type « sédation profonde » gérée par le médecin anesthésiste réanimateur.
        Lorsque vous serez endormi, le médecin gastro-entérologue réalise l’examen sans que vous ne ressentiez de douleur ni de souvenir après l’examen. 
        La durée de l’examen varie en fonction des découvertes per examen et des éventuels polypes à réséquer (généralement 30 à 45 minutes). 
        Vous êtes ensuite emmené en salle de réveil où vous vous réveillez rapidement après l’examen et vous êtes ensuite reconduit dans votre chambre.

        <h2>
            Après l’examen
        </h2>
        Vous regagnez votre chambre pour vous reposer et vous êtes revu par le médecin gastro-entérologue pour discuter de vos résultats et du suivi à y apporter. Si des prélèvements ont été réalisés (biopsies, résection de polypes), les résultats de l’analyse au microscope seront disponibles auprès de votre médecin traitant 10 jours plus tard environ. Le médecin gastro-entérologue pourra vous donner un intervalle de surveillance en fonction des résultats de l’examen. 
        Compte tenu de la sédation, il ne vous sera pas possible de conduire/travailler/prendre des décisions importantes jusqu’au lendemain de l’examen. Il est donc nécessaire d’être accompagné pour le retour. 
        Exceptionnellement, il peut vous être demandé de rester en surveillance la nuit suivant l’examen. 
    </div>,
    "examinations.defeco_mri.title": "IRM dynamique du plancher pelvien (Déféco-IRM)",
    "examinations.defeco_mri.content": <div>
        L’examen se réalise dans le service de radiologie et est indiqué dans le bilan de troubles de la statique pelvienne. On étudie la position des organes du pelvis lors des efforts de poussée, de retenue et d’évacuation. On peut donc explorer un prolapsus (urinaire, génital ou rectal) ou une « descente d’organe ».<br/><br/>
        Contrairement à la <b>colpo-cysto-défécographie</b> il n’y a pas de sondage urinaire ni de préparation à boire.
    </div>,
    "examinations.twenty_four_hour_ph_impedance_testing.title": "pH-impédancemétrie de 24h",
    "examinations.twenty_four_hour_ph_impedance_testing.content": <div>
        <h2>
            Pourquoi ?
        </h2>
        Confirmer l’existence ou non d’un reflux gastro oesophagien (RGO) pathologique, acide ou non acide et s’assurer que la symptomatologie présentée par le patient est bien corrélée au reflux gastro oesophagien.<br/><br/>
        Par exemple : lorsque la gastroscopie n’a pas montré de lésion significative d’oesophagite confirmant le diagnostic de RGO, lorsque la symtomatologie n’est pas typique, lorsque le traitement antiacide est inefficace, pour confirmer certaines indications de chirurgie anti reflux, ...

        <h2>
            Comment ?
        </h2>
        Une fine sonde munie de capteurs de pH et d’impédance est placée dans l’œsophage par le nez et est reliée à un boitier porté en bandoulière qui enregistre les données durant 24 heures.<br/><br/>
        Le placement de la sonde n’est pas douloureux mais peut engendrer une gêne transitoire dans la gorge et quelques nausées.<br/><br/>
        Si vous prenez un traitement antiacide, le médecin qui vous prescrit l’examen vous indiquera si vous devez l’interrompre quelques jours avant ou non, selon votre situation.<br/><br/>
        Vous devrez être à jeun (ne pas manger ni boire) 6 heures avant l’examen.<br/><br/>
        Durant l’examen, vous devrez vivre normalement, sans éviter ce qui favorise les symptômes de reflux. Il vous sera demandé de renseigner les moments où vous ressentez des symptômes, les périodes de repas, et lorsque vous êtes en position couchée.<br/><br/> 
        Vous devrez revenir le lendemain pour ôter la sonde et rendre le boitier d’enregistrement qui sera ensuite analysé.<br/><br/>
    </div>,
    "examinations.video_capsule_endoscopy.title": "Videocapsule Endoscopique (VCE)",
    "examinations.video_capsule_endoscopy.content": <div>
        Cet examen permet l’exploration de l’intestin grêle (« petit intestin »), dont la majeure partie n’est pas accessible par la gastroscopie et la colonoscopie conventionnelle. L’indication principale est la recherche de lésions responsables de saignements digestifs.
        
        <h2>
            Comment ?
        </h2>
        La capsule, de la taille d’une grosse gélule, est avalée avec un peu d’eau et prend de multiples photographies de la paroi muqueuse tout au long de son transit dans l’intestin grêle. Ces photos sont transmises et enregistrées dans un boitier porté en bandouliere.<br/>
        Une préparation de l’intestin est nécessaire, avec l’arrêt de toute prise de fer oral 7 à 10 jours avant l’examen, la prise d’une purge par voie orale la veille au soir puis un jeun complet pour permettre la visualisation de la paroi intestinale.
        <br/><br/>
        L’enregistrement dure 8 à 9 heures, durant lesquelles vous pourrez rentrer chez vous et vous alimenter selon les consignes qui vous seront données. Il vous sera demandé de rapporter le boitier en fin de journée ou le lendemain, pour permettre son analyse.<br/>
        La capsule utilisée est à usage unique et sera éliminée dans les selles, elle ne doit pas être récupérée après l’examen.
        <br/><br/>
        Les principales contre-indications à cet examen sont les sténoses de l’intestin grêle pouvant entrainer une rétention de la capsule. Il est dès lors important de signaler tout antécédent de chirurgie intestinale, occlusion intestinale, maladie inflammatoire intestinale, radiotherapie, … Les troubles de déglutition doivent également être signalés car nécessitent dans certains cas une technique particulière pour pouvoir placer la capsule dans l’intestin.<br/>
        La grossesse est une contre-indication. <br/>
        La réalisation d’une RMN (imagerie par résonnance magnétique) est contre indiquée tant que la capsule n’ a pas été évacuée de façon certaine.
    </div>,

    /*
        CONTACT
    */
    "contact.title": "Contact",
    "contact.header.day": "Jour",
    "contact.header.morning": "Occupation le matin",
    "contact.header.afternoon": "Occupation le soir",
    "contact.monday": "Lundi",
    "contact.tuesday": "Mardi",
    "contact.wednesday": "Mercredi",
    "contact.thursday": "Jeudi",
    "contact.friday": "Vendredi",
    "contact.saturday": "Samedi",
    "contact.sunday": "Dimanche",
    "contact.subtitle.schedule": "Horaire",
    "contact.subtitle.appointment": "Prise de rendez-vous",
    "contact.virginie_jadot.tuesday.morning": "Consultations à Heusy",
    "contact.virginie_jadot.tuesday.afternoon": "Endoscopies sous sédation à Heusy",
    "contact.virginie_jadot.wednesday.morning": "Endoscopies ambulatoires à Heusy",
    "contact.virginie_jadot.wednesday.afternoon": "Consultations à Heusy",
    "contact.virginie_jadot.friday.morning": "Endoscopies (une semaine sous sédation une semaine ambulatoires) à Malmedy",
    "contact.virginie_jadot.friday.afternoon": "Consultations à Malmedy",
    "contact.virginie_jadot.appointment": <div>
        Via le secrétariat de médecine interne à Malmedy: <br/>
        <a href="tel:+3280793158">+32 80 793 158</a> ou <a href="tel:+3280793122">+32 80 793 122</a>
    </div>,
    "contact.ann_christine_dupin.tuesday.morning": "Endoscopies en ambulatoire à St Vith",
    "contact.ann_christine_dupin.tuesday.afternoon": "",
    "contact.ann_christine_dupin.wednesday.morning": "Endoscopies sous sédation et consultations à Eupen",
    "contact.ann_christine_dupin.wednesday.afternoon": "Endoscopies sous sédation et consultations à Eupen",
    "contact.ann_christine_dupin.thursday.morning": "Consultations, manométrie ano-rectale et échoendoscopie anorectale à Heusy",
    "contact.ann_christine_dupin.thursday.afternoon": "Consultations, manométrie ano-rectale et échoendoscopie anorectale à Heusy",
    "contact.ann_christine_dupin.friday.morning": "Endoscopie en ambulatoire et sous sédation à Heusy",
    "contact.ann_christine_dupin.friday.afternoon": "Endoscopie en ambulatoire et sous sédation à Heusy",
    "contact.ann_christine_dupin.appointment": <div>
        Saint Vith: <a href="tel:+3280854111">+32 80 854 111</a><br/>
        Eupen: <a href="tel:+3287599312">+32 87 599 312</a><br/>
        Heusy: <a href="tel:+3287213700">+32 87 213 700</a>
    </div>,
    "contact.nadia_torres.monday.morning": "Consultations à Heusy",
    "contact.nadia_torres.monday.afternoon": "Endoscopies ambulatoires à Heusy",
    "contact.nadia_torres.tuesday.morning": "Consultations à Eupen",
    "contact.nadia_torres.tuesday.afternoon": "Endoscopies sous sédations à Eupen",
    "contact.nadia_torres.wednesday.morning": "Endoscopies sous sédations à Heusy",
    "contact.nadia_torres.wednesday.afternoon": "Consultations à Heusy",
    "contact.nadia_torres.friday.morning": "Endoscopies sous sédations à Eupen",
    "contact.nadia_torres.friday.afternoon": "Consultations à Eupen",
    "contact.nadia_torres.appointment": <div>
        Eupen: <a href="tel:+3287599312">+32 87 599 312</a><br/>
        Heusy: <a href="tel:+3287213700">+32 87 213 700</a>
    </div>,
    "contact.astrid_denis.monday.morning": "Endoscopies ambulatoires à Heusy",
    "contact.astrid_denis.monday.afternoon": "Manometries œsophagiennes / consultations à Heusy",
    "contact.astrid_denis.tuesday.morning": "Endoscopies ambulatoires à Eupen",
    "contact.astrid_denis.tuesday.afternoon": "Consultations aux Cabinets Medicaux de la Haie des Chêne à Dolembreux",
    "contact.astrid_denis.wednesday.morning": "Consultations aux Cabinets Médicaux de la Haie des Chênes à Dolembreux",
    "contact.astrid_denis.wednesday.afternoon": "",
    "contact.astrid_denis.thursday.morning": "pH-impedancemétries / consultations  à Heusy",
    "contact.astrid_denis.thursday.afternoon": "Endoscopies sous sédation à Heusy",
    "contact.astrid_denis.appointment": <div>
        Eupen: <a href="tel:+3287599312">+32 87 599 312</a><br/>
        Heusy: <a href="tel:+3287213700">+32 87 213 700</a>
    </div>,

    /*
        CONSTRUCTION
    */
    "construction.title": "En construction",
    "construction.description": "Cette page sera bientôt disponible.",

    /*
        NOT FOUND
    */
    "not_found.title": "Erreur 404 - Page non trouvée",
    "not_found.description": "Vous seriez-vous égaré.e ?",
    "not_found.call_to_action_1": "Recommencez vos recherches depuis la",
    "not_found.call_to_action_2": "page d'accueil",
    "not_found.call_to_action_3": " !",
};

export default french;