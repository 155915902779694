import React, { ReactNode } from 'react';

import '../styles/components/ArticleImage.css';

export default function ArticleImage({ smaller, src, caption }: { smaller?: boolean, caption: ReactNode, src: string }) {
    return (
        <div className='centerFlex'>
            <div>
                <div className='centerFlex'>
                    <img className={smaller === true ? 'articleImage smaller' : 'articleImage'} src={src} alt='' />
                </div>
                {caption !== undefined &&
                    <div className='imageCaption'>
                        {caption}
                    </div>}
            </div>
        </div>
    );
}