export const Doctors  = {
    olivier_cajot: {
      imgSrc: "/images/team/olivier_cajot.png",
      name: "Olivier Cajot",
    },
    maxime_seivert: {
      imgSrc: "/images/team/maxime_seivert.jpg",
      name: "Maxime Seivert",
    },
    virginie_jadot: {
      imgSrc: "/images/team/virginie_jadot.jpg",
      name: "Virginie Jadot",
    },
    alexandre_clotuche: {
      imgSrc: "/images/team/alexandre_clotuche.jpg",
      name: "Alexandre Clotuche",
    },
    donald_vermer: {
      imgSrc: "/images/team/donald_vermer.jpg",
      name: "Donald Vermer",
    },
    ann_christine_dupin: {
      imgSrc: "/images/team/ann_christine_dupin.jpg",
      name: "Ann-Christine Dupin",
    },
    matthias_dietze: {
      imgSrc: "/images/team/matthias_dietze.jpg",
      name: "Matthias Dietze",
    },
    nadia_torres: {
      imgSrc: "/images/team/nadia_torres.jpg",
      name: "Nadia Torres",
    },
    astrid_denis: {
      imgSrc: "/images/team/astrid_denis.jpg",
      name: "Astrid Denis"
    }
} ;

export type DoctorKey = keyof typeof Doctors;