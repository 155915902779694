import React from 'react';

import '../styles/components/HRule.scss';

export default class HRule extends React.Component {
    render() {
        return(
            <hr className="center-diamond"></hr>
        )
    }
}

