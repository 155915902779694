import React from "react";

// Styles
import "../styles/pages/Home.css";
import "../styles/pages/Contact.css";

import I18n from "../i18n/i18n";
import { useLoaderData } from "react-router-dom";
import {
  DoctorSchedule,
} from "../components/DoctorSchedule";

export default function Contact() {
  useLoaderData(); // Ensures it is refreshed when language changes

  return (
    <div className="scrollableContent">
      <div className="pageTitle">{I18n.getString("contact.title")}</div>

      <DoctorSchedule
        doctor_id="virginie_jadot"
        days={[
          'tuesday',
          'wednesday',
          'friday',
        ]}
      />

      <DoctorSchedule
        doctor_id="ann_christine_dupin"
        days={[
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
        ]}
      />

      <DoctorSchedule
        doctor_id="nadia_torres"
        days={[
          'monday',
          'tuesday',
          'wednesday',
          'friday'
        ]}
      />

      <DoctorSchedule
        doctor_id="astrid_denis"
        days={[
          'monday',
          'tuesday',
          'wednesday',
          'thursday'
        ]}
      />
    </div>
  );
}
