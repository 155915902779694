import React from "react";

import { Link } from "react-router-dom";
import { withParams } from "../lib/RouterUtils";

import "../styles/pages/News.css";

import I18n from "../i18n/i18n";
import NotFound from "./NotFound";

class News extends React.Component {
  constructor(props) {
    super(props);

    this.news = {
      covid_19: {
        cover: "covid_mask.jpg",
        date: new Date(2022, 5, 5, 5, 18),
      },
    };

    this.state = {
      id: this.props.params.id,
    };
  }

  updateId() {
    this.setState({
      id: this.props.params.id,
    });
  }

  render() {
    const pageId = this.props.params.id;

    if (pageId !== undefined) {
      const post = this.news[pageId];
      if (post === undefined) {
        return <NotFound />;
      }

      return (
        <div>
          <div
            className="articleCover"
            style={{ backgroundImage: `url("/images/news/${post.cover}")` }}
          >
            <div className="fullSizeOpacityBox centerFlex">
              <div>
                <div className="centerFlex">
                  <div className="articleTitle">
                    {I18n.getString(`news.${pageId}.title`)}
                  </div>
                </div>
                <hr />
                <div className="centerFlex">
                  <div className="articlePublicationDate">
                    {I18n.getString("news.published_on")}{" "}
                    {post.date.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="articleContent">
            {I18n.getString(`news.${pageId}.contents`)}
          </div>
        </div>
      );
    } else {
      return (
        <div className="articleGridContainer">
          <div className="articleGrid">
            {Object.keys(this.news).map((key, index) => {
              const post = this.news[key];
              return (
                <Link
                  to={"../news/" + key}
                  key={key}
                  className={`articleGridItem${index + 1} articleGridItem`}
                >
                  <img
                    src={`/images/news/${post.cover}`}
                    alt=""
                    className="articleGridImg"
                  />
                  <header className="articleGridItemCover">
                    <div className="articleGridItemCaption">
                      <div className="articleGridItemTitle">
                        {I18n.getString(`news.${key}.title`)}
                      </div>
                      <div className="articleGridItemSubtitle">
                        {I18n.getString("news.published_on")}{" "}
                        {post.date.toLocaleString()}
                      </div>
                    </div>
                  </header>
                </Link>
              );
            })}
          </div>
        </div>
      );
    }
  }
}

export default withParams(News);
