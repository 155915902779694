import React, { ReactNode } from "react";

import "../styles/components/DoctorSchedule.css";
import I18n from "../i18n/i18n";
import { Doctors, DoctorKey } from "../data_definitions/doctors";
import { Link } from "react-router-dom";

export type DoctorScheduleDays = "monday" | "tuesday"| "wednesday"| "thursday"| "friday";

export function DoctorSchedule({
  doctor_id,
  days
}: {
  doctor_id: DoctorKey,
  days: Array<DoctorScheduleDays>
}): ReactNode {
  return (
    <div className="doctorSchedule">
      <Link
        className="noLinkHighlight"
        to={`/${I18n.locale}/team/${doctor_id}`}
        state={{ prevPath: `/${I18n.locale}/contact` }}
      >
        <div className="scheduleTitle">
          <div className="scheduleTitleImgWrapper">
            <img src={Doctors[doctor_id].imgSrc} alt="" />
          </div>
          <div>
            <span>{Doctors[doctor_id].name}</span>
            <span>{I18n.getString(`team.${doctor_id}.subtitle`)}</span>
          </div>
        </div>
      </Link>

      <div className="scheduleSubtitle">
        {I18n.getString(`contact.subtitle.schedule`)}
      </div>
      <table>
        <thead>
          <tr>
            <th>{I18n.getString(`contact.header.day`)}</th>
            <th>{I18n.getString(`contact.header.morning`)}</th>
            <th>{I18n.getString(`contact.header.afternoon`)}</th>
          </tr>
        </thead>
        <tbody>
          {days.map((day: DoctorScheduleDays) => (
            <DoctorScheduleLine
              day={day}
              doctor_id={doctor_id}
              key={`schedule_line_${day}_${doctor_id}`}
            />
          ))}
        </tbody>
      </table>

      <div className="scheduleSubtitle">
        {I18n.getString(`contact.subtitle.appointment`)}
      </div>
      <div className="contactInfo">
        {I18n.getString(`contact.${doctor_id}.appointment`)}
      </div>
    </div>
  );
}

function DoctorScheduleLine({
  day,
  doctor_id,
}: {
  day: string;
  doctor_id: DoctorKey;
}) {
  return (
    <tr>
      <td>{I18n.getString(`contact.${day}`)}</td>
      <td>{I18n.getString(`contact.${doctor_id}.${day}.morning`)}</td>
      <td>{I18n.getString(`contact.${doctor_id}.${day}.afternoon`)}</td>
    </tr>
  );
}
