import React from "react";
import { Link } from "react-router-dom";

// Styles
import "../styles/pages/Home.css";
import "../styles/pages/LanguageSelect.css";

import I18n from "../i18n/i18n";

export default function LanguageSelect() {
  return (
    <div
      style={{
        height: "calc(100vh - var(--navBarHeight))",
        width: "100vw",
        backgroundSize: "cover",
        backgroundPosition: "top right",
        backgroundImage: `url("/images/backgrounds/lang_select.jpg")`,
      }}
    >
      <div className="centerFlex fullSize fullSizeOpacityBoxLight">
        <div>
          <div className="pageTitle">Select a language</div>
          <div>
            {Object.keys(I18n.languages).map((key) => {
              const language = I18n.languages[key];

              return (
                <Link to={`/${key}/home`} key={key} className="languageChoice aFormatCancel">
                  <div className="languageLabel">
                    <div className="languageIcon">{language.icon}</div>{" "}
                    {language.displayName}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
