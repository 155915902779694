import React, { ReactNode } from 'react';

import '../styles/components/TeamMemberIcon.css';

export default function TeamMemberIcon({ name, description, imgSrc }: { name: string, description: string, imgSrc: string }): ReactNode {
    return (
        <div className="teamMember">
            <div className='teamMemberImgWrapper'>
                <img src={imgSrc} alt="acc-logo" className="teamMemberImg" />
            </div>
            <div className="teamMemberInfo">
                <div className="teamMemberName">
                    {name}
                </div>
                <div className="teamMemberDescription">
                    {description}
                </div>
            </div>
        </div>
    );
}
