import React, { useState, ReactNode, SetStateAction, Dispatch } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import NotFound from "./NotFound";

// Components
import TeamMemberIcon from "../components/TeamMemberIcon";
import TeamMemberTitleDisplay from "../components/TeamMemberTitleDisplay";

// SVGs
import { ReactComponent as LeftArrow } from "../images/left_arrow_with_body.svg";

// Style
import "../styles/pages/Home.css";
import "../styles/pages/Team.css";

import I18n from "../i18n/i18n";
import { DoctorKey, Doctors } from "../data_definitions/doctors";

export default function Team() : ReactNode{
  const params = useParams();
  const { state } = useLocation();
  const [selectedMember, setSelectedMember] : [DoctorKey, Dispatch<SetStateAction<DoctorKey>>] = useState<DoctorKey>(params.id as DoctorKey);

  function getReturnPath() {
    if (state != null && "prevPath" in state) {
      return state.prevPath;
    }

    return `/${I18n.locale}/team`;
  }

  if (selectedMember !== undefined && !(selectedMember in Doctors)) {
    return <NotFound />;
  }

  if (selectedMember !== undefined) {
    return (
      <div className="topMostSlider">
        <Link
          to={getReturnPath()}
          key={`back_navlink`}
          onClick={() => {
            setSelectedMember(undefined);
          }}
        >
          <div className="iconWithTextButton rightAlignedButtonBand">
            <div className="iconImg">
              <LeftArrow />
            </div>
            <div className="iconText">
              {I18n.getString(`team.navigation.back`)}
            </div>
          </div>
        </Link>

        <div className="teamMemberDetailsWrapper">
          <div className="inlineBlock">
            <TeamMemberTitleDisplay
              index={selectedMember}
              imgSrc={Doctors[selectedMember as DoctorKey].imgSrc}
              name={Doctors[selectedMember as DoctorKey].name}
              description={I18n.getString(`team.${selectedMember}.subtitle`)}
            />
          </div>
          <div className="teamMemberDetailsDescription">
            {I18n.getString(`team.${selectedMember}.description`)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="scrollableContent">
        <div className="pageTitle">{I18n.getString("team.title")}</div>

        <div className="teamGrid">
          <div className="teamWrapper">
            {Object.keys(Doctors).map((teamMemberKey : DoctorKey, index) => {
              return (
                <Link
                  to={`/${I18n.locale}/team/${teamMemberKey}`}
                  key={`${index}_navlink`}
                  onClick={() => {
                    setSelectedMember(teamMemberKey);
                    window.scrollTo(0, 0);
                  }}
                >
                  <TeamMemberIcon
                    key={index}
                    imgSrc={Doctors[teamMemberKey].imgSrc}
                    name={Doctors[teamMemberKey].name}
                    description={I18n.getString(
                      `team.${teamMemberKey}.subtitle`
                    ) as string}
                  />
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
