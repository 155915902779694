import React from 'react';
import ArticleImage from '../../components/ArticleImage';
import { Link } from 'react-router-dom';

const english = {
    /* 
        NAVIGATION BAR
    */
    "navbar.contact.title": "Contact",
    "navbar.examinations.title": "Examinations",
    "navbar.pathologies.title": "Pathologies",
    "navbar.team.title": "Team",
    "navbar.news.title": "News",
    "navbar.home.title": "Home",

    /* 
        HOMEPAGE
    */
    "home.header.subtitle": "The gastroenterology ward of eastern Belgium",

    "home.cornerstones.title": "Discover our four pillars",

    "home.cornerstones.first.title": "digestion from A to Z",
    "home.cornerstones.first.description": "Specializes in the prevention, diagnosis and treatment of diseases of the stomach, esophagus, intestine, anus, liver, pancreas, gallbladder, bile ducts and obesity.",
    "home.cornerstones.second.title": "multidisciplinary & multi-horizon team",
    "home.cornerstones.second.description": "The gastroenterologists of the St Elisabeth CHC clinic in Verviers, the St Nicolas clinic in Eupen, the Saint Joseph clinic in Saint Vith and the Reine Astrid clinic in Malmédy have pooled their skills and experience to provide a digestive system care that is organized, complete and as a team.",
    "home.cornerstones.third.title": "specialized care",
    "home.cornerstones.third.description": "Our service is characterized by a very specialized care: each member of the service has at least one area of excellence that he shares with the rest of the team.",
    "home.cornerstones.fourth.title": "human nature & trust",
    "home.cornerstones.fourth.description": "Your relationship of trust with your doctor is essential for the effectiveness of your treatment. We therefore favor the continuity of this relationship that you have chosen with a doctor from our team. It is your gastroenterologist who will surround himself with the skills of your general practitioner, his fellow gastroenterologists, surgeons, radiologists, oncologists, internists or others to offer you optimal care that remains personalized and humane.",
    "home.treatment_1": "Our ",
    "home.treatment_2": "care",
    "home.treatment_3": " is most frequently carried out on an outpatient basis (consultations). In the event of a more severe digestive disease or one requiring daily monitoring, hospitalization is organized in our department at one of the four hospital sites. The day clinic is often used to perform endoscopies under anesthetic sedation or infusion treatments, for instance.",
    "home.strengths.title": "Our strengths",
    "home.strengths.first.title": "privileged contact",
    "home.strengths.first.description": "Digestive diseases are increasingly treated by several medical and surgical specialties jointly. Digestive oncology and obesity are two examples of multidisciplinary consultations involving multiple stakeholders. The particularity of our service is that the gastroenterologist you have chosen remains your referent. This allows you to maintain a privileged medical relationship with the doctor of your choice, which remains human despite the sometimes necessary multitude of stakeholders.",
    "home.strengths.second.title": "24/7 care according to needs",
    "home.strengths.second.description": "We are the reference ward for endoscopic emergencies as well as biliary and pancreatic endoscopic examinations for eastern Belgium for 4 of the 5 hospital sites in Verviers and eastern Belgium.",
    "home.strengths.third.title": "state-of-the-art equipment",
    "home.strengths.third.description": "For an accurate diagnosis, endoscopic or functional explorations are often necessary. For this, the department has the best possible equipment: high-resolution endoscopes with electronic coloration, latest generation echo-endoscopes with either axial vision (allowing punctures and endoscopic treatments), or 360° transverse (allows optimal vision of certain anomalies), videocapsule allowing the exploration of the small intestine, high resolution manometry, impedance meters, lactose respiratory tests, ...",
    "home.strengths.fourth.title": "patient safety",
    "home.strengths.fourth.description": <div>
        <h3>Hardware disinfection</h3>
        The department is historically among the first departments in the province of Liège to use single-use biopsy forceps for endoscopic sampling and endoscope washers to disinfect endoscopes. Currently, practically all small endoscopy equipment is single-use.
        <br/><br/>
        The endoscopes are disinfected by high-performance machines and with the most effective existing products.
        <br/><br/>
        Each disinfection is traced from A to Z and disinfection quality controls are regularly carried out using bacteriological samples.
        <br/><br/>
        <h3>Multiple checks</h3>
        Before any endoscopy performed under anesthesia, a checklist is used for each patient, including, among other things, the verification of the patient's identity, the examination to be performed, allergies, the patient's consent, etc.
        <br/><br/>
        <h3>Canada Accreditation</h3>
        A very demanding <a href="https://accreditation.ca/qmentum-accreditation/">accreditation</a> process has been successfully completed at CHC, led by an independent international body. CHC is accredited "Gold" by this organization after aiming at all possible points for patient safety.
    </div>,
    "home.strengths.fifth.title": "digital medical record",
    "home.strengths.fifth.description": <div>
        100% of the medical information of consultations, hospitalizations and technical acts are saved by the department's doctors in this database. This allows statistical checks, cross-referencing of information and thus improves the quality of care in our ward.
        <br/><br/>
        In addition, international consensus on the treatment of a disease or the performance of an examination can be integrated without delay into the database, since it is managed solely by the doctors in the department.
    </div>,
    "home.strengths.sixth.title": "consistency between hyperspecializations",
    "home.strengths.sixth.description": <div>
        Gastroenterologists each have at least one area of hyperspecialization, covering all digestive pathologies (cancerology, liver disease, Crohn's disease, ulcerative-hemorrhagic rectocolitis, pancreas, gallbladder, proctology, reflux and esophagitis, colonic polyps, obesity, ...).
        <br/><br/>
        As much as possible, these hyperspecializations are grouped by doctor according to the patient's best interests.
        <br/><br/>
        For instance, the same doctors perform echo-endoscopies and ERCPs, thus allowing the detection of a stone in the bile duct (echo-endoscopy) and the endoscopic extraction of this stone (ERCP) to take place during a single anesthesia. Another example, the doctor who is trained in anorectal manometry also practices anorectal echo-endoscopy, allowing the patient to perform these troublesome examinations in a single appointment and with a single person.
    </div>,

    /* 
        NEWS
    */
    "news.published_on": "Published on",
    "news.covid_19.contents": <div>
        Although the situation is improving, the virus is still circulating and our patients, your loved ones, are a vulnerable audience.
        <br/><br/>
        Some precautions therefore remain in use:
        <ul style={{listStyleType: "circle"}}>
        <li>wearing a mask is compulsory inside the hospital;</li>
        <li>avoid coming if you show signs of COVID-19, or if you have been in close contact with a positive person.</li>
        </ul>
    </div>,
    "news.covid_19.title": "COVID 19",

    /*
        TEAM
    */
    "team.title": "Our team",
    "team.navigation.back": "Back",

    "team.olivier_cajot.subtitle": "Gastroenterologist",
    "team.olivier_cajot.description": <div>
        <h2>
        Interests
        </h2>
        <ul>
            <li>Pancreas and bile ducts</li>
            <li>Inflammatory bowel diseases (UCL training and CHU Lille reference center)</li>
            <li>Echo-endoscopy (experience of more than 5,000 examinations) and CPRE, interventional endoscopy (multiple expert training centers: Lille, Paris, Brussels, Liège and Charleroi)</li>
            <li>Nutrition</li>
        </ul>
        <h2>
        Diplomas
        </h2>
        <ul>
            <li>Doctor of Medicine 1992 (UCL)</li>
            <li>Specialization in gastroenterology 1998 (UCL)</li>
            <li>University diploma in Radiation protection in 2017 (ULG)</li>
            <li>University diploma in inflammatory bowel disease 2020 (Sorbonne, Paris)</li>
        </ul>
        <h2>
        Societies
        </h2>
        <ul>
            <li>Member of the Belgian Inflammatory bowel disease Research and Development</li>
            <li>Member of the European Pancreatic Club</li>
        </ul>
    </div>,
    "team.maxime_seivert.subtitle": "Gastroenterologist",
    "team.maxime_seivert.description": <div>
        I graduated as a gastroenterologist at ULg in 2014. I am one of the latest arrivals in the gastroenterology team at St Elisabeth in Heusy and the youngest doctor on the team. Young dad, dynamic and spontaneous, I quickly joined the St Elisabeth team.
        <br/><br/>
        Trained in interventional endoscopy at UCL with Pr Deprez and practicing abdominal ultrasound, echo-endoscopy as well as retrograde cholangiography, I am particularly interested in bilio-pancreatic pathology.
        <br/><br/>
        Although mainly an endoscopist, I obviously remain a general gastroenterologist with a particular interest in inflammatory intestinal pathologies after a period of training, notably in the departments of Pr Louis at ULg and Pr Dewit at UCL.
    </div>,
    "team.virginie_jadot.subtitle": "Gastroenterologist",
    "team.virginie_jadot.description": <div>
        <h2>
        Interests
        </h2>
        <ul>
            <li>Hepatology (Liège and Lille University Hospital)</li>
            <li>General gastroenterology (Liège)</li>
            <li>Abdominal ultrasound (in progress)</li>
        </ul>
        <h2>
            Diplomas
        </h2>
        <ul>
            <li>Doctor of Medicine (ULG 2013)</li>
            <li>Specialization in Gastroenterology (ULG 2019)</li>
            <li>University diploma in inflammatory bowel diseases (Lille 2020)</li>
            <li>University degree in dysmetabolic steatohepatitis/NASH (Angers 2021)</li>
        </ul>
    </div>,
    "team.alexandre_clotuche.subtitle": "Hospital Physician",
    "team.alexandre_clotuche.description": <div>
        My name is Alexandre Clotuche, and I am a trained general practitioner.<br/><br/>
        Wanting to give a new impetus to my career, I became a <b>hospital physician</b>.<br/>
        What does this involve?<br/><br/>
        I am responsible for hospitalized patients in the gastroenterology and oncology department, from admission to discharge, working closely with various specialist doctors as well as the patient's general practitioner and family.<br/><br/>
        My work involves making diagnoses, developing treatment plans, organizing additional tests, and providing follow-up care after hospitalization with the gastroenterologist and/or oncologist.
    </div>,
    "team.donald_vermer.subtitle": "Gastroenterologist",
    "team.donald_vermer.description": <div>
        Dear patient,<br/><br/>
        With over 15 years of experience in the field, and a graduate from the University of Louvain (UCL), I am available to provide consultation services to you.<br/><br/>
        Medicine is an art, the art of healing, relieving, and curing.<br/><br/>
        The patient needs to be listened to and treated comprehensively.<br/><br/>
        I have a holistic approach to patient care, although sometimes it is necessary to focus on a specific organ or condition in order to effectively address the patient's complaints.<br/><br/>
        The digestive system is vast; while I handle various digestive issues, ranging from gastroesophageal reflux to hemorrhoidal pathology, I have chosen to specialize in digestive endoscopy and the management of inflammatory bowel diseases (Lille inter-university diploma on Crohn's disease and UC).
    </div>,
    "team.ann_christine_dupin.subtitle": "Gastroenterologist",
    "team.ann_christine_dupin.description": <div>
        <h2>
            Education
        </h2>
        <ul>
            <li><b>2003:</b> SGR Baccalaureate (Rheinbach, Germany)</li>
            <li><b>2004-2012:</b> Medical studies at the University of Liège</li>
            <li><b>6/2012:</b> Doctor of Medicine degree with honors</li>
        </ul>

        <h2>
            Professional Experience
        </h2>
        <ul>
            <li><b>2003-2004:</b> Nursing training and internships at Marienhospital Euskirchen (Germany)</li>
            <li><b>2010-2012:</b> 3rd and 4th year medical intern at the University Hospital of Liège</li>
            <li><b>October-November 2011:</b> Humanitarian mission for the NGO "Femme SIDA" in Lubumbashi (Democratic Republic of Congo)</li>
            <li><b>2012-2015:</b> Assistant Physician in Internal Medicine (common trunk of complementary Master's degree in Gastroenterology) at St Nikolaus Hospital in Eupen</li>
            <li><b>2015:</b> Assistant Physician in Internal Medicine at HUG (Geneva, Switzerland)</li>
            <li><b>2016-2019:</b> Assistant Physician in the gastroenterology departments at CHR Verviers (1st year of specialized training), University Hospital of Liège, and CHC Liège</li>
            <li><b>Since October 2019:</b> Gastroenterologist at CHC (Heusy, Eupen, and St. Vith sites)</li>
        </ul>

        <h2>
            Publications and Presentations
        </h2>
        <ul>
            <li><b>2/2018:</b> Belgian Weeks of Gastroenterology, "The Duplicated Pancreas"</li>
            <li><b>2021:</b> Publication of the Case Report: "A rare cause of epigastric pain: Splenic artery dissection and aneurysm of celiac trunk in the Ehlers-Danlos syndrome type IV"</li>
        </ul>

        <h2>
            Languages
        </h2>
        <ul>
            <li><b>French-German:</b> Bilingual</li>
            <li><b>English:</b> Fluent</li>
        </ul>

        <h2>
            Interests
        </h2>
        Traveling, Reading, Athletics
    </div>,
    "team.matthias_dietze.subtitle": "Gastroenterologist",
    "team.matthias_dietze.description": <div>
        <h2>Academic Background</h2>
        <ul>
            <li>1990-1992 Medical studies at the University of Leipzig (Germany)</li>
            <li>1992-1993 Medical studies at the University of Berlin (Germany)</li>
            <li>1993-1997 Medical studies at the University of Rennes I (France)</li>
        </ul>

        <h2>Professional Career</h2>
        <ul>
            <li>1997-1999 General Practitioner at Gérardmer Hospital (France)</li>
            <li>1999-2004 Assistant in Gastroenterology at the University of Liège</li>
            <li>Since 2004 Private practice in St-Vith</li>
            <li>2004-2021 Gastroenterologist at Clinique Ste-Elisabeth in Verviers</li>
            <li>Since 2011 Gastroenterologist at Clinique St-Joseph in St-Vith</li>
            <li>2014-2015 Private practice in Wemperhardt (Grand Duchy)</li>
            <li>2014-2015 Physician at CH du Nord in Wiltz (Grand Duchy)</li>
        </ul>

        <h2>Qualifications</h2>
        <ul>
            <li>1999 Medical License (Health Administration of the State of Berlin)</li>
            <li>2004 Authorization to practice Gastroenterology (Belgium)</li>
            <li>2004 Diploma in "Viral Hepatitis" (University of Paris VI)</li>
            <li>2010 Diploma in "Nutrition in Gastroenterology" (German Association for Metabolic and Digestive Diseases (DGVS))</li>
            <li>2012 Diploma in "Hepatology" (German Association for Metabolic and Digestive Diseases (DGVS))</li>
            <li>2016 Diploma in "Chronic Intestinal Diseases" (German Association for Metabolic and Digestive Diseases (DGVS))</li>
        </ul>

        <h2>Main Techniques</h2>
        Gastroscopies, colonoscopies, placement of gastric balloons and gastrostomies, liver biopsies, abdominal ultrasounds
    </div>,
    "team.nadia_torres.subtitle": "Gastroenterologist",
    "team.nadia_torres.description": <div>
        <h2>Areas of Interest</h2>
        <ul>
            <li>Pancreas and Biliary Tract</li>
            <li>Echo-endoscopy and ERCP</li>
            <li>Interventional Endoscopy</li>
            <li>Hepatology</li>
        </ul>

        <h2>Degrees</h2>
        <ul>
            <li>Doctor of Medicine in 2016 (ULg)</li>
            <li>Specialization in Gastroenterology in 2022 (ULg), with the last year of training at Mont-Godinne (UCL)</li>
            <li>University Degree in Radiation Protection in 2023 (ULg)</li>
        </ul>

        <h2>Professional Societies</h2>
        <ul>
            <li>Member of the SFED (French Society of Digestive Endoscopy)</li>
            <li>Member of the CFE (French-speaking Club of Echo-endoscopy)</li>
            <li>Member of the ESGE (European Society of Gastro-intestinal Endoscopy)</li>
            <li>Member of the EASL (European Association for the Study of the Liver)</li>
            <li>Member of the SRBGE (Royal Belgian Society of Gastroenterology)</li>
        </ul>
    </div>,
    "team.astrid_denis.subtitle": "Gastroenterologist",
    "team.astrid_denis.description": <div>
        <h2>Areas of Interest</h2>
        <ul>
            <li>General Gastroenterology</li>
            <li>Functional Esophageal Pathology (Esophageal manometry, pH impedance)</li>
            <li>Capsule Endoscopy</li>
        </ul>
    
        <h2>Degrees</h2>
        <ul>
            <li>Doctor of Medicine in 2000 (ULg)</li>
            <li>Specialization in Gastroenterology in 2005 (ULg)</li>
            <li>Inter-University Diploma in Functional Digestive Pathology in 2018 (Lyon)</li>
        </ul>
    </div>,
    

    /*
        PATHOLOGIES
    */
    "pathologies.home.title": "Explore the pathologies in which we specialize.",
    "pathologies.home.subtitle": "Select a pathology in the navigation panel on the left to get started.",
    "pathologies.general.title": "General",

    "pathologies.cancer.title": "Cancer",
    "pathologies.cancer.content": <div>
        <h2>
        General presentation
        </h2>

        A cancerous disease is the abnormal division and multiplication of a cell in the human body. These abnormal cells group together in a tumorous mass that grows and invades the organ in which it developed to gradually disrupt its normal functioning.
        <br/><br/>
        If the tumor mass infiltrates a blood vessel, abnormal cells pass into the circulation and attach themselves to other organs (liver, lungs, brain). They develop there and also block its normal functioning, this is called metastases.
        Without proper treatment, a cancerous disease is often fatal for the patient.
        <br/><br/>
        Any cell in the human body has the possibility of presenting a cancerous transformation. For some organs, such a transformation is frequent (prostate, breast, colon, lung) For other organs, this development is rare or even exceptional (small intestine, muscle, bone, heart)
        <br/><br/>
        The gastroenterologist is the doctor responsible for detecting cancers of the esophagus, stomach, liver, bile ducts, pancreas, small intestine (small intestine), large intestine (colon and rectum) and canal. anal.
        <br/><br/>
        The specialists dealing with the treatment of cancerous diseases at St Elisabeth are the oncolgues: Dr Gauthier Demolin and Dr Geoffrey Matus.

        <h2>
        Risk factors
        </h2>
        Different factors influence the appearance of a cancerous disease:
        <ul style={{listStyleType: "circle"}}>
        <li><b>age:</b> cancer is more common in people over 50 and the risk increases with age;</li>
        <li><b>heredity:</b> certain genes transmitted from generation to generation predispose to the appearance of cancer in members of the same family, for example in the breast or the colon;</li>
        <li><b>precancerous lesions:</b> In certain organs such as the colon, skin or bladder, the appearance of cancer begins with small precancerous lesions often present years before the cancer. These lesions do not hurt and can remain unknown for years, sometimes until they transform into tumors. This is the case, for example, of moles on the skin, colon polyps or certain pancreatic cysts. These precancerous lesions can regularly be detected by screening examinations;</li>
        <li><b>tobacco:</b> main risk factor for lung cancer. Associated with alcohol, it promotes the appearance of diseases of the mouth, throat and esophagus. Alcohol and tobacco also predispose to a disease of the pancreas (chronic pancreatitis) which can degenerate into cancer;</li>
        <li><b>lifestyle:</b> the quality of the food we eat (e.g. overcooked animal fats), air pollution, certain occupational chemicals we swallow or breathe in, have an impact on the development of cancer;</li>
        <li><b>radiation (radioactivity):</b> chronic exposure to ionizing radiation also promotes the development of cancer. The source of its rays can be natural (certain rocks), accidental (nuclear accident), professional (factories) and sometimes medical (significant excess of radiological examinations and radiotherapy);</li>
        <li><b>drugs:</b> certain drugs used to cure serious illnesses may exceptionally be responsible for the appearance of secondary cancer.</li>
        </ul>
    </div>,

    "pathologies.alimentary_canal.title": "Digestive tract",
    
    "pathologies.barrett.title": "Barrett",
    "pathologies.barrett.content": <div>
        This is the transformation of the esophageal mucosa into gastric mucosa, stronger to resist the aggressions of gastric secretions which contribute to reflux.
        <br/><br/>
        This phenomenon occurs over time when abnormal gastroesophageal reflux persists for several years.
        <br/><br/>
        Screening for Barrett is important in at-risk patients; reflux complaints should not be minimized and deserve at least a specialist consultation.
        <br/><br/>
        The treatment of Barrett's esophagus requires comprehensive care of the patient both in terms of medication and nutrition; this approach should be discussed with your treating physician and gastroenterologist specialist.
    </div>,
    "pathologies.reflux.title": "Gastroesophageal Reflux Disease",
    "pathologies.reflux.content": <div>
        It is, by definition, the rise of secretions from the stomach to the esophagus: associated gastric and/or bile fluid flows back from the gastric cavity down the esophageal duct.
        <br/><br/>
        This phenomenon can be considered normal, that is to say, physiological, in certain circumstances, especially after a heavy meal. The latter can cause acid, sour, usually transient, but annoying, felt as discomfort in the chest or a bitter taste in the mouth.
        <br/><br/>
        Gastroesophageal reflux is considered abnormal when it impairs quality of life; frequent or intense regurgitation can disturb digestion on a daily basis, during the day and/or at night.
        <br/><br/>
        The symptoms can be varied and affect the digestive sphere, ENT, cardiac, respiratory in the form in particular of palpitations, burning; these must be evaluated in consultation with gastroenterology.
        <br/><br/>
        During the visit to the specialist, the doctor will offer you the most suitable development and will advise you on the treatment, both in terms of medication and nutrition.
    </div>,
    "pathologies.colorectal_cancer.title": "Colorectal cancer (CRC)",
    "pathologies.colorectal_cancer.content": <div>
        Colorectal cancer (CRC) is a major public health problem. This disease is particularly common in people over the age of 50. It is the 3rd most common cancer in men after that of the prostate and that of the lung and the second in women after that of the breast.
        <br/><br/>
        +/- 5000 new cases of CRC are reported per year in Belgium.
        <br/><br/>
        Discovered at an early stage, CRC is curable in 90% of cases. If discovered late, at a metastatic stage, the prognosis is much more reserved. The treatment of colorectal cancer depends on the stage of the disease at its diagnosis and can combine surgery, chemotherapy and radiotherapy.
        <br/><br/>
        The majority of colorectal cancers develop from small precancerous lesions, called polyps or adenomas, present years before the cancer appears.
        <br/><br/>
        The patient with a polyp usually has no symptoms. Early detection of these precancerous lesions and their resection can prevent the onset of cancer. This is the purpose of colorectal cancer screening.
        <br/><br/>

        <ArticleImage
            src={"/images/pathologies/polype_colon.jpg"}
            caption={<div>
                Colon polyp<br/>
                Source: <a href="https://www.gastrocochin.com/endoscopy-colonoscopy">https://www.gastrocochin.com/endoscopy-colonoscopy</a>
            </div>}/>

        <ArticleImage
            src={"/images/pathologies/cancer_colon.jpg"}
            caption={<div>
                Colon cancer<br/>
                Source: <a href="https://www.researchgate.net">https://www.researchgate.net</a>
            </div>}/>

        <h2>
        How to detect colorectal cancer?
        </h2>
        Screening for colorectal cancer is intended for <b>asymptomatic</b> patients over the age of 50. It consists of the realization of a stool sample by the patient at home. This sample will then be analyzed in the laboratory to look for the presence of microscopic traces of blood. The presence of blood in the stool can be associated with the presence of polyps or even cancer.
        This test is easy, quick and inexpensive and can be performed at your GP. A positive screening test in a patient over 50 requires a consultation with the gastroenterologist and the performance of a colonoscopy.
        <br/><br/>
        <em>
        In the event of a personal or family history of colorectal cancer or suspicious symptoms, the screening test by stool analysis is not indicated and the patient must immediately consult a gastroenterologist.
        </em>
        <br/><br/>
        <h2>
        What symptoms may suggest the presence of colorectal cancer or a polyp of significant size?
        </h2>
        If CRC is present, the patient may present with different symptoms:
        <ul style={{listStyleType: "circle"}}>
        <li>abdominal pain,</li>
        <li>weight loss,</li>
        <li>loss of appetite,</li>
        <li>unexplained fatigue,</li>
        <li>blood loss in the stool,</li>
        <li>a change in transit: appearance of constipation or diarrheal stools without explanation,</li>
        <li>emergencies to have a bowel movement,</li>
        <li>false need to have a bowel movement,</li>
        <li>the presence of anemia and lack of iron in a blood test.</li>
        </ul>
        <br/><br/>
        Depending on their age, personal and family history, and the date of the last colonic examinations performed, a patient with these symptoms will be offered a colonoscopy.
    </div>,
    "pathologies.crohn.title": "Crohn's disease",
    "pathologies.crohn.content": <div>
        Crohn's disease is a chronic inflammation of the digestive system, which progresses through flare-ups (or crises) and phases of remission. It is mainly characterized by abdominal pain and diarrhea, lasting a few weeks to a few months. Fatigue, weight loss and even malnutrition can occur if no treatment is undertaken. In some cases, non-digestive symptoms, which affect the skin, joints, liver or eyes may be associated with the disease.
        <br/><br/>
        The inflammation in this disease can affect any segment of the digestive tract, from the mouth to the anus. But most often, it settles at the junction of the small intestine and the colon (large intestine).
        <br/><br/>
        The <b>causes</b> of this disease are unknown and likely multiple, involving genetic, autoimmune and environmental factors.

        <h3>
        Genetic factors
        </h3>
        Some genes increase the risk of developing the disease, but other factors are necessary for the disease to occur. As in many other diseases, it seems that a genetic predisposition combined with environmental or lifestyle factors triggers the disease.
       
        <h3>
        Autoimmune factors
        </h3>
        Crohn's disease has characteristics of an autoimmune disease. Scientists believe that inflammation of the digestive tract is linked to an excessive immune reaction of the body against viruses or bacteria present in the intestine.

        <h3>
        Environmental factors
        </h3>
        Crohn's disease is more common in industrialized countries. This suggests that environmental factors, probably related to Western lifestyle, could influence the onset of the disease. Smoking increases the risk of disease flare-ups. The sedentary lifestyle could also influence.
        <br/><br/>
        It is possible (no absolute proof) that eating too much meat and sugar increases the risk. The mental state could also have an influence on the occurrence of relapses.
        <br/><br/>
        The imbalance of the intestinal flora (i.e. bacteria naturally present in the digestive tract) could also be the cause of the disease.
   
        <h2>
        Evolution
        </h2>
        It is a chronic disease that is present throughout life. Most often, Crohn's disease evolves in flare-ups interspersed with periods of remission of very variable duration. Recurrences (or crises) follow one another in a rather unpredictable way and are of variable intensity.
        <br/><br/>
        Some Crohn's diseases are relatively mild and with widely spaced attacks. Others are unfortunately more severe with more frequent attacks. There are therefore several possible types of this disease.
       
        <h2>
        Supported
        </h2>
        Optimal medical monitoring is essential in this disease and many treatments are possible. Our service offers personalized care: the doctor-patient relationship of trust is more essential than ever in this care and allows treatment that is truly adapted to the person with this disease.
        <br/><br/>
        Several members of the team are specialized in this disease and share their advice and experience. In addition, collaboration with other specialties is naturally set up according to the patient's needs (surgery, radiology, dietetics, anatomopathology, tobacco, psychology, etc.)
        <br/><br/>
        Our service maintains privileged links with recognized international reference centers, which allows the patient to benefit if necessary from new treatments under study in collaboration with these renowned centers.
        <br/><br/>
        In order to improve one's own management of this type of chronic disease, it is sometimes useful to join a patient association.
        <br/><br/>
        Useful link: <a href="http://www.mici.be/">http://www.mici.be/</a>
    </div>,
    "pathologies.ulcerative_colitis.title": "Ulcerative colitis",
    "pathologies.ulcerative_colitis.content": <div>
        Ulcerative colitis is a chronic inflammatory bowel disease (IBD) of the colon and rectum. Unlike Crohn's disease, which can affect any segment of the digestive tract, ulcerative colitis is limited to the colon and rectum.
        <br/><br/>
        The rectum is always affected and depending on the forms of RCUH, a variable length of colon is affected by the disease. In some cases, non-digestive symptoms, which affect the skin, joints, liver or eyes may be associated with the disease.
        <br/><br/><br/>
        The <b>causes</b> of this disease are unknown and likely multiple, involving genetic, autoimmune and environmental factors.
        <br/><br/>
        It is an inflammatory disease with a complex mechanism. It is not a contagious disease and it is not directly due to infection by a germ or virus. The imbalance of the intestinal flora (i.e. bacteria naturally present in the digestive tract) could also be the cause of the disease.
        <br/><br/>
        Stress and food intolerances can trigger symptoms in some people, but these factors are not the cause of the disease.
       
        <h2>Evolution</h2>
        It is a chronic disease that evolves in flare-ups separated by more or less long periods of remission.
        <br/><br/>
        Forms limited to the rectum are less severe than forms including the entire colon.
        <br/><br/>
        There is a very serious form with the risk of perforation of the colon, which may require urgent surgery.

        <h2>Support</h2>
        Optimal medical monitoring is essential in this disease and many treatments are possible. Our service offers personalized care: the doctor-patient relationship of trust is more essential than ever in this care and allows treatment that is truly adapted to the person with this disease.
        <br/><br/>
        Several members of the team are specialized in this disease and share their advice and experience. In addition, collaboration with other specialties is naturally set up according to the patient's needs (surgery, radiology, dietetics, anatomopathology, etc.)
        <br/><br/>
        If necessary, our service maintains privileged links with recognized international reference centers, which allows the patient to benefit from new treatments under study in collaboration with these renowned centers.
        <br/><br/>
        In order to improve one's own management of this type of chronic disease, it is sometimes useful to join a patient association.
        <br/><br/>
        Useful link: <a href="http://www.mici.be/">http://www.mici.be/</a>
    </div>,

    "pathologies.liver.title": "Liver",

    "pathologies.viral_hepatitis.title": "Viral hepatitis",
    "pathologies.viral_hepatitis.content": <div>
        The term hepatitis refers to any inflammatory process in the liver. The term chronic hepatitis refers to progressive inflammation of the liver that lasts for more than six months. Below these six months, the process is considered to be acute.
        Viral hepatitis is inflammation of the liver caused by infection with a virus. There are different types of viruses that can inflame the liver:
        <ol type='a'>
        <li>non-specific liver viruses (e.g. CMV, EBV, …) and </li>
        <li>specific liver viruses (hepatitis A, B, C, D, E viruses).</li>
        </ol>

        <h2>
        Hepatitis A
        </h2>
        <h3>
        Summary
        </h3>
        Hepatitis A is a viral liver disease whose evolution is most often benign but can be serious in some cases.
        The hepatitis A virus is transmitted by ingesting contaminated water or food or by direct contact with an infected person.
        Almost everyone is completely cured of hepatitis A by being immune for the rest of their lives. However, a very small proportion of infected people die of fulminant hepatitis.
        The virus is mainly spread when an uninfected (or unvaccinated) person ingests water or food contaminated with the feces of an infected person. In the family setting, this can occur when an infected person prepares food for family members with dirty hands. The virus can also be transmitted through close physical contact with an infected person, but it is not spread through ordinary person-to-person contact.
        There is a safe and effective vaccine for the prevention of hepatitis A.
        <br/><br/>
        Unlike hepatitis B and hepatitis C, hepatitis A does not cause chronic liver disease.

        <h3>
        Symptoms
        </h3>
        The incubation period for hepatitis A is usually 14 to 28 days.
        The symptoms of hepatitis A can be mild or severe: fever, poor general condition, loss of appetite, diarrhea, nausea, abdominal discomfort, dark urine and jaundice (yellow color of skin and whites of the eyes). Not all infected people have all of these symptoms.
        Adults show symptoms of the disease more often than children

        <h3>
        Who are the people at risk?
        </h3>
        Anyone who has never been infected or vaccinated can get hepatitis A.

        <h3>
        Treatment
        </h3>
        There is no specific treatment for hepatitis A. Recovery from symptoms following infection can be slow and take several weeks or months. The most important thing is to avoid unnecessary medications. Do not prescribe acetaminophen/paracetamol or antiemetics (drugs against vomiting).
        It is not useful to hospitalize the patient if he does not present with acute hepatitis insufficiency. The main aim of the treatment is to maintain a certain comfort and a good nutritional balance, in particular to replace fluid losses due to vomiting and diarrhoea.

        <h3>
        Prevention
        </h3>
        The spread of hepatitis A can be limited by:
        good personal hygiene, including washing hands regularly with clean water.


        <h3>
        Vaccination
        </h3>
        Several inactivated injectable hepatitis A vaccines are available on the international market. All are comparable in terms of protection against the virus and side effects. No vaccine is licensed for children under one year of age.
        Worldwide, millions of people have received the injectable inactivated hepatitis A vaccine without experiencing serious adverse events.

        Some countries also recommend vaccinating people at increased risk of hepatitis A, including:
        <ul style={{listStyleType: "circle"}}>
            <li>travellers to countries where the virus is endemic;</li>
            <li>men who have sex with men;</li>
            <li>recreational drug users.</li>
            <li>people with chronic liver disease (due to increased risk of serious complications if they contract HAV).</li>
        </ul>


        <h2>
        Hepatitis B
        </h2>

        <h3>
        Summary
        </h3>
        Hepatitis B is a viral infection that attacks the liver. It can cause acute or chronic illnesses. The chronic form exposes patients to a significant risk of death from cirrhosis and liver cancer.
        The virus is spread through contact with blood or other bodily fluids from an infected person.
        Hepatitis B is a significant occupational hazard for healthcare workers.
        Hepatitis B can be prevented with the safe and effective vaccine currently available. This vaccine is 95% effective in preventing infection and the development of chronic hepatitis and liver cancer due to hepatitis B.

        <h3>
        Transmission
        </h3>
        The hepatitis B virus can survive outside the body for at least 7 days. During this time, the virus is still likely to cause infection if it enters the body of a person not protected by vaccination. The incubation period for hepatitis B is 75 days on average, but can vary from 30 to 180 days. The virus is detectable 30 to 60 days after infection and can persist in the body to give chronic hepatitis B.

        In areas of high endemicity (Africa, Asia), the most common modes of spread of hepatitis B are perinatal transmission (from mother to child) and horizontal transmission (exposure to infected blood), especially between a infected child and one uninfected child during the first 5 years of life. The onset of chronic infection is very common in infants infected by their mother or before the age of 5 years.
        Hepatitis B is also spread by percutaneous exposure or through mucous membranes, and through saliva, menstrual discharge, or vaginal and seminal secretions. Sexual transmission of hepatitis B can also occur, particularly in unvaccinated men who have sex with men and in heterosexual people with multiple partners or contact with sex workers.
        In adulthood, the infection leads to chronic hepatitis in less than 5% of cases. The virus can also be transmitted through the reuse of needles or syringes in healthcare settings or among people who inject drugs. Also, infection can occur during medical, surgical or dental procedures, tattoos or when using razors or similar objects contaminated with infected blood.

        <h3>
        Symptoms
        </h3>
        In most cases, no symptoms occur during the acute phase of the infection. However, some people have an acute illness with symptoms that last for several weeks, including yellowing of the skin and eyes (jaundice), dark colored urine, extreme fatigue, nausea, vomiting, and abdominal pain.
        A small number of people with acute hepatitis will develop acute liver failure that can lead to death.
        In some people, the hepatitis B virus can cause a chronic liver infection that can later progress to liver cirrhosis or liver cancer.

        <h3>
        HBV-HIV co-infection
        </h3>
        About 1% of people infected with HBV (2.7 million people) also have HIV. Conversely, the worldwide prevalence of HBV infection in HIV-infected individuals is 7.4%.

        <h3>
        Diagnostic
        </h3>
        It is not possible to distinguish hepatitis B from hepatitis caused by other viral agents clinically, so laboratory confirmation of the diagnosis is essential. Several blood tests are available to diagnose and monitor people with hepatitis B. They can also be used to differentiate acute from chronic infections.

        <h3>
        Treatment
        </h3>
        There is no specific treatment for acute hepatitis B. The care aims to preserve the comfort of the patient and the nutritional balance, with in particular a fluid substitution in the event of vomiting and diarrhoea.
        Chronic hepatitis B can be treated with medication, including oral antiviral agents. Such treatment can slow the progression of cirrhosis, reduce the incidence of liver cancers and improve long-term survival.
        <br/><br/>
        Among the long-term complications of HBV infections, cirrhosis and hepatocellular carcinoma are responsible for a significant burden of disease.

        <h3>
        Prevention
        </h3>
        The hepatitis B vaccine is the key to preventing this disease. WHO recommends giving this vaccine to all infants as soon as possible after birth. The low incidence of chronic HBV infection in children under 5 years of age can be attributed to the widespread use of the hepatitis B vaccine.
        Worldwide, the prevalence of this infection in this age group was estimated at 1.3% in 2015, compared to 4.7% before the advent of vaccination. The birth dose should be followed by 2-3 more doses to complete the first vaccination series.
        <br/><br/>
        In countries where endemicity is low or medium, all children and adolescents under 18 who have not yet been vaccinated should be vaccinated. In such settings, it is possible that more members of high-risk groups will become infected and therefore these groups will also need to be vaccinated. In this case it is:
        <ul style={{listStyleType: "circle"}}>
            <li>people who need blood or blood products frequently, on dialysis and recipients of solid organ transplants;</li>
            <li>detainees;</li>
            <li>injecting drug users;</li>
            <li>people who have sex with individuals chronically affected by HBV;</li>
            <li>people with multiple sex partners:</li>
            <li>health care personnel and persons at risk of occupational exposure to blood and blood products; and</li>
            <li>Travellers who have not been fully vaccinated should be offered the vaccine before traveling to an endemic area.</li>
        </ul>
        The vaccine has historically demonstrated remarkable safety and efficacy. Since 1982, more than one billion doses of hepatitis B vaccine have been administered worldwide.

        <h2>
        Hepatitis C
        </h2>
        Hepatitis C is a liver disease caused by a virus. The hepatitis C virus can cause both acute and chronic liver infection, which vary in severity, ranging from a mild form that lasts a few weeks to a severe illness that lasts a lifetime.
        The hepatitis C virus is transmitted through blood and the most common modes of infection result from exposure to small amounts of blood, occurring through injection drug use, unsafe injections, healthcare at risk and the transfusion of blood or derivative products for which there has been no screening.
        Worldwide, approximately 71 million people are chronic carriers of hepatitis C.
        For a significant number of people with the chronic form of the disease, the infection progresses to cirrhosis or liver cancer.
        About 399,000 people die of hepatitis C each year, mostly from cirrhosis or hepatocellular carcinoma.
        Antiviral drugs can cure more than 95% of people infected with the hepatitis C virus, reducing the risk of death from liver cancer or cirrhosis.
        In Belgium, 50% of infected people do not know each other. It is therefore essential to screen those at risk:
        Current treatments are short treatments (8 to 12 weeks), with very few side effects (headaches, fatigue, insomnia) and very effective (95 to 100% viral eradication).
        Currently, there is no vaccine for hepatitis C.

        <h3>
        Symptoms
        </h3>
        As with other hepatitis, the symptoms are non-specific. With chronic infection, the most common symptom is fatigue.

        <h3>
        Transmission
        </h3>
        It is done by blood.


        <h3>
        Diagnostic
        </h3>
        The diagnosis is made by a blood test:
        <ol>
            <li>Antibodies (screening)</li>
            <li>Research for viral RNA by PCR (diagnostic)</li>
        </ol>

        <h3>
        Vaccination
        </h3>
        There is no vaccination for this virus.

        <h3>
        Treatment
        </h3>
        The treatments are very effective (over 95% effective with eradication of the virus). They are ongoing (8 to 12 weeks). They have few side effects (fatigue, headaches, insomnia).

        <h3>
        Screening
        </h3>
        50% of infected patients are unaware of themselves. It is essential to optimize screening in at-risk groups

        <h3>
        Risk groups
        </h3>
        <ul style={{listStyleType: "circle"}}>
            <li>injecting drug users;</li>
            <li>nasal drug users;</li>
            <li>people who received infected blood products or who were subjected to invasive examinations in healthcare establishments with poor infection control practices or before 1970;</li>
            <li>children born to mothers infected with HCV;</li>
            <li>people whose sexual partners are infected with HCV;</li>
            <li>people with HIV infection;</li>
            <li>prisoners or people who have been incarcerated in the past; and</li>
            <li>people who are tattooed or wear piercings.</li>
        </ul>

        <h3>
        Advice in case of infection to avoid contamination
        </h3>
        Do not share nail scissors, nail clippers, toothbrushes, hairbrushes, combs, razors, etc.

        <h2>
        Hepatitis Delta
        </h2>
        Only exists if the patient is infected with the hepatitis B virus.


        <h2>
        Hepatitis E
        </h2>
        Hepatitis E is a liver disease caused by infection with a virus, the hepatitis E virus (HEV).
        The hepatitis E virus is transmitted by the faecal-oral route, mainly through contaminated water or after eating undercooked meat (pork, wild boar, deer) or shellfish.
        Hepatitis E occurs worldwide, but its prevalence is highest in East and South Asia.
        The infection is usually acute and inconsequential but can sometimes lead to fulminant hepatitis. In the event of immunosuppression, the infection may become chronic and require drug treatment with Ribavirin.
        In some cases, there may be extra-hepatic (eg neurological) manifestations.
    </div>,
    "pathologies.hepatitis_misc.title": "Miscellaneous hepatitis",
    "pathologies.hepatitis_misc.content": <div>
        There are other causes of hepatitis such as hepatitis related to drug toxicity.
    </div>,
    "pathologies.hepatitis_alc.title": "Alcoholic hepatitis",
    "pathologies.hepatitis_alc.content": <div>
        Chronic alcohol intake can lead to hepatitis (inflammation of the liver) resulting in abnormal liver tests. It can lead to liver fibrosis which, if severe, corresponds to cirrhosis.
    </div>,
    "pathologies.nafld.title": "Non Alcoholic Fatty Liver Disease",
    "pathologies.nafld.content": <div>
        Non Alcoholic Fatty Liver Disease (NAFLD) is the excessive accumulation of fat in liver cells unrelated to excessive alcohol consumption.
        <br/><br/>
        This excess fat in the liver can lead to inflammation (non-alcoholic steatohepatitis or NASH) and progress to liver fibrosis (“scarring”).
        <br/><br/>
        It is the leading cause of chronic liver disease, affecting 20-30% of the world's population alongside the epidemic of obesity and diabetes.

        <h2>
            Risk factors
        </h2>

        <h3>Age and sex</h3>

        The prevalence and severity of NAFLD are greater in men than in women and increase with age.

        <h3>Ethnicity</h3>

        There are socio-economic, cultural and genetic factors that explain a different prevalence according to ethnicity.

        <h3>Metabolic risk factors</h3>

        This is the main risk factor for the disease. Overweight/obesity, diabetes, arterial hypertension, metabolic syndrome (association of several metabolic abnormalities), dyslipidemia (hypercholesterolemia or hypertriglyceridemia) are risk factors for NAFLD (and its severity)

        <h3>Lifestyle</h3>

        Excess calories, the type of diet (excess fats, sodas, fruit juices) or a lack of physical activity and sedentary lifestyle are risk factors for NAFLD.

        <h3>Genetics</h3>

        Certain genetic factors modify the risk of presenting the disease.

        <h2>Diagnosis</h2>

        There are no specific symptoms of the disease. The diagnosis is generally evoked on the basis of a blood test (abnormal liver tests) and/or an abdominal ultrasound (liver too "shiny" evoking excess fat).
        <br/><br/>
        In case of suspicion of NAFLD, a complete checkup must be carried out: complete blood biology (in particular to exclude other liver diseases, look for diabetes, excess cholesterol, etc.), liver ultrasound and non-invasive measurement of fibrosis (in a blood test or by performing an elastometry).

        <h2>Evolution and complications</h2>

        The evolution can be done towards an inflammatory liver (non-alcoholic steatohepatitis) and towards a fibrosis (“scar”) of the liver which can sometimes lead to cirrhosis.
        <br/><br/>
        Apart from complications in the liver, this disease increases the risk of cardiovascular disease and of certain cancers.

        <h2>Support</h2>

        Management consists of controlling metabolic risk factors:
        <ul style={{listStyleType: "circle"}}>
            <li>Weight loss if overweight/obese, usually 5-10% of body weight</li>
            <li>High blood pressure control</li>
            <li>Correction of dyslipidemia (cholesterol - triglycerides)</li>
            <li>Limit alcohol consumption</li>
            <li>Diabetes control</li>
            <li>Regular physical activity</li>
        </ul>

        Some treatments to prevent progression to fibrosis are under development.

        Follow-up is indicated and adapted to each patient, depending on their profile and stage of fibrosis.
    </div>,
    "pathologies.rare_diseases.title": "Rare Diseases: PBC, CSP, Autoimmune Hepatitis, Hemochromatosis",
    "pathologies.rare_diseases.content": <div>
        There are other rare liver diseases, linked either to autoimmunity Primary biliary cholangitis, Primary sclerosing cholangitis, autoimmune hepatitis), or to overloads (eg iron overload).
    </div>,
    "pathologies.cirrhosis.title": "Cirrhosis",
    "pathologies.cirrhosis.content": <div>
        Cirrhosis is severe fibrosis of the liver. Fibrosis is a scar that can be moderate or severe (cirrhosis). This scar can prevent the normal functioning of the liver.
        <br/><br/>
        This scar (fibrosis) is most often a reaction to inflammation of the liver which can be of various origins (alcohol, steatosis, viral hepatitis, autoimmune hepatitis, etc.).
        <br/><br/>
        Symptoms are not very specific at the beginning of the disease (fatigue). In the case of more advanced and symptomatic cirrhosis (this is called "decompensated" cirrhosis), the symptoms are more specific. It can be confusion, jaundice, water in the legs or in the stomach. In some cases, digestive bleeding may occur in the event of varicose veins (dilation of veins linked to high pressure secondary to resistance to the passage of blood in the liver).
        <br/><br/>
        The diagnosis of cirrhosis can be suspected on imaging and blood tests. Nevertheless, a non-invasive examination such as Fibroscan or Elastometry makes it possible to assess the degree of fibrosis by measuring the "hardness" of the liver by imaging. In some cases, a liver biopsy may be proposed.
        <br/><br/>
        The treatment is essentially based on the treatment of the cause (eg virus eradication, alcohol cessation, etc.) or on the treatment of complications.
        <br/><br/>
        Half-yearly ultrasound monitoring is essential. Indeed, cirrhosis increases the risk of liver tumors and six-monthly screening can detect small lesions for which a curative treatment can most often be proposed.
        <br/><br/>
        A low-salt diet is most often recommended. It must also be high in calories and high in protein.
    </div>,

    "pathologies.bile_ducts_and_pancreas.title": "Bile ducts & pancreas",

    "pathologies.biliopancreatic_pathology.title": "Bilio-pancreatic pathology",
    "pathologies.biliopancreatic_pathology.content": <div>
        The bile ducts are internal channels that drain bile produced by the liver into the digestive tract at the level of the duodenum.
        Bile, yellow/green in color, is a digestive enzyme, produced continuously by the liver. Apart from a meal, bile is stored in the gallbladder, a small reservoir pocket attached to the main bile duct (the common bile duct). During a meal, the gallbladder contracts and bile is poured into the duodenum on contact with food to begin digestion.
        <br/><br/>
        The pancreas is an organ located behind the stomach, which is involved in digestion but also in the regulation of blood sugar levels.
        Digestive enzymes from the pancreas are discharged into the digestive tract through the duct of Wirsung.
        The common bile duct from the liver and the Wirsung duct of the pancreas joins the duodenum through a common orifice: the ampulla of Vater (see illustration below).

        <ArticleImage src={"/images/pathologies/bilio-pancreatic_duct_en.png"} caption={"Source: WIKIPEDIA"} />

        <br/><br/>
        Diseases of the bile ducts and pancreas most often occur as a result of obstruction of these ducts.
        <br/><br/>
        The obstruction of the ducts is most often related to the passage of a stone from the gallbladder. However, the obstacle can also be due to a cancerous disease. It is then a cancer of the bile ducts or a cancer of the pancreas.
        More rarely, autoimmune or fibrosing inflammatory diseases can obstruct the bile ducts or the pancreatic duct (chronic calcifying pancreatitis, autoimmune pancreatitis, sclerosing cholangitis, primary biliary cirrhosis)
        <br/><br/>
        The migration of a stone causes, depending on where it stops, different symptoms (cholecystitis, cholangitis, acute pancreatitis) and may require different treatment.
        It should be noted here that acute pancreatitis and other diseases of the pancreas are also often linked to chronic alcohol intoxication.
        <br/><br/>
        Symptoms suggestive of biliopancreatic disease are:
        <ul>
        <li>severe pains appearing suddenly above the navel and/or below the ribs on the right and radiating to the back,</li>
        <li>nausea and vomiting after meals</li>
        <li>a severe lack of appetite,</li>
        <li>appearance of jaundice (jaundice),</li>
        <li>appearance of fever</li>
        <li>in some cases, fatigue and significant weight loss.</li>
        </ul>
        <br/><br/>
        The appearance of these symptoms requires immediate medical attention.
        <br/><br/>
        Obstruction of the bile ducts or pancreas may require emergency endoscopic examination or surgery if necessary.
    </div>,
    "pathologies.anus.title": "Anus",
    "pathologies.hemorrhoids.title": "Hemorrhoids",
    "pathologies.hemorrhoids.content": <div>
        Hemorrhoids are dilated veins located in the anus and present in everyone.<br/><br/>
        There are two types of hemorrhoids: internal hemorrhoids, which are located inside the anal canal, and external hemorrhoids, which can appear under the skin outside the anal canal. Internal hemorrhoids play an important role in continence.<br/><br/>
        
        <ArticleImage
            src={"/images/pathologies/hemorrhoids_en.jpg"}
            caption={<div>
                Distinction between external and internal hemorrhoids.
            </div>}/>
        
        Hemorrhoids can sometimes swell, become uncomfortable, and cause bleeding. This is referred to as hemorrhoidal disease.<br/><br/>

        <h2>Causes of Hemorrhoidal Disease</h2>
        <ul>
            <li>Disorders of bowel movements (diarrhea or constipation)</li>
            <li>Pregnancy</li>
            <li>Alcohol, spicy meals</li>
            <li>Some sports (weightlifting, cycling)</li>
        </ul>

        <h2>Medical Treatments</h2>
        <ul>
            <li>Mild laxatives, such as Macrogol, to improve bowel movements</li>
            <li>Ointments, particularly cortisone-based ones, to alleviate local inflammation</li>
            <li>Medications to improve blood flow (venotonic drugs)</li>
        </ul>

        <h2>Instrumental Treatments</h2>
        <ul>
            <li>Applied to internal hemorrhoids after the failure of medical treatment</li>
            <li>Application of elastic ligatures, infrared photocoagulation</li>
        </ul>

        <h2>Surgical Treatments</h2>
        <ul>
            <li>Used when instrumental treatments fail or when hemorrhoidal disease is already advanced</li>
            <li>There are several surgical techniques available, such as laser treatment, Longo's hemorrhoidopexy, Milligan Morgan's radical hemorrhoidectomy, etc.</li>
        </ul>

        <h2>Hemorrhoidal Thrombosis</h2>
        Sometimes, a more acute and painful "crisis" can occur, accompanied by the appearance of a "bluish lump" externally. Most of the time, this is a case of hemorrhoidal thrombosis.<br/><br/>
        Hemorrhoidal thrombosis refers to a blood clot inside a hemorrhoid with edema, which will naturally resolve within 3-4 days. The clot can never migrate and cause conditions like pulmonary embolism.<br/><br/>
        If the pain is too intense, it can be relieved by incising the external hemorrhoid. This procedure should be done in the emergency room or by a gastroenterologist within 48 hours of the appearance of thrombosis.
    </div>,
    "pathologies.anal_fissure.title": "Anal Fissure",
    "pathologies.anal_fissure.content": <div>
        An anal fissure is a tear in the skin of the anal canal and is very common.<br/><br/>
        It causes pain, especially during bowel movements, and can persist for several minutes or hours after defecation. This wound often leads to bleeding.<br/><br/>
        The causes of anal fissure are not clearly established. Traumatic causes are mentioned, such as the passage of hard stool, prolonged pressure on the anus, and poor blood circulation in this area.<br/><br/>
        The healing of the fissure is difficult because there is often a spasm of the internal sphincter of the anal canal (the muscle of continence), which reduces blood supply to the wound necessary for healing. It is believed that this spasm is mainly responsible for the pain.<br/><br/>
        The main treatment for anal fissure is the application of an ointment that relaxes the internal sphincter.
    </div>,
    "pathologies.anal_fistula.title": "Anal Fistula and Perianal Abscess",
    "pathologies.anal_fistula.content": <div>
        An <b>anal abscess</b> (a cavity filled with pus) can sometimes develop around a small infected gland inside the anus. A communication may then form between the infected gland or abscess and the surrounding skin, which is called an <b>anal fistula</b>.<br/><br/>
        Anal fistula most commonly presents with suppuration (discharge of pus).<br/><br/>
        When there is an anal abscess, there is constant, throbbing anal pain that is not relieved by bowel movements.<br/><br/>
        The only effective treatment for fistulas and abscesses is surgical intervention.
    </div>,
    "pathologies.anal_warts.title": "Anal Warts",
    "pathologies.anal_warts.content": <div>
        Anal warts are skin lesions that develop as a result of a sexually transmitted infection caused by the human papillomavirus (HPV). Anal warts are highly contagious, and condom use does not provide complete protection. They can appear around and inside the anus, as well as on the genital and oral areas.<br/><br/>
        Anal warts may disappear on their own within a few weeks, but if they persist, it is advisable to treat them to reduce the risk of transmission and prevent potential progression to precancerous cells (dysplasia).<br/><br/>
        There are several treatment options available to remove the warts (the virus itself is not eliminated), including the application of a cream, cryotherapy using liquid nitrogen, electrocautery with an electric scalpel, or surgical excision.<br/><br/>
        It is important to treat sexual partners as well.<br/><br/>
        Recurrence of anal warts is common.
    </div>,
    "pathologies.gall_and_bile_duct_stones.title": "Gallbladder and Bile Duct Stones",
    "pathologies.gall_and_bile_duct_stones.content": <div>
        Gallstone pathology refers to the presence of gallstones in the gallbladder. Gallstones, also known as biliary calculi, form when certain substances in the bile, such as cholesterol and bile salts, crystallize and solidify. These stones can vary in size, ranging from small grains of sand to large rocks.<br/><br/>
        <ArticleImage
            src={"/images/pathologies/gallstones_en.jpg"}
            caption={<div>
                Gallstones<br/>
                (stones formed from precipitated bile components) <br/>
                Source: <a href="https://stock.adobe.com">Adobe Stock</a>
            </div>}
            smaller={true}/>
        Risk factors for developing gallstones include a high-fat and high-cholesterol diet, obesity, rapid weight loss, a family history of gallstones, certain medications, and certain medical conditions such as diabetes and liver cirrhosis.<br/><br/>
        The management of gallstone pathology depends on the presence or absence of symptoms.<br/><br/>
        In the absence of symptoms, regular ultrasound follow-up may be recommended to monitor the progress of gallstones.<br/><br/>
        However, if symptoms occur, such as intense abdominal pain (biliary colic) or complications like inflammation of the gallbladder (cholecystitis), medical intervention may be necessary.<br/><br/>
        The diagnosis of gallbladder pathology is based on medical history, clinical examination, blood tests, and abdominal imaging such as ultrasound, endoscopic ultrasound, CT scan, or abdominal MRI.<br/><br/>
        In symptomatic cases, surgery is generally recommended to remove the gallstones. The most well-known procedure is open cholecystectomy (which has become rare) that requires a larger abdominal incision, and laparoscopic cholecystectomy, a less invasive approach that uses small incisions and a camera to guide the surgeon.<br/><br/>
        <ArticleImage
            src={"/images/pathologies/laparoscopic_cholecystectomy_en.jpg"}
            caption={<div>
                Laparoscopic Cholecystectomy<br/>
                Source: <a href="https://stock.adobe.com">Adobe Stock</a>
            </div>}/>
        Besides surgery, there are other treatment options for gallstones, such as chemical dissolution using specific medications. However, this method is less common and is generally only used in specific cases where surgery poses high risks to the patient.<br/><br/>
        Choledocholithiasis, also known as common bile duct stones or choledocholiths, refers to the presence of gallstones in the common bile duct, the duct that carries bile from the liver to the small intestine. These stones are expelled from the gallbladder into the common bile duct due to gallbladder contractions. Less commonly, they may spontaneously appear in the common bile duct due to bile stasis caused by an obstruction.<br/><br/>
        Common health problems associated with choledocholithiasis include biliary colic, angiocholitis (inflammation of the bile ducts), and pancreatitis (inflammation of the pancreas). Common symptoms include severe abdominal pain, jaundice, nausea, vomiting, and fever.<br/><br/>
        Once again, the diagnosis of choledocholithiasis relies on the physician's medical history, clinical examination, blood tests, and abdominal imaging. The two most accurate examinations for diagnosing common bile duct stones are endoscopic retrograde cholangiopancreatography (ERCP) or abdominal MRI.<br/><br/>
        The management of choledocholithiasis can vary depending on the severity of symptoms and the presence of complications. Here are some possible treatment approaches:
        <ol>
            <li><a href='../examinations/ercp'>Endoscopic Retrograde Cholangiopancreatography (ERCP)</a>: This procedure involves inserting an endoscope through the mouth to the ampulla of Vater (the sphincter where the common bile duct connects to the small intestine). An endoscopic sphincterotomy, a small incision in the sphincter, is then performed. Special instruments can be used to remove, fragment, or crush the gallstones, making their passage easier. Temporary treatment with a stent (tube-shaped prosthesis) may be necessary to allow bile flow when the stone cannot be extracted.</li>
            <li><b>Extracorporeal Shock Wave Lithotripsy (ESWL)</b>: This technique can be used to fragment gallstones using shock waves generated outside the body. The fragments can then be easily removed using ERCP.</li>
        </ol>
        It's important to note that the management of choledocholithiasis should be personalized based on each patient's specific clinical situation. It's recommended to consult a gastroenterologist or specialized surgeon for an accurate diagnosis and to discuss the most appropriate treatment options.
    </div>,
    "pathologies.irritable_bowel_syndrome.title": "Irritable Bowel Syndrome (IBS)",
    "pathologies.irritable_bowel_syndrome.content": <div>
        This is a real chronic digestive disease, common, always benign but can have a significant impact on the patient's quality of life.<br/>
        Its main symptoms are transit disorders (diarrhea, constipation, or a combination of diarrhea and constipation), abdominal pain, and bloating.
        
        <h2>Causes</h2>
        Its causes are multifactorial and partially identified. They include disorders of intestinal motility (too fast or too slow), visceral hypersensitivity, and disturbances in the analysis of painful messages in the central nervous system, micro-inflammation of the intestinal wall, an increase in the permeability of the intestinal wall (allowing the passage of molecules from the intestine to the digestive wall and triggering micro-inflammatory reactions), an imbalance in intestinal flora (dysbiosis).<br/>
        Stress and anxiety can worsen symptoms.<br/>
        IBS can also occur following a severe infectious gastroenteritis.<br/>
    
        <h2>Diagnosis</h2>     
        Its diagnosis is an exclusion diagnosis. Your doctor may prescribe certain tests to exclude other pathologies, but these will be normal if it is irritable bowel syndrome.
    
        <h2>Treatment</h2>
        The treatment aims to reduce the frequency and intensity of symptoms. It must be personalized for each patient, with often necessary adjustments.<br/><br/>
        A healthy and balanced diet is recommended. A low FODMAPs (Fermentable Oligosaccharides, Disaccharides, Monosaccharides And Polyols) diet may be proposed to reduce digestive fermentation phenomena responsible for pain, bloating, and diarrhea. This will mainly involve reducing the consumption of lactose, certain cereals, certain vegetables (garlic, onion, leek, cabbage, ...), certain fruits (mango, pear, apple, plum, dried fruits), artificial sweeteners, industrial dishes, ...<br/><br/>
        Drug treatment may be proposed to regulate transit (mild laxatives or antidiarrheal agents) and reduce pain (antispasmodics). Probiotics can also be effective.<br/><br/>
        In some cases, small doses of antidepressants can be useful, due to their analgesic action on visceral hypersensitivity.<br/><br/>
        Stress management is important, and physical activity improves symptoms.<br/><br/>
        Hypnosis has also shown real effectiveness.
    </div>,
    

    /*
        EXAMINATIONS
    */
    "examinations.home.title": "Learn more about the modalities and conditions of the examinations we conduct.",
    "examinations.home.subtitle": "Select an exam from the navigation panel on the left to learn more.",
    "examinations.gastroscopy.title": "Gastroscopy",
    "examinations.gastroscopy.content": <div>
        Gastroscopy is a visual exploration of the esophagus, stomach and first part of the small intestine (duodenum) with a camera dedicated to endoscopy; it thus makes it possible to diagnose ulcers, to highlight a gastroesophageal reflux or a food allergy.
        <br/><br/>
        Here is some information about gastroscopy and answers to questions you generally ask about this investigative technique.
        <ul style={{listStyleType: "circle"}}>
            <li>Why choose gastrocopy?</li>
            <li>How to prepare for this exam?</li>
            <li>How is the exam conducted? </li>
            <li>How to make an appointment?</li>
        </ul>

        The examination is indicated to explore symptoms of burning, heaviness, digestive discomfort or epigastric pain.
        <br/><br/>
        Endoscopic exploration only lasts a few minutes, sometimes a little longer if biopsies are to be performed. It is above all the preparation and conditioning of the patient for the examination that takes time.
        <br/><br/>
        During the endoscopy, a sample (biopsy) can be taken and sent to a laboratory for analysis (CHC laboratory); a share for this analysis may also be requested by the laboratory in question.
        <br/><br/>

        <h2>
            Prepare for the exam
        </h2>

        You must come to your appointment on an empty stomach.
        <br/><br/>
        Any solid food intake must be avoided in the 6 hours preceding the examination.
        <br/><br/>
        If the endoscopy takes place at the end of the morning, possibly, on medical advice, you can drink up to 2 hours before the examination (drinks such as juice, coffee, tea, water, no milk).
        <br/><br/>
        A premedication based on Dormicum and/or Rapifen is administered to you intravenously to relieve the nausea sometimes caused by the passage of the endoscope at the level of the aero-digestive junction or the stomach. An anesthetic spray is also sprayed in the back of the throat to numb this area.
        <br/><br/>
        Tell the medical examiner if you are allergic to Xylocaine.
        <br/><br/>
        You should focus on breathing for the duration of the exam. This begins with the esophagus after having passed the endoscope through the mouth and crossed the crossroads between the digestive tract and the respiratory tract; then the device is gently pushed through the stomach into the duodenum (beginning of the small intestine).

        <h2>
            Exam procedure
        </h2>

        It is not a painful examination, but sometimes inconvenient. Most importantly, focus on breathing during the procedure.
        Biopsies are often performed, in the stomach and duodenum, in particular to determine the presence of an infection or an allergy, for example. These biopsies are performed using single-use forceps and sometimes require the discontinuation of anticoagulant drugs (Sintrom, Pradaxa, Eliquis, etc.).
        Once the exam is over, you are settled for 1/2 hour or 1 hour in the rest room to recover at your ease. You are then seen again by the doctor to obtain information on the examination and to receive therapeutic and/or dietary advice.
        You must, in principle, be accompanied because you cannot drive again after the examination.
        <br/><br/>
        It should be noted that, for your safety, endoscopic devices are rigorously disinfected between each examination according to a procedure validated by the competent authorities in terms of public health and hygiene.
        <br/><br/>
        If you would like more information about this examination, do not hesitate to make an appointment for consultation beforehand by calling the endoscopy secretariat at <a href="tel:003287213891">
        087/21.38.91
        </a>.
    </div>,
    "examinations.anorectal_echoendoscopy.title": "Anorectal Endoscopic Ultrasound",
    "examinations.anorectal_echoendoscopy.content": <div>
        Anorectal endoscopic ultrasound is performed to morphologically examine the final part of the colon (the rectum), as well as the anus and its sphincters. This examination allows visualization of the different layers of the anal and rectal walls, as well as the surrounding tissues (presence of lymph nodes, etc.).<br/><br/>
        The examination is generally well-tolerated and does not cause any pain. Sometimes, there may be minor discomfort associated with the insertion of the device. Occasionally, small amounts of water will be injected into your rectum. The examination does not require any preparation at home.<br/><br/>
        Upon arrival at the clinic, a nurse will first administer a rectal enema. You will then be placed on a table to lie comfortably on your left side.<br/><br/>
        The doctor will carefully insert a thin probe into your anus. In some cases, a small balloon, about the size of a walnut, may be inflated. Using the ultrasound probe at the end of the device, the doctor will examine your rectal ampulla and/or the anus and its sphincters. For example, the examination can evaluate the muscles that form the anal sphincters (in the case of investigating the causes of incontinence), determine the size and depth of tumors in the anus or rectum, and identify abscesses and fistulas.<br/><br/>
        At the end of the examination, the probe is removed, and you can leave the clinic immediately.<br/><br/>
        The examination typically lasts about 10 minutes. It does not require anesthesia, and you do not need to fast, but it is required to have an enema one hour before the examination.
    </div>,
    "examinations.anorectal_manometry.title": "Anorectal Manometry",
    "examinations.anorectal_manometry.content": <div>
        An anorectal manometry is performed to assess the sensitivity and motor function of your anal region and rectum (the very last part of the large intestine). It may be requested in cases of long-lasting constipation, unexplained anal pain, difficulty in retaining stools or gas (incontinence), or before and after a surgical intervention.<br/><br/>
        The examination is not painful. You do not need to be fasting, but it is advisable to perform a rectal enema one hour before the examination.<br/><br/>
        Upon arrival at the clinic, if a rectal enema has not been done previously at home, a nurse will administer a rectal enema. You will then be placed on a table to lie comfortably on your left side.<br/><br/>
        The doctor will carefully insert a thin probe into your anus. The probe is barely noticeable. At this stage of the examination, the doctor will only measure the pressures at the level of the anal sphincter. Afterwards, you will be asked to perform certain movements, such as squeezing your buttocks or coughing. Again, the examination is never painful. The manometry lasts approximately 10 minutes.<br/><br/>
        At the end of the examination, the probe is removed, and you can immediately leave the clinic.
    </div>,
    "examinations.esophageal_manometry.title": "Esophageal Manometry",
    "examinations.esophageal_manometry.content": <div>
        A manometry will be carried out to explore the motor function of the esophagus and if other examinations have not sufficiently enabled a diagnosis to be made. Sometimes, we will also perform this examination before surgery to ensure that the esophagus is functioning properly. Another possibility is looking for chest pain that is not due to heart problems.
        <br/><br/>
        The examination is not painful. It does not require any preparation. You just have to be fasting 6 hours before. You are placed on an armchair before being anesthetized at both nostrils using a spray.
        <br/><br/>
        The doctor will then insert a thin probe the diameter of a spaghetti into your nose. He will push the probe gently to the stomach, which is not painful. Rarely, nausea may occur. The manometry takes about 10 minutes. During this time, you must swallow several times water or small pieces of rusk when the doctor asks you to.
        <br/><br/>
        At the end of the examination, the probe is removed and you can immediately leave the clinic.
    </div>,
    "examinations.urea_breath_test.title": "Urea breath test",
    "examinations.urea_breath_test.content": <div>
        This test can determine if the Helicobacter Pylori bacteria is still present in your stomach or not.
        This bacterium is usually detected by biopsies performed during a gastroscopy.
        <br/><br/>
        Helicobacter Pylori is a risk factor for stomach ulcers but also for stomach cancer.
        <br/><br/>
        After receiving the appropriate antibiotic treatment, we perform this simple, painless and quick test to determine if the bacteria has been eradicated. This avoids having to perform a new gastroscopy.
        <br/><br/>
        For this test, you will be asked to report to the nurses on an empty stomach one day in the morning. We will then ask you to blow into small test tubes and we will collect your breath. You will then have to swallow a small capsule of urea accompanied by an apple or orange juice which is provided to you. Thirty minutes after drinking the juice, you will blow a second time into the test tubes. The test is then complete. The contents of the test tubes are then sent to the laboratory for analysis and your attending physician will be informed a few days after the test of the results.
        <br/><br/>
        In case of persistence of the bacteria in the stomach, it is possible that a second antibiotic treatment will be proposed to you by your doctor.
    </div>,
    "examinations.upper_digestive_tract_echo_endoscopy.title": "Echo-endoscopy of the upper digestive tract",
    "examinations.upper_digestive_tract_echo_endoscopy.content": <div>
        Echo-endoscopy is an examination that combines endoscopy and ultrasound. A miniature ultrasound probe is attached to the end of an endoscope and is brought close to an organ to be studied. This technique is useful for the precise analysis of the wall of the digestive tract and surrounding organs (esophagus, stomach, duodenum, pancreas, liver, gallbladder, bile ducts, internal lymph nodes).
        Echo-endoscopy completes the analysis of abnormalities detected by gastroscopy, scanner or MRI. Finally, it makes it possible to obtain information that cannot be obtained by other techniques. In some cases, it allows samples to be taken from the diseased organ which will be studied in the laboratory to determine the nature of the problem (bacteria, cyst, benign or malignant tumor).
        <br/><br/>
        As for a gastroscopy, the echo-endoscopy of the upper digestive tract requires you to come to the clinic on an empty stomach. A light anesthetic is performed by an anesthesiologist to allow the realization of a comfortable examination for the patient and the doctor. A flexible endoscope is then introduced through the mouth and pushed to the area to be studied. The patient is then taken back to his room to wake up. The doctor joins the patient a little later to explain his results.
        <br/><br/>
        In order to offer examinations of optimal quality, we have at St Elisabeth Heusy high quality and latest generation echo-endoscopy equipment allowing the most accurate analysis possible. Echo-endoscopy of the upper digestive tract is performed by Dr Olivier Cajot and Dr Maxime Seivert.
        <br/><br/>
        It is important to point out here that like any other medical act or intervention on the human body, even carried out under conditions of maximum skill and safety, complications are possible in upper echo-endoscopy. However, these are exceptional (perforations of the digestive tract, digestive haemorrhage, infections) but may require the patient to remain hospitalized and sometimes to have to undergo surgical treatment.
        <br/><br/><br/><br/>
        <i>This description is inspired by the site <a href="www.SFED.org">www.SFED.org</a>.</i>
    </div>,
    "examinations.ultrasound.title": "Ultrasound",
    "examinations.ultrasound.content": <div>
        Abdominal ultrasound is an examination that mainly explores the abdominal organs that are solid or contain liquid. Ultrasound does not expose to X-rays: it uses ultrasound emitted by a probe. The ultrasounds pass through the patient's skin and tissues to reach the organs which, depending on their density, will reflect the ultrasounds towards the device which translates them into images. Ultrasound thus makes it possible to visualize, with a lower resolution than CT or MRI, the abdominal organs such as liver, gallbladder, pancreas, spleen, kidneys, aorta, large abdominal lymph nodes.
        <br/><br/>
        This is the test of choice for detecting stones (stones) in the gallbladder.

        <h2>How is an abdominal ultrasound performed?</h2>
        You are placed comfortably in a lying position on your back, with your belly uncovered down to the bottom of your pelvis. The doctor places a conductive gel on the skin of the belly to facilitate the passage of ultrasound through the skin. The realization of an abdominal ultrasound takes about twenty minutes.

        <h2>Practical information</h2>
        It is necessary to fast for at least 6 hours.
        <br/><br/>
        Make an appointment: <Link to={"../contact"}>contact page</Link>
    </div>,
    "examinations.ercp.title": "Endoscopic retrograde cholangiopancreatography (ERCP)",
    "examinations.ercp.content": <div>
        Endoscopic retrograde cholangiopancreatography (ERCP) is an endoscopic examination that can treat certain problems of the bile ducts and pancreas. This procedure may include the removal of stones, allow drainage of the pancreas and or bile ducts with the placement of a prosthesis.
        <br/><br/>
        A preliminary assessment is therefore often carried out to specify the problem to be treated. This can be, for example, an ultrasound, a CT scanner, an NMR or a biliary and pancreatic echo-endoscopy.
        <br/><br/>
        Echo-endoscopy also takes place under anesthesia, it is always possible in our service to carry out this preliminary examination and the therapeutic ERCP during the same anesthesia, which reduces repeated narcosis and accelerates treatment.

        <h2>Exam procedure</h2>
        The bile and pancreatic ducts flow into the initial part of the small intestine (duodenum) through an opening called the “papilla” or ampulla of Vater. The examination uses an endoscope which is slid through the mouth into the duodenum. Then a catheter is inserted through the endoscope, which is guided to the papilla to inject a contrast product into the biliary and/or pancreatic tract. X-rays are then taken. Then a treatment is carried out.
        <ArticleImage 
            src={"/images/examinations/ercp_en.jpg"} 
            caption={<div>
                Exam procedure<br/>
                Source: <a href="https://stock.adobe.com">Adobe Stock</a>
            </div>}/>
        The total duration of the ERCP varies greatly depending on the difficulty of the examination and the treatment to be performed (between 15 and 120 minutes).

        <h2>Practical information</h2>
        Before this examination, contact with a doctor is essential.
        <br/><br/>
        The examination is performed under anesthesia. A 24-hour hospitalization is most often necessary. This examination is carried out at the St Elisabeth clinic in Heusy.
        <br/><br/>
        We perform this examination by blowing CO2 into the digestive tract to see correctly with increased comfort for the patient. If there are no contraindications, an anti-inflammatory suppository is administered to minimize the risk of adverse effects.
        <br/><br/>
        You are not allowed to eat on the day of the exam. Food is resumed the next day, unless there are complications or other examinations are necessary.

        <h2>What are the possible complications?</h2>
        <b>Any medical act, exploration, intervention on the human body, even carried out under conditions of competence and safety in accordance with current medical data and the regulations in force, harbors a risk of complications. The most frequent complications of therapeutic ERCP (endoscopic sphincterotomy and associated treatment) are the following: acute pancreatitis, infection of the bile ducts or the gallbladder, perforation of the digestive wall, digestive haemorrhage. The frequency of each of these complications is in the range of 1-4%.</b>
        <br/><br/>
        Other complications are exceptional such as cardiovascular or respiratory disorders. These complications can be favored by your medical and surgical history or by taking certain medications.
        <br/><br/>
        All of these complications may necessitate delaying the timing of refeeding, prolonging hospitalization, and making further endoscopy or surgery necessary. Haemorrhage may lead to blood or blood derivative transfusions.
        <br/><br/>
        Complications most often appear during endoscopy but can also appear a few days after the examination (abdominal or chest pain, fever, chills, etc.). It is then very important to immediately contact the doctor and/or the anesthesiologist who took care of you. If it is impossible to contact them, it is very important to contact your doctor very quickly or to go to the emergency services of the St Elisabeth clinic.

        <h2>What are the other alternatives to this exam?</h2>
        Ultrasound, scanner, echoendoscopy and sometimes magnetic resonance are often performed before the ERCP. Magnetic resonance can allow the ducts of the liver and pancreas to be clearly seen and, if they are normal, to avoid ERCP.
        <br/><br/>
        However, the ERCP is the only examination that allows simultaneous treatment (extraction of a stone - fitting of a prosthesis). Surgical treatment can be an alternative to endoscopic treatment but requires longer hospitalization and higher risks, especially in the elderly.

        <h2>In case of refusal</h2>
        If the examination that your doctor has proposed is not carried out, this can have harmful consequences for your health. A calculation of the bile duct, benign disease, left in place can lead among other things to a severe infection, sometimes fatal.
    </div>,
    "examinations.colonoscopy.title": "Colonoscopy",
    "examinations.colonoscopy.content": <div>
        Colonoscopy consists of visual exploration (with a camera) of the colon, from the anus to the caecum (beginning of the colon) as well as, in some cases, exploration of the end of the small intestine (ileum). This examination makes it possible to find the origin of symptoms (transit disorders, abdominal pain, positive screening test, anemia, etc.), to carry out biopsies of the mucosa (take a small piece of tissue to analyze it under the microscope) and to look for polyps or colon cancer. It also allows, as far as possible, to resect any polyps discovered during the examination.

        <h2>
            Before the exam
        </h2>
        In the vast majority of cases, you will benefit from a prior consultation with the gastroenterologist doctor to validate the indication for the examination and receive full explanations, the preparation, the methods of the endoscopic examination as well as a notebook that will include this information. This notebook will also contain the consents (for the anesthesia and for the endoscopy) which must be read and signed for the day of the examination. During this consultation, you will have the opportunity to ask all your questions.

        <h3>
            What is the preparation?
        </h3>
        For optimal exploration of the colon, it is absolutely necessary to obtain the cleanest possible colon, ideally free of any residue.
        <br/>
        The preparation is individualized according to each patient (duration of the diet, type of laxative preparation) and determined during the pre-colonoscopy consultation.
        <br/><br/>
        It is important to follow these instructions strictly in order to improve the quality of the examination as much as possible. Nevertheless, in some patients, despite following the instructions, the preparation is not optimal and the examination sometimes needs to be repeated at a close interval or supplemented by another examination.

        <ul>
            <li>
                <em>Residue-free diet</em> (duration determined in consultation, depending on your personal situation, generally 2 to 3 days)
                <ul style={{listStyleType: "circle"}}>
                    <li><em>Avoid:</em> Wholemeal breads, cereal breads, muesli, whole grains and foods made from whole grains, all fruits and vegetables, fruit juices with pulp (grapefruit, pineapple, tomatoes, prunes), milk and dairy products with the exception of yoghurts without pieces of fruit, jams and jellies</li>
                    <li><em>Authorized:</em> white bread, white rice, pasta, semolina, cooked ham, chicken breast, lean meat, cooked egg (boiled or boiled), unbreaded fish, potatoes without skin, fruit juice without pulp, stewed fruit, vegetable broth, apple juice, yoghurt without fruit pieces, honey, spices (curry, paprika, pepper, thyme, bay leaf, tarragon, chervil, parsley, tomato sauce without skin seeds, salt)</li>
                </ul>
            </li>
            <li>
                <em>Laxative preparation</em> (individualized in consultation, depending on your personal situation)
                <br/>
                It is a “purge” based on laxative products. There are several products available and the choice will depend on the profile of each patient. Generally, the preparation requires 2 doses; one the evening before and the other the morning of the exam (respecting a fasting interval of 2 to 3 hours before the exam).
                <br/><br/>
                In some patients, a laxative treatment may be prescribed a few days before the examination, for example in the event of severe constipation or a history of sub-optimal preparation.
                This preparation is generally carried out at home and the examination is carried out in a day hospital. Nevertheless, in certain situations, you may be asked to be hospitalized the day before the examination to carry out the purge in the hospital.
            </li>

            <li>
                <em>Preliminary meeting with a doctor from the anesthesia care team</em> (not necessarily the same as the day of the examination)
                <br/>
                They will explain the sedation to you, will analyze your medical file (history/medications) and give you instructions concerning the continuation or the discontinuation of your medications for the examination.
                You will have the opportunity to ask questions about anesthesia.
            </li>
        </ul>

        <h2>
            During the exam
        </h2>
        Colonoscopy requires a short hospital stay (generally in a day hospital) as well as “deep sedation” type anesthesia managed by the anesthesiologist.
        When you are asleep, the gastroenterologist doctor performs the examination without you feeling any pain or having any recollection of the examination.
        The duration of the examination varies according to the findings per examination and any polyps to be resected (generally 30 to 45 minutes).
        You are then taken to the recovery room where you wake up quickly after the exam and are then taken back to your room.

        <h2>
            After the exam
        </h2>
        You go back to your room to rest and you are seen again by the gastroenterologist doctor to discuss your results and the follow-up to be given. If samples have been taken (biopsies, resection of polyps), the results of the microscopic analysis will be available from your attending physician approximately 10 days later. The gastroenterologist doctor will be able to give you a monitoring interval depending on the results of the examination.
        Due to sedation, you will not be able to drive/work/make important decisions until the day after the exam. It is therefore necessary to be accompanied for the return.
        Exceptionally, you may be asked to remain under surveillance the night following the examination.
    </div>,
    "examinations.defeco_mri.title": "Dynamic Pelvic Floor MRI (Defecography-MRI)",
    "examinations.defeco_mri.content": <div>
        The examination is performed in the radiology department and is indicated for the assessment of pelvic floor disorders. It examines the position of the pelvic organs during pushing, retention, and evacuation efforts. It can be used to explore prolapse (urinary, genital, or rectal) or organ descent.<br/><br/>
        Unlike <b>colpo-cysto-defecography</b>, there is no urinary catheterization or drinking preparation required.
    </div>,
    "examinations.twenty_four_hour_ph_impedance_testing.title": "24-hour pH Impedance Testing",
    "examinations.twenty_four_hour_ph_impedance_testing.content": <div>
        <h2>Why?</h2>
        Confirm the presence or absence of pathological gastroesophageal reflux (GER), whether acid or non-acid, and ensure that the symptoms presented by the patient are well-correlated with gastroesophageal reflux.<br/><br/>
        For example: when gastroscopy did not show significant esophagitis lesions confirming the GER diagnosis, when the symptoms are not typical, when antacid treatment is ineffective, to confirm certain indications for anti-reflux surgery, ...
    
        <h2>How?</h2>
        A thin probe with pH and impedance sensors is placed in the esophagus through the nose and is connected to a shoulder-worn device that records data for 24 hours.<br/><br/>
        The placement of the probe is not painful but may cause transient discomfort in the throat and some nausea.<br/><br/>
        If you are taking antacid medication, the doctor prescribing the test will inform you whether you should stop it a few days before or not, depending on your situation.<br/><br/>
        You should fast (not eat or drink) for 6 hours before the test.<br/><br/>
        During the test, you should live normally, without avoiding what triggers reflux symptoms. You will be asked to note the times when you experience symptoms, meal periods, and when you are lying down.<br/><br/>
        You will need to return the next day to remove the probe and return the recording device, which will then be analyzed.<br/><br/>
    </div>,
    
    "examinations.video_capsule_endoscopy.title": "Video Capsule Endoscopy (VCE)",
    "examinations.video_capsule_endoscopy.content": <div>
        This examination allows the exploration of the small intestine, most of which is not accessible by conventional gastroscopy and colonoscopy. The main indication is the search for lesions causing digestive bleeding.
        
        <h2>How?</h2>
        The capsule, the size of a large capsule, is swallowed with a little water and takes multiple photographs of the mucous membrane throughout its transit in the small intestine. These photos are transmitted and recorded in a shoulder-worn device.<br/>
        Preparation of the intestine is necessary, with the cessation of any oral iron intake 7 to 10 days before the examination, taking an oral purge the night before, and complete fasting to allow visualization of the intestinal wall.
        <br/><br/>
        Recording lasts 8 to 9 hours, during which you can return home and eat according to the instructions given to you. You will be asked to return the device in the evening or the next day for analysis.<br/>
        The capsule used is for single use and will be eliminated in the stool; it should not be retrieved after the examination.
        <br/><br/>
        The main contraindications to this examination are strictures of the small intestine that can cause capsule retention. It is therefore important to report any history of intestinal surgery, intestinal obstruction, inflammatory bowel disease, radiotherapy, ... Swallowing disorders should also be reported as they may require a specific technique to place the capsule in the intestine.<br/>
        Pregnancy is a contraindication.<br/>
        The performance of an MRI (magnetic resonance imaging) is contraindicated until the capsule has been definitively evacuated.
    </div>,
    
    /*
        CONTACT
    */
    "contact.title": "Contact",
    "contact.header.day": "Day",
    "contact.header.morning": "Morning Activity",
    "contact.header.afternoon": "Afternoon Activity",
    "contact.monday": "Monday",
    "contact.tuesday": "Tuesday",
    "contact.wednesday": "Wednesday",
    "contact.thursday": "Thursday",
    "contact.friday": "Friday",
    "contact.saturday": "Saturday",
    "contact.sunday": "Sunday",
    "contact.subtitle.schedule": "Schedule",
    "contact.subtitle.appointment": "Appointment Booking",
    "contact.virginie_jadot.tuesday.morning": "Consultations in Heusy",
    "contact.virginie_jadot.tuesday.afternoon": "Endoscopies under sedation in Heusy",
    "contact.virginie_jadot.wednesday.morning": "Ambulatory endoscopies in Heusy",
    "contact.virginie_jadot.wednesday.afternoon": "Consultations in Heusy",
    "contact.virginie_jadot.friday.morning": "Endoscopies (one week with sedation, one week ambulatory) in Malmedy",
    "contact.virginie_jadot.friday.afternoon": "Consultations in Malmedy",
    "contact.virginie_jadot.appointment": <div>Through the Internal Medicine secretary's office in Malmedy: <br/><a href='tel:+3280793158'>+32 80 793 158</a> or <a href='tel:+3280793122'>+32 80 793 122</a></div>,
    "contact.ann_christine_dupin.tuesday.morning": "Outpatient endoscopies in St. Vith",
    "contact.ann_christine_dupin.tuesday.afternoon": "",
    "contact.ann_christine_dupin.wednesday.morning": "Sedated endoscopies and consultations in Eupen",
    "contact.ann_christine_dupin.wednesday.afternoon": "Sedated endoscopies and consultations in Eupen",
    "contact.ann_christine_dupin.thursday.morning": "Consultations, ano-rectal manometry, and anorectal echo-endoscopy in Heusy",
    "contact.ann_christine_dupin.thursday.afternoon": "Consultations, ano-rectal manometry, and anorectal echo-endoscopy in Heusy",
    "contact.ann_christine_dupin.friday.morning": "Outpatient endoscopy and sedated endoscopy in Heusy",
    "contact.ann_christine_dupin.friday.afternoon": "Outpatient endoscopy and sedated endoscopy in Heusy",
    "contact.ann_christine_dupin.appointment": <div>
        Saint Vith: <a href="tel:+3280854111">+32 80 854 111</a><br/>
        Eupen: <a href="tel:+3287599312">+32 87 599 312</a><br/>
        Heusy: <a href="tel:+3287213700">+32 87 213 700</a>
    </div>,
    "contact.nadia_torres.monday.morning": "Consultations in Heusy",
    "contact.nadia_torres.monday.afternoon": "Ambulatory endoscopies in Heusy",
    "contact.nadia_torres.tuesday.morning": "Consultations in Eupen",
    "contact.nadia_torres.tuesday.afternoon": "Endoscopies under sedation in Eupen",
    "contact.nadia_torres.wednesday.morning": "Endoscopies under sedation in Heusy",
    "contact.nadia_torres.wednesday.afternoon": "Consultations in Heusy",
    "contact.nadia_torres.friday.morning": "Endoscopies under sedation in Eupen",
    "contact.nadia_torres.friday.afternoon": "Consultations in Eupen",
    "contact.nadia_torres.appointment": <div>
        Eupen: <a href="tel:+3287599312">+32 87 599 312</a><br/>
        Heusy: <a href="tel:+3287213700">+32 87 213 700</a>
    </div>,
    
    "contact.astrid_denis.monday.morning": "Ambulatory Endoscopies in Heusy",
    "contact.astrid_denis.monday.afternoon": "Esophageal Manometries / Consultations in Heusy",
    "contact.astrid_denis.tuesday.morning": "Ambulatory Endoscopies in Eupen",
    "contact.astrid_denis.tuesday.afternoon": "Consultations at Medical Cabinets de la Haie des Chêne in Dolembreux",
    "contact.astrid_denis.wednesday.morning": "Consultations at Medical Cabinets de la Haie des Chênes in Dolembreux",
    "contact.astrid_denis.wednesday.afternoon": "",
    "contact.astrid_denis.thursday.morning": "pH Impedance Studies / Consultations in Heusy",
    "contact.astrid_denis.thursday.afternoon": "Endoscopies under sedation in Heusy",
    "contact.astrid_denis.appointment": <div>
        Eupen: <a href="tel:+3287599312">+32 87 599 312</a><br/>
        Heusy: <a href="tel:+3287213700">+32 87 213 700</a>
    </div>,
    

    /*
        CONSTRUCTION
    */
    "construction.title": "In construction",
    "construction.description": "This page will be available soon.",

    /*
        NOT FOUND
    */
    "not_found.title": "Error 404 - Page not found",
    "not_found.description": "Did you get lost?",
    "not_found.call_to_action_1": "Start your search over from the",
    "not_found.call_to_action_2": "home page",
    "not_found.call_to_action_3": "!",
};

export default english;

export type LocalizedStringKey = keyof typeof english;